import React, { FC, useState, useEffect } from 'react';

import { Input } from 'antd';
import styled from '@emotion/styled';
import { AddLoanNoteI } from '../../../types/loan.types';
import { Image, PrimaryButton, SecondaryButton } from '../../../components';
import theme from '../../../theme';
import { uniqueId } from 'lodash';
import {
  After3Months,
  After6Months,
  DatePicker,
  RemindNote,
} from '../../people/shared/notes/NoteForm';
import { PayrollCalenderSVG } from '../../../assets';
import dayjs from 'dayjs';

const { TextArea } = Input;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  @media (max-width: 450px) {
    height: auto;
  }
`;

const TextSection = styled.div`
  background-color: ${theme.white};
  border-bottom: 1px solid ${theme.gray300};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 130px;
`;

const TextAreaField = styled(TextArea)`
  border: none;
  padding: 16px 24px !important;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {
    border: none;
    box-sizing: none;
    filter: none;
    border-radius: none;
  }
`;

const BottomSection = styled.div`
  margin-bottom: 0px;
  padding: 14px 24px !important;
  background: ${theme.gray100};
  border-bottom: 1px solid ${theme.offWhite} !important;
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 450px) {
    margin-top: 85px;
  }
`;

interface Props {
  onCancel: () => void;
  editItem?: AddLoanNoteI;
  onSubmit: (note: AddLoanNoteI) => void;
  isLoanView?: boolean;
}

const LoanNoteForm: FC<Props> = ({
  onCancel,
  editItem,
  onSubmit,
  isLoanView,
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const dateFormat = 'YYYY-MM-DD';
  const date = dayjs().format(dateFormat);
  const [selectColor, setSelectColor] = useState<string | null>(null);
  const [remindDate, setRemindDate] = useState<string | undefined | null>(
    undefined,
  );
  const [color, setColor] = useState<string | null>(null);

  useEffect(() => {
    if (editItem) {
      setNoteText(editItem.note);
      setRemindDate(editItem.reminderDate);
    }
  }, [editItem]);

  return (
    <Container>
      <TextSection>
        <TextAreaField
          name="note"
          onChange={(e) => setNoteText(e.target.value)}
          value={noteText}
          placeholder="Write your note here"
          autoSize={{ minRows: 2, maxRows: 2 }}
        />
        <RemindNote>
          <After3Months
            selectColor={selectColor}
            onClick={() => {
              setRemindDate(dayjs().add(3, 'months').format(dateFormat));
              setSelectColor(theme.blue500);
              setColor(null);
            }}>
            Remind this after 3 months
          </After3Months>
          <After6Months
            color={color}
            onClick={() => {
              setRemindDate(dayjs().add(6, 'months').format(dateFormat));
              setColor(theme.blue500);
              setSelectColor(null);
            }}>
            Remind this after 6 months
          </After6Months>
          <DatePicker
            suffixIcon={<Image src={PayrollCalenderSVG} marginRight="7px" />}
            size="middle"
            placeholder="Pick a date to remind"
            value={remindDate ? dayjs(remindDate) : undefined}
            onChange={(date) => {
              date
                ? setRemindDate(date.format(dateFormat))
                : setRemindDate(null);
              setSelectColor(null);
              setColor(null);
            }}
            disabledDate={(current) => {
              return current && current.isBefore(dayjs().endOf('day'));
            }}
          />
        </RemindNote>
      </TextSection>

      <BottomSection>
        <PrimaryButton
          disabled={!noteText}
          onClick={() => {
            if (editItem) {
              onSubmit({
                note: noteText,
                id: editItem.id,
                updatedAt: editItem.updatedAt,
                loanId: editItem.loanId,
                reminderDate: remindDate,
              });
            } else {
              onSubmit(
                isLoanView
                  ? {
                      note: noteText,
                      reminderDate: remindDate,
                    }
                  : {
                      id: uniqueId(),
                      note: noteText,
                      updatedAt: date,
                      reminderDate: remindDate,
                    },
              );
            }
          }}>
          {editItem ? 'Update' : 'Add Note'}
        </PrimaryButton>
        <SecondaryButton ml={16} onClick={() => onCancel()}>
          Cancel
        </SecondaryButton>
      </BottomSection>
    </Container>
  );
};

export default LoanNoteForm;
