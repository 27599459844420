import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { B, InputComp, PrimaryButton, Tag } from '../../../../components';

import { updateCustomization } from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';

import { useWindowSize } from '../../../../util/screenSize';
import styled from '@emotion/styled';
import { CustomizationEnum } from '../../../../types/organization.types';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-clip: border-box;
  padding: 32px;
  @media (max-width: 760px) {
    padding: 32px 10px;
    border-bottom: 1px solid #e0e0e0;
  }
`;

const Designations: FC = () => {
  const { isMobile } = useWindowSize();
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const dispatch: DispatchType = useDispatch();
  const designations: string[] = organizationData?.designations || [];
  const [form] = Form.useForm();

  async function handleSubmit(data: any) {
    const newText: string = data.designation;
    const trimmedValue: string = newText.trim();
    if (trimmedValue) {
      const newList = [...new Set<string>([...designations, trimmedValue])];
      if (organizationData) {
        form.resetFields();
        dispatch(updateCustomization(newList, CustomizationEnum.DESIGNATION));
      }
    }
  }

  async function removeByIndex(index: number) {
    if (organizationData) {
      const newList = [...designations];
      newList.splice(index, 1);
      dispatch(updateCustomization(newList, CustomizationEnum.DESIGNATION));
    }
  }

  return (
    <MainContainer>
      <Form
        form={form}
        name="form1"
        onFinish={handleSubmit}
        validateTrigger="onBlur"
        css={css`
          width: 100%;
        `}>
        <B type="b-default-semibold" color="#00318C" pb="4px">
          Position Levels
        </B>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={24} md={12} lg={20} xl={20}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Enter position levels!',
                  validator: (_, value) => {
                    const trimmedValue = value.trim();
                    if (!trimmedValue) {
                      return Promise.reject('Enter team!');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name="designation"
              css={css`
                margin-bottom: 0px;
                .ant-col-10 {
                  max-width: 100%;
                }
              `}>
              <InputComp
                name="designation"
                id="designation"
                placeholder="Enter position levels"
                css={css`
                  .ant-select-selection-item {
                    background: rgba(51, 117, 238, 0.1);
                    border-radius: 4px;
                    color: #0042bb;
                  }
                  .ant-select-multiple
                    .ant-select-selection-item-remove
                    > .anticon {
                    background: #0042bb;
                  }
                `}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <PrimaryButton
              id="add-designation"
              block={isMobile}
              htmlType="submit">
              Add
            </PrimaryButton>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {designations.map((designation, i) => (
              <Tag
                onClose={() => {
                  removeByIndex(i);
                }}>
                {designation}
              </Tag>
            ))}
          </Col>
        </Row>
      </Form>
    </MainContainer>
  );
};

export default Designations;
