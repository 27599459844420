export const getLabelFromTitle = (title) =>{
    if (!title) return '';
    
    const splitName = title.split(' ');
    if (splitName.length === 1) {
      return splitName[0].charAt(0).toUpperCase();
    } else if (splitName.length > 1) {
      return `${splitName[0].charAt(0).toUpperCase()}${splitName[1]
        .charAt(0)
        .toUpperCase()}`;
    }
  }