import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetLoanLogs } from '../../../api/loanHooks';
import {
  FooterComponent,
  LogTrailTable,
  SecondaryButton,
} from '../../../components';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';

const LoanLogTrail: FC = () => {
  const navigate = useNavigate();

  const {
    isLoading: loadingLoanLogs,
    data: dataLoanLogs = [],
    mutate: getLoanLogs,
  } = useGetLoanLogs();

  useEffect(() => {
    getLoanLogs();
  }, []);

  return (
    <>
      <LogTrailTable
        logs={dataLoanLogs}
        loading={loadingLoanLogs}
        leftChildren={
          <SecondaryButton onClick={() => navigate(getPathWithOrgId('/loans'))}>
            Back
          </SecondaryButton>
        }
      />
    </>
  );
};
export default LoanLogTrail;
