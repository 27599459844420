import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Input, Row, Spin } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  useRequestLeaveAsAdmin,
  useRequestMyLeave,
} from '../../../api/leaveHooks';
import {
  AlertMessage,
  B,
  DatePickerComp,
  Div,
  SelectComp,
} from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import {
  clearResponseState,
  getAllOrganizationLeaves,
  getLeaveBalanceByUser,
  getMyLeaveRequests,
  getMyLeaves,
  getOrganizationLeaveRequests,
  leavesBalanceMe,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  DATE_FORMAT,
  LeaveBalance,
  LeaveNameOption,
  LeaveTypeEnum,
  LeaveTypesEnum,
  LeaveViewI,
  Permission,
  RequestLeaveI,
} from '../../../types';
import { PeopleI } from '../../../types/people.types';
import { getApiError } from '../../../util/getApiError';
import { isAllowed } from '../../../util/permissionUtil';
import { capitalizeUnderscore } from '../../../util/utils';
import LeaveDetails from '../shared/LeaveDetails';
import {
  getDatesBetween,
  getLeaveTypes,
  getOtherLeaveTypes,
  isDisabledLeaveEndDate,
  isDisabledLeaveStartDate,
  onDateRangeChanged,
  onHoursChange,
  onLeaveDayClose,
} from '../utils';
import LeaveSelector from './LeaveSelector';
import dayjs from 'dayjs';

interface Props {
  allPeoples?: PeopleI[];
  onClose?: () => void;
  userId?: number | undefined;
}

const LeaveRequestForm = styled.div`
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: none;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-input[disabled] {
    color: #000000;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
`;

const TextArea = styled(Input.TextArea)`
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding-left: 11px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    border: 1px solid #0052ea;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid #0052ea;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;
const RowContainer = styled(Row)<{ leaveDatesLength: number }>`
  box-sizing: border-box;
  border-radius: 4px;
  border: ${(props) => props.leaveDatesLength != 0 && '1px solid #E0E0E0'};
  border-bottom: none;
  margin-bottom: ${(props) => props.leaveDatesLength != 0 && '16px'};
`;

const ApplyLeaveModal: FC<Props> = ({ allPeoples, onClose, userId }) => {
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(true);
  const [startValue, setStartValue] = useState<string>();
  const [endValue, setEndValue] = useState<string>();
  const [isLeaveName, setIsLeaveName] = useState(false);
  const [leaveDates, setLeaveDates] = useState<LeaveViewI[]>([]);
  const [noPayAllowed, setNoPayAllowed] = useState(
    isAllowed(Permission.CREATE_EDIT_LEAVES_USER),
  );
  const [resignedDate, setResignedDate] = useState<string | null>(null);

  const {
    isLoading: requestLoadingAsAdmin,
    error: requestErrorAsAdmin,
    data: requestDataAsAdmin,
    mutate: postLeaveAsAdmin,
  } = useRequestLeaveAsAdmin();

  const {
    isLoading: requestLoadingAsUser,
    error: requestErrorAsUser,
    data: requestDataAsUser,
    mutate: postLeaveAsUser,
  } = useRequestMyLeave();

  useEffect(() => {
    dispatch(getAllOrganizationLeaves(''));
  }, []);

  allPeoples = useSelector((state: RootState) => state.people.allPeoples);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const nextYear = moment().add(1, 'year').year();
  const leaveResetDate = moment(
    organizationData?.leaveSettings.leaveReset.date,
    'DD-MM',
  )
    .year(nextYear)
    .format('YYYY-MM-DD');

  const leaveBalanceUser = useSelector(
    (state: RootState) => state.leave.leavesBalanceUser,
  );

  const leaveBalanceMe = useSelector(
    (state: RootState) => state.leave.leavesBalanceMe,
  );

  const leavesBalanceMeLoading = useSelector(
    (state: RootState) => state.leave.leavesBalanceMeLoading,
  );
  const leavesBalanceUserLoading = useSelector(
    (state: RootState) => state.leave.leavesBalanceUserLoading,
  );

  const me = useSelector((state: RootState) => state.people.me);

  const userLeaveData = useSelector(
    (state: RootState) => state.leave.organizationLeaves,
  );

  const [otherLeaves, setOtherLeaves] = useState<LeaveBalance[]>();

  useEffect(() => {
    if (leaveBalanceUser)
      setOtherLeaves(
        leaveBalanceUser.filter(
          (item) => item.leaveType == LeaveTypesEnum.OTHER,
        ),
      );
  }, [leaveBalanceUser]);

  useEffect(() => {
    if (requestDataAsAdmin && !requestErrorAsAdmin && onClose) {
      dispatch(leavesBalanceMe());
      dispatch(getAllOrganizationLeaves());
      dispatch(getMyLeaves());
      dispatch(getOrganizationLeaveRequests());
      dispatch(getMyLeaveRequests());
      // Close modal on success
      onClose();
    }
  }, [requestDataAsAdmin]);

  useEffect(() => {
    if (requestDataAsUser && !requestErrorAsUser && onClose) {
      dispatch(leavesBalanceMe());
      dispatch(getAllOrganizationLeaves());
      dispatch(getMyLeaves());
      dispatch(getOrganizationLeaveRequests());
      dispatch(getMyLeaveRequests());
      // Close modal on success
      onClose();
    }
  }, [requestDataAsUser]);

  useEffect(() => {
    if (leaveBalanceMe)
      setOtherLeaves(
        leaveBalanceMe?.filter(
          (item) => item.leaveType == LeaveTypesEnum.OTHER,
        ),
      );
  }, [leaveBalanceMe]);

  useEffect(() => {
    if (me && me.id) {
      dispatch(getLeaveBalanceByUser(String(me.id)));
    } else {
      dispatch(leavesBalanceMe());
    }
  }, []);

  useEffect(() => {
    dispatch(getAllOrganizationLeaves(''));
  }, [endValue, startValue]);

  const user: string = form.getFieldValue('userId');

  useEffect(() => {
    if (startValue) {
      form.setFieldsValue({
        from: dayjs(startValue),
      });
    }
    if (endValue) {
      form.setFieldsValue({
        to: dayjs(endValue),
      });
    }

    if (startValue && endValue) {
      const dateRanges = getDatesBetween({
        toDate: endValue,
        fromDate: startValue,
      });
      const newLeaveDates = onDateRangeChanged(
        dateRanges,
        leaveDates,
        userLeaveData,
        organizationData,
        user,
      );
      setLeaveDates(newLeaveDates);
    }
  }, [endValue, startValue, user]);

  useEffect(() => {
    if (!visible) {
      onClose && onClose();
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
    dispatch(clearResponseState());
  };

  const leaveNameOptions: LeaveNameOption[] | undefined = otherLeaves?.map(
    (item) => {
      return {
        value: item.leaveName,
        label: capitalizeUnderscore(item.leaveName),
      };
    },
  );

  const onSubmitLeaveAsAdmin = (data: RequestLeaveI) => {
    const value: RequestLeaveI = {
      userId: String(data.userId),
      type: LeaveTypeEnum.DEDUCT,
      hours: data.hours ? Number(data.hours) : 0,
      leaveType: data.leaveType,
      leaveName: data.leaveName && data.leaveName,
      reason: data.reason,
      leaves: leaveDates
        .filter((item) => item.isHoliday === false)
        .map((item) => ({
          date: item.date,
          hours: item.hours,
        })),
    };
    postLeaveAsAdmin(value);
  };

  const onSubmitLeaveAsUser = (data: RequestLeaveI) => {
    const leaves = leaveDates
      .filter((item) => item.isHoliday === false)
      .map((item) => ({
        date: item.date,
        hours: item.hours,
      }));

    const value: RequestLeaveI = {
      leaveType: data.leaveType,
      reason: data.reason,
      leaveName: data.leaveName && data.leaveName,
      leaves,
    };
    postLeaveAsUser(value);
  };

  const allPeoplesOptions =
    allPeoples &&
    allPeoples
      .sort((a, b) => a.fullName?.localeCompare(b.fullName))
      .map((item, index) => {
        return {
          value: item.id ? item.id : index,
          label: item.fullName,
        };
      });

  const leaveSummery = allPeoplesOptions.length
    ? leaveBalanceUser
    : leaveBalanceMe;

  const getResignedDate = (value) => {
    const selectedEmployee = allPeoples.find((item) => item.id === value);
    setResignedDate(selectedEmployee?.resignedDate ?? null);
  };

  return (
    <ModalComponent
      form={form}
      centered
      width={585}
      visible={visible}
      onCancel={handleCancel}
      submitText="Submit"
      loading={
        allPeoplesOptions.length ? requestLoadingAsAdmin : requestLoadingAsUser
      }
      title={
        <B type="b-large-semibold" color={theme.black}>
          Apply leave
        </B>
      }>
      <LeaveRequestForm>
        <Form
          initialValues={{
            userId: userId,
          }}
          form={form}
          layout="vertical"
          wrapperCol={{ span: 50 }}
          name="requestLeave"
          onFinish={
            allPeoplesOptions.length
              ? onSubmitLeaveAsAdmin
              : onSubmitLeaveAsUser
          }>
          {requestErrorAsAdmin && (
            <Div mb="16px">
              <AlertMessage
                title={getApiError(requestErrorAsAdmin)}
                type="error"
              />
            </Div>
          )}
          {requestErrorAsUser && (
            <Div mb="16px">
              <AlertMessage
                title={getApiError(requestErrorAsUser)}
                type="error"
              />
            </Div>
          )}
          <div>
            {!!allPeoplesOptions.length ? (
              <Form.Item
                name="userId"
                rules={[
                  {
                    required: true,
                    message: 'Please select your Employee Name!',
                  },
                ]}>
                <SelectComp
                  size="middle"
                  showSearch
                  label="Employee Name"
                  placeholder="Select a employee name"
                  optionFilterProp="label"
                  value={userId}
                  options={allPeoplesOptions}
                  onSelect={(value) => {
                    setNoPayAllowed(
                      me?.id !== value ||
                        isAllowed(Permission.CREATE_EDIT_LEAVES_USER),
                    );
                    dispatch(getLeaveBalanceByUser(value.toString()));
                    getResignedDate(value);
                  }}></SelectComp>
              </Form.Item>
            ) : null}
            <Spin spinning={leavesBalanceUserLoading || leavesBalanceMeLoading}>
              <LeaveDetails leaveSummery={leaveSummery} />
            </Spin>
            <Form.Item
              name="leaveType"
              rules={[
                {
                  required: true,
                  message: 'Please select your leave type',
                },
              ]}>
              <SelectComp
                size="middle"
                showSearch
                label="Leave Type"
                placeholder="Please Select leave type"
                options={getLeaveTypes(
                  leaveSummery,
                  otherLeaves ? otherLeaves?.length : 0,
                  noPayAllowed,
                  organizationData,
                )}
                onSelect={(value) => {
                  if (value == LeaveTypesEnum.OTHER) {
                    setIsLeaveName(true);
                  } else {
                    setIsLeaveName(false);
                  }
                }}
              />
            </Form.Item>
            
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="from"
                  rules={[
                    {
                      required: true,
                      message: 'required',
                    },
                  ]}>
                  <DatePickerComp
                    size="middle"
                    label="From"
                    placeholder="Start Date"
                    disabledDate={(current) =>
                      isDisabledLeaveStartDate(
                        moment(current.toDate()),
                        !!organizationData?.leaveSettings.leaveReset.date,
                        moment(leaveResetDate),
                        resignedDate,
                      )
                    }
                    onChange={(value, dateString: string) => {
                      if (value) {
                        setStartValue(dateString);
                        if (
                          !endValue ||
                          moment(value.toDate()).valueOf() >
                            moment(endValue).valueOf()
                        ) {
                          setEndValue(value.format('YYYY-MM-DD'));
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {startValue && endValue && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="to">
                    <DatePickerComp
                      size="middle"
                      label="To"
                      defaultValue={
                        startValue ? dayjs(startValue) : dayjs(endValue)
                      }
                      disabled={startValue === undefined ? true : false}
                      placeholder="End Date"
                      disabledDate={(current) =>
                        isDisabledLeaveEndDate(
                          moment(current.toDate()),
                          moment(startValue),
                          !!organizationData?.leaveSettings.leaveReset.status &&
                            !!organizationData?.leaveSettings.leaveReset.date
                            ? moment(leaveResetDate)
                                .subtract(1, 'day')
                                .valueOf()
                            : moment().endOf('year').valueOf(),
                          resignedDate,
                        )
                      }
                      onChange={(value) => {
                        if (value) {
                          setEndValue(value.format('YYYY-MM-DD'));
                        } else {
                          setEndValue('');
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <RowContainer leaveDatesLength={leaveDates.length}>
              {leaveDates.map((item, i) => (
                <LeaveSelector
                  key={i}
                  leaveClose={(value) =>
                    onLeaveDayClose(
                      value,
                      leaveDates,
                      setStartValue,
                      setEndValue,
                      setLeaveDates,
                    )
                  }
                  leave={item}
                  onChangeHours={(value) =>
                    setLeaveDates(onHoursChange(value, leaveDates, i))
                  }
                />
              ))}
            </RowContainer>
            <Form.Item
              name="reason"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter a reason!',
                },
              ]}>
              <TextArea placeholder="Reason" />
            </Form.Item>{' '}
          </div>
        </Form>
      </LeaveRequestForm>
    </ModalComponent>
  );
};

export default ApplyLeaveModal;
