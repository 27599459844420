import React, { useEffect, useState } from 'react';
import {
  AlertMessage,
  B,
  DefaultBody,
  Div,
  FooterComponent,
  InputComp,
  PrimaryButton,
  SelectComp,
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../store';
import { Col, Form, Row, Spin } from 'antd';
import { useRequestLeaveAsAdmin } from '../../../api/leaveHooks';
import {
  getAllOrganizationLeaves,
  getLeaveBalanceByUser,
  getMinimumUserInfo,
  getMyLeaveRequests,
  getMyLeaves,
  getOrganizationLeaveRequests,
  leavesBalanceMe,
} from '../../../store/actions';
import {
  LeaveNameEnum,
  LeaveTypesEnum,
  Permission,
  RequestLeaveAdminI,
} from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import {
  disableSickleavesOrg,
  LEAVE_LIST,
  LEAVE_NAMES,
} from '../../../configs';
import styled from '@emotion/styled';
import { getApiError } from '../../../util/getApiError';
import { SelectValue } from 'antd/es/select';
import LeaveDetails from '../shared/LeaveDetails';
import theme from '../../../theme';
import LeavesBalance from './LeavesBalance';
import { useGetLeaveStatus } from '../utils/leave.util';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import LeaveBalanceComponent from './LeaveBalanceComponent';

const LeaveBalanceForm = styled.div`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;
const LeaveOverviewDiv = styled.div`
  padding: 0px 0px 16px 0px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const ManageLeaveBalancePage = () => {
  const me = useSelector((state: RootState) => state.people.me);

  useEffect(() => {
    if (isAllowed(Permission.MANAGE_ASSIGNED_USERS) || !!me?.isManager) {
      dispatch(getMinimumUserInfo());
    }
    if (!me?.isExternal) {
      dispatch(leavesBalanceMe());
    }
  }, []);
  const allPeople = useSelector((state: RootState) => state.people.allPeoples);
  const dispatch: DispatchType = useDispatch();
  const [visible, setVisible] = useState(true);
  const [isLeaveName, setIsLeaveName] = useState(false);
  const [form] = Form.useForm();

  const [userId, setUserId] = useState<null | number>(null);

  const leavesBalanceUserLoading = useSelector(
    (state: RootState) => state.leave.leavesBalanceUserLoading,
  );
  const leaveBalanceUser = useSelector(
    (state: RootState) => state.leave.leavesBalanceUser,
  );
  const leaveStatus = useGetLeaveStatus(leaveBalanceUser || []);

  const {
    isLoading: requestLoadingAsAdmin,
    error: requestErrorAsAdmin,
    data: requestDataAsAdmin,
    mutate: executeAdminPost,
  } = useRequestLeaveAsAdmin();

  useEffect(() => {
    if (requestDataAsAdmin && !requestErrorAsAdmin) {
      dispatch(leavesBalanceMe());
      dispatch(getAllOrganizationLeaves());
      dispatch(getMyLeaves());
      dispatch(getOrganizationLeaveRequests());
      dispatch(getMyLeaveRequests());
    }
  }, [requestDataAsAdmin]);

  useEffect(() => {
    if (userId) {
      dispatch(getLeaveBalanceByUser(userId.toString()));
    }
  }, [userId, requestDataAsAdmin]);

  const onSubmit = (data: RequestLeaveAdminI) => {
    const value: RequestLeaveAdminI = {
      ...data,
      userId: String(data.userId),
      type: 'ADD',
      hours: Number(data.hours),
      leaves: [],
    };
    executeAdminPost(value, {
      onSuccess: () => {
        // onClose();
      },
    });
  };

  const allPeoplesOptions = allPeople
    .filter(
      (item) =>
        (isManager() && item.id !== me?.id) ||
        isAllowed(Permission.CREATE_EDIT_LEAVES_USER),
    )
    .map((item) => {
      return {
        value: item.id,
        label: item.fullName,
      };
    });

  // to add labels and values of the custom leave types as an object
  const customLeaveTypeList =
    leaveBalanceUser?.filter(
      (items) =>
        items.leaveType === LeaveTypesEnum.CUSTOM && items.totalLeave > 0,
    ) || [];
  const CUSTOM_LEAVES_LIST = customLeaveTypeList?.map((customLeaves) => ({
    label: customLeaves.leaveName,
    value: customLeaves.leaveName,
  }));

  const mergedOptions = [
    ...LEAVE_LIST.filter((item) => item.value !== LeaveNameEnum.NO_PAY),
    ...CUSTOM_LEAVES_LIST,
  ];

  const LeavesBalanceRow = styled(Row)`
    overflow-x: auto !important;
  `;

  const onOk = () => {
    form && form.submit();
  };
  const submitText = 'save';

  return (
    <>
      <DefaultBody>
        <B type="b-large-semibold" style={{marginBottom:"20px"}} color={theme.black}>
          Manage Leave Balances
        </B>
        <LeaveBalanceForm>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={22}>
              <Form
                form={form}
                initialValues={{
                  userId: userId,
                }}
                layout="vertical"
                name="requestLeave"
                onFinish={onSubmit}>
                {requestErrorAsAdmin && (
                  <Div mb="12px">
                    <AlertMessage
                      title={getApiError(requestErrorAsAdmin)}
                      type="error"
                    />
                  </Div>
                )}
                <Row>
                  <Col xs={24} sm={24} md={18} lg={10} xl={6}>
                    <Form.Item
                      name="userId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Employee Name!',
                        },
                      ]}>
                      <SelectComp
                        size="middle"
                        label="Employee Name"
                        value={userId as SelectValue | undefined}
                        showSearch
                        placeholder="Select a employee name"
                        optionFilterProp="label"
                        onChange={(v) => setUserId(v as number)}
                        options={allPeoplesOptions}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Spin spinning={leavesBalanceUserLoading}>
                  {/* <LeaveDetails leaveSummery={leaveBalanceUser} /> */}
                  <B type="b-default-semibold" marginTop="10px">
                    Leave Balance
                  </B>
                  <LeavesBalanceRow>
                    <Col>
                      <LeaveBalanceComponent
                        leaveBalanceUser={leaveBalanceUser}
                        leaveStatus={leaveStatus}
                      />
                    </Col>
                  </LeavesBalanceRow>
                </Spin>
                <Row>
                  <Col xs={24} sm={20} md={12} lg={8} xl={6}>
                    <Form.Item
                      name="leaveType"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your leave type',
                        },
                      ]}>
                      <SelectComp
                        size="middle"
                        label="Leave Type"
                        placeholder="Please Select leave type"
                        options={mergedOptions}
                        onSelect={(value) => {
                          if (value == LeaveTypesEnum.OTHER) {
                            setIsLeaveName(true);
                          } else {
                            setIsLeaveName(false);
                          }
                        }}
                      />
                    </Form.Item>

                    {isLeaveName && (
                      <Form.Item
                        name="leaveName"
                        rules={[
                          {
                            required: true,
                            message: 'Please select your leave Name',
                          },
                        ]}>
                        <SelectComp
                          size="middle"
                          label="Leave Name"
                          placeholder="Please Select leave name"
                          options={LEAVE_NAMES}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="hours"
                      rules={[
                        {
                          required: true,
                          message: 'Number of hours are required',
                        },
                      ]}>
                      <InputComp size="small" type="number" label="Hours" />
                    </Form.Item>

                    <Form.Item
                      name="reason"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a reason!',
                        },
                      ]}>
                      <InputComp
                        size="small"
                        label="Reason"
                        placeholder="Reason for leave"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Div mt="80px">
            <FooterComponent
              rightChildren={
                <PrimaryButton
                  disabled={false}
                  onClick={onOk}
                  htmlType="submit"
                  loading={requestLoadingAsAdmin}>
                  {submitText}
                </PrimaryButton>
              }
            />
          </Div>
        </LeaveBalanceForm>
      </DefaultBody>
    </>
  );
};
