import styled from '@emotion/styled';
import { Layout, Menu } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { PROFILE_MENU_ITEMS, ProfileMenuItemType } from '../../../configs';
import theme from '../../../theme';

const { Content, Sider } = Layout;

const SideMenu = styled(Menu)`
  border: 0;
  .ant-menu-item,
  .ant-menu-submenu.ant-menu-submenu-inline {
    border-bottom: 1px solid ${theme.gray300};
    margin: 0 !important;
    .ant-menu-submenu-title {
      margin: 0;
      padding: 0 32px !important;
    }
    .ant-menu.ant-menu-sub.ant-menu-inline {
      .ant-menu-item {
        border-bottom: 0;
      }
    }
  }
  .ant-select-selector {
    width: 50%;
  }
  .ant-menu-item-selected {
    background: #e6eefd !important;
    a {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: ${theme.blue500}!important;
      cursor: initial;
    }
  }
  .ant-menu-item::after {
    border-right: 0px !important;
  }
`;
const MenuItem = styled(Menu.Item)`
  height: 48px !important;
  padding-top: 5px !important;
  border-right: 1px solid ${theme.gray300};
`;

interface Props {
  onChange: (value: string) => void;
}

export const AccountDetailMenu: FC<Props> = ({ onChange }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const [userMenuItems, setUserMenuItems] =
    useState<ProfileMenuItemType[]>(PROFILE_MENU_ITEMS);

  useEffect(() => {
    if (activeMenu) {
      onChange(activeMenu);
    }
  }, [activeMenu]);

  useEffect(() => {
    setUserMenuItems(PROFILE_MENU_ITEMS);
  }, []);

  useEffect(() => {
    setActiveMenu(getCurrentActiveMenu());
  }, [userMenuItems, location]);

  function getCurrentActiveMenu() {
    const currentPath = userMenuItems.find(
      (item) => `${item.path}` === location.pathname,
    );
    return currentPath?.displayName || '';
  }

  return (
    <Content>
      <Layout className="site-layout-background">
        <Sider className="site-layout-background" width="100%">
          <SideMenu
            onClick={(event) => {
              setActiveMenu(event.key as any);
            }}
            selectedKeys={[activeMenu]}
            mode="inline">
            {userMenuItems.map((menuItem) => {
              return (
                <MenuItem key={menuItem.displayName}>
                  <Link to={`${menuItem.path}`}>{menuItem.displayName}</Link>
                </MenuItem>
              );
            })}
          </SideMenu>
        </Sider>
      </Layout>
    </Content>
  );
};
