import { LeaveNameEnum, LeaveTypeEnum, UserRole } from '.';
import { LeaveStatus } from '../containers/home/homeUtil';
import {
  BirthdayI,
  CelebrationReturnI,
  UpcomingTypesEnum,
} from './organization.types';
import { ContractReminderEnum, UserType } from './contract.types';

export enum NotificationStatusEnum {
  PENDING = 'PENDING',
  DONE = 'DONE',
  SNOOZED = 'SNOOZED',
}
export enum NotificationTypeEnum {
  NOTE = 'NOTE',
  GRATUITY = 'GRATUITY',
  T10 = 'T10',
  ONBOARDING = 'ONBOARDING',
}

export interface NotificationItemI {
  id?: number;
  userId?: number;
  type: NotificationTypeEnum.NOTE;
  note: string;
  reminderDate?: string | null;
  createdAt?: string | null;
  status?: string | null;
  isLoanNote?: boolean;
  loanId?: string;
}

export interface ChangeNotificationStatusI {
  id: number;
  status: NotificationStatusEnum;
  snoozedDate?: string | null;
}

export type Reminders =
  | ReminderNotificationI
  | ReminderLeaveI
  | ReminderContractI
  | BirthdayCelebrationI
  | AnniversaryCelebrationI
  | NoAnyNotificationI;

export type UpcomingEventsI =
  | BirthdayCelebrationI
  | AnniversaryCelebrationI
  | NoAnyNotificationI;

export enum ReminderTypeEnum {
  NOTIFICATION = 'NOTIFICATION',
  LEAVE = 'LEAVE',
  CONTRACT = 'CONTRACT',
}

export interface NotificationPayloadI {
  id: number;
  type: NotificationTypeEnum;
  createdById: number;
  userId: number;
  note: string;
  reminderDate: string | null;
  status: NotificationStatusEnum | null;
  snoozedDate: string | null;
  user: UserI;
}
export interface UserI {
  fullName: string;
  preferredName: string;
}

export interface ReminderNotificationI {
  type: ReminderTypeEnum.NOTIFICATION;
  payload: NotificationPayloadI;
  effectiveDate: string | null;
}

export interface ReminderContractI {
  type: ReminderTypeEnum.CONTRACT;
  payload: ContractPayloadI;
  effectiveDate: string | null;
}
export interface ReminderLeaveI {
  type: ReminderTypeEnum.LEAVE;
  payload: LeavePayloadI;
  effectiveDate: string | null;
}
export interface BirthdayCelebrationI {
  type: UpcomingTypesEnum.BirthDay;
  payload: BirthdayI;
  effectiveDate: string | null;
}

export interface AnniversaryCelebrationI {
  type: UpcomingTypesEnum.Anniversary;
  payload: CelebrationReturnI;
  effectiveDate: string | null;
}

export interface NoAnyNotificationI {
  type: UpcomingTypesEnum.No_Any_Notifications;
  payload: {};
  effectiveDate: string | null;
}
export interface LeavePayloadI {
  id: number;
  reason: string;
  to: string;
  from: string;
  date: string;
  hours: number;
  createdById: number;
  reviewById: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  status: LeaveStatus;
  type: LeaveTypeEnum.DEDUCT;
  leaveType: LeaveNameEnum;
  leaveName: string | null;
  user: UserI;
  createdBy: CreatedI;
  reviewBy: ReviewI;
}

export interface ContractPayloadI {
  type: ContractReminderEnum;
  data: ContractDataI;
}

export interface ContractI {
  name: string;
}
export interface ContractDataI {
  contractId: number;
  userId: number;
  userType: UserType;
  isSend: true;
  isSigned: true;
  updatedAt: string;
  createdAt: string;
  contract: ContractI;
  user: UserI;
}

export interface ReviewI {
  userRole: UserRole;
  fullName: string;
}
export interface CreatedI {
  fullName: string;
}
