
const MOBILE_MAX_WIDTH = 760;
const TABLET_MAX_WIDTH = 1030;
const LAPTOP_MAX_WIDTH = 1440;
const DESKTOP_MAX_WIDTH = 2000;

const LAPTOP_MAX_HEIGHT = '900px';
const DESKTOP_MAX_HEIGHT = '1080px';

const theme = {
  black: '#000000',
  black100: '#424242',
  white: '#FFFFFF',
  offWhite: '#EAEAEC',
  // blue ----------------------------
  blue50: '#3375EE',
  blue100: '#CCDCFB',
  blue200: '#99BAF7',
  blue300: '#6697F2',
  blue400: '#3375EE',
  blue450: '#006AFF',
  blue500: '#0052EA',
  blue600: '#0042BB',
  blue700: '#00318c',
  blue800: '#00215E',
  blue900: '#00102F',

  //light-blue------------------------
  lightBlue50: '#EBF1FD',
  lightBlue100: '#E6EEFD',

  // gray ----------------------------
  gray25: '#FBFBFB',
  gray50: '#FAFAFA',
  gray100: '#F5F5F5',
  gray150: '#F8F8F8',
  gray200: '#EEEEEE',
  gray300: '#E0E0E0',
  gray350: '#EAEAEA',
  gray400: '#BDBDBD',
  gray500: '#9E9E9E',
  gray600: '#757575',
  gray700: '#616161',
  gray750: '#6B6B6B',
  gray800: '#9CA3AF',
  gray850: '#5F6267',
  gray900: '#212121',
  gray950: '#D1D5DB',

  // red ----------------------------
  red50: '#FFEBEE',
  red100: '#EF5350',
  red200: '#F44336',
  red300: '#C62828',
  red500: '#ff0000',
  red600: '#D50000',
  red700: '#FFB9B9',
  red800: '#FFD89E',

  // orange -------------------------
  orange50: '#FEFEF5',
  orange100: '#FFC459',
  orange200: '#FAA719',
  orange300: '#BD640D',
  orange400: '#eb9400',
  orange500: '#EA8C00',
  // green --------------------------
  green50: '#E8F5E9',
  green100: '#2FEA00',
  green300: '#205823',
  green500: '#2E7D32',
  green600: '#256428',
  green700: '#58975B',

  //light-blue------------------------
  lightGreen50: '#dbf0dc',

  // light-yellow---------------------
  lightYellow50: '#FFF4CD',

  // warning -------------------------
  warn: '#FFF7DA',

  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  LAPTOP_MAX_WIDTH,
  DESKTOP_MAX_WIDTH,

  LAPTOP_MAX_HEIGHT,
  DESKTOP_MAX_HEIGHT,

  contract: {
    sender: {
      default: '#B6842D',
      hover: '#FDDF91',
      label: '#FFC459',
    },
    receiver: {
      default: '#0052EA',
      hover: '#E6EEFD',
      label: '#3375EE',
    },
  },
};

export default theme;
