import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import {
  B,
  Div,
  PrimaryButton,
  SwitchComponent,
} from '../../../../../components';
import { Col, Form, Row } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import theme from '../../../../../theme';
import { Divider } from '../../../../../components/Divider';

import {
  useGetOrganizationData,
  useUpdateOrganizationData,
} from '../../../../../api/superAdminHooks';
import { CustomizedFeaturesI } from '../../../../../types/superAdmin.types';
import { useParams } from 'react-router-dom';

interface Props {
  organizationId: number;
}
type Params = { accountId: string };

const CustomForm = styled(Form)`
  height: 448px;
  padding: 10px 24px;
`;

const MainDiv = styled.div`
  padding: 50px;
  width: 100%;
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  ${space}
  ${layout}
`;

const UserFeatures: FC<Props> = ({ organizationId }) => {
  const [features, setFeatures] = useState<CustomizedFeaturesI>({
    isEnablePayroll: false,
    isEnableContract: false,
    isEnableLeave: false,
    isEnableExpenses: false,
    isEnableLoan: false,
    isEnableGratuity: false,
    isEnableReport: false,
    isEnableTimeAttendance: false,
  });
  const [form] = Form.useForm();
  const params = useParams<Params>();

  const { mutate: getOrganizationsDataByOwner, data: organizationData } =
    useGetOrganizationData();

  useEffect(() => {
    getOrganizationsDataByOwner(Number(params.accountId));
  }, [params.accountId]);

  const handleFeatureChange = (
    key: keyof CustomizedFeaturesI,
    value: boolean,
  ) => {
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (organizationData) {
      const organization = organizationData.find(
        (org) => org.organizationId === organizationId,
      );

      const customizedFeatures = organization?.customizedFeatures;

      if (customizedFeatures) {
        for (const [key, value] of Object.entries(customizedFeatures)) {
          form.setFieldsValue({ [key]: value });
        }
        setFeatures({
          ...customizedFeatures,
        });
      }
    }
  }, [organizationData, organizationId]);

  const {
    mutate: updateFeatures,
    error: errorUpdateFeatures,
    data: dataUpdateFeatures,
  } = useUpdateOrganizationData();

  useEffect(() => {
    if (dataUpdateFeatures && !errorUpdateFeatures) {
      getOrganizationsDataByOwner(Number(params.accountId));
    }
  }, [dataUpdateFeatures]);

  const onFinish = (values: CustomizedFeaturesI) => {
    const value: CustomizedFeaturesI = {
      isEnablePayroll: values.isEnablePayroll || false,
      isEnableContract: values.isEnableContract || false,
      isEnableLeave: values.isEnableLeave || false,
      isEnableExpenses: values.isEnableExpenses || false,
      isEnableLoan: values.isEnableLoan || false,
      isEnableGratuity: values.isEnableGratuity || false,
      isEnableReport: values.isEnableReport || false,
      isEnableTimeAttendance: values.isEnableTimeAttendance || false,
    };

    updateFeatures({ id: organizationId, data: value });
  };

  return (
    <>
      <CustomForm form={form} onFinish={onFinish}>
        <Divider />
        <MainDiv>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Payroll
              </B>
              <Div display="flex">
                <FormItem name="isEnablePayroll" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnablePayroll}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnablePayroll', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnablePayroll
                    ? 'Disable Payroll'
                    : 'Enable Payroll'}
                </B>
              </Div>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Contract
              </B>
              <Div display="flex">
                <FormItem name="isEnableContract" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableContract}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableContract', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableContract
                    ? 'Disable Contract'
                    : 'Enable Contract'}
                </B>
              </Div>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Leave
              </B>
              <Div display="flex">
                <FormItem name="isEnableLeave" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableLeave}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableLeave', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableLeave ? 'Disable Leave' : 'Enable Leave'}
                </B>
              </Div>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Expenses
              </B>
              <Div display="flex">
                <FormItem name="isEnableExpenses" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableExpenses}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableExpenses', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableExpenses
                    ? 'Disable Expenses'
                    : 'Enable Expenses'}
                </B>
              </Div>
            </Col>
          </Row>
        </MainDiv>
        <MainDiv>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Loan
              </B>
              <Div display="flex">
                <FormItem name="isEnableLoan" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableLoan}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableLoan', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableLoan ? 'Disable Loan' : 'Enable Loan'}
                </B>
              </Div>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Gratuity
              </B>
              <Div display="flex">
                <FormItem name="isEnableGratuity" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableGratuity}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableGratuity', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableGratuity
                    ? 'Disable Gratuity'
                    : 'Enable Gratuity'}
                </B>
              </Div>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Report
              </B>
              <Div display="flex">
                <FormItem name="isEnableReport" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableReport}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableReport', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableReport ? 'Disable Report' : 'Enable Report'}
                </B>
              </Div>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <B type="b-large-semibold" pb="16px">
                Time Attendance
              </B>
              <Div display="flex">
                <FormItem name="isEnableTimeAttendance" valuePropName="checked">
                  <SwitchComponent
                    checked={features.isEnableTimeAttendance}
                    onChange={(checked: boolean) =>
                      handleFeatureChange('isEnableTimeAttendance', checked)
                    }
                  />
                </FormItem>
                <B type="b-default" pl="10px" pt="5px" color={theme.gray600}>
                  {features.isEnableTimeAttendance
                    ? 'Disable Time Attendance'
                    : 'Enable Time Attendance'}
                </B>
              </Div>
            </Col>
          </Row>
        </MainDiv>
        <Divider />
        <MainDiv>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}></Col>
            <Col xs={24} sm={12} md={6}></Col>
            <Col xs={24} sm={12} md={6}></Col>
            <Col xs={24} sm={12} md={6}>
              <PrimaryButton ml={16} htmlType="submit">
                Save
              </PrimaryButton>
            </Col>
          </Row>
        </MainDiv>
      </CustomForm>
    </>
  );
};

export default UserFeatures;
