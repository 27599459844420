import { css } from '@emotion/react';
import { Form, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import {
  AlertMessage,
  B,
  Div,
  InputNumberComp,
  LabeledInputV2,
  ModalComponent,
  SelectV2,
  SwitchComponent,
} from '../../../components';
import theme from '../../../theme';
import { PaymentMethod, SettleLoanI } from '../../../types/loan.types';
import { numberThousandSeparator } from '../../../util/utils';
import { SelectValue } from 'antd/lib/select';

interface Props {
  visible: boolean;
  onSubmit: (formValues: SettleLoanI) => void;
  onCancel: () => void;
  loanBalance?: number;
  netSalary?: number;
}

const LoanOverview: FC<Props> = ({
  visible,
  onSubmit,
  onCancel,
  loanBalance,
  netSalary,
}) => {
  const [form] = Form.useForm();
  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<SelectValue>();

  const isNotValidLoan = Number(netSalary) < Number(amount);

  const onFinish = (FormValues: SettleLoanI) => {
    const values = {
      ...FormValues,
      markAsPaid: isAllowed,
    };
    onSubmit(values);
  };

  return (
    <ModalComponent
      form={form}
      centered
      width={540}
      visible={visible}
      disabled={isNotValidLoan && paymentMethod === PaymentMethod.PAYROLL}
      onCancel={onCancel}
      submitText="Submit"
      title={
        <B type="b-large-semibold" color={theme.black}>
          Settle Loan
        </B>
      }>
      <Form
        form={form}
        layout="vertical"
        wrapperCol={{ span: 50 }}
        onFinish={onFinish}>
        {isNotValidLoan && paymentMethod === PaymentMethod.PAYROLL && (
          <Div Pb="16px">
            <AlertMessage
              iconCssStyle="margin-top: -15px"
              type="error"
              title="Loan instalment size is larger than the last drawn net salary, please adjust accordingly "></AlertMessage>
          </Div>
        )}
        <Div display="flex" justifyContent="space-between" pb="24px">
          <B type="b-default-semibold">Loan Balance</B>
          <B type="b-default-semibold">
            LKR {loanBalance && numberThousandSeparator(loanBalance)}
          </B>
        </Div>
        <LabeledInputV2 label="Method of Payment*">
          <Form.Item
            name="paymentMethod"
            rules={[
              {
                required: true,
                message: 'Please select Payment Method',
              },
            ]}>
            <SelectV2
              placeholder="Choose"
              options={[
                {
                  label: 'Via Payroll',
                  value: PaymentMethod.PAYROLL,
                },
                {
                  label: 'Via External Funds',
                  value: PaymentMethod.EXTERNAL_FUNDS,
                },
              ]}
              onChange={(v) => setPaymentMethod(v)}
            />
          </Form.Item>
        </LabeledInputV2>
        <LabeledInputV2 label="Amount to be Paid*">
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please Enter Amount',
              },
            ]}>
            <InputNumberComp
              max={loanBalance}
              size="middle"
              placeholder="Enter Amount"
              onChange={(v) => setAmount(Number(v))}
            />
          </Form.Item>
        </LabeledInputV2>
        <Form.Item
          name="markAsPaid"
          valuePropName="checked"
          css={css`
            margin-bottom: 0;
          `}>
          <Div display="flex" alignItems="center">
            <B type="b-small" pr="13px">
              Mark as paid
            </B>
            <Tooltip
              title='When you mark a loan as "paid", the loan will be considered as settled'
              placement="right"
              visible={isAllowed}>
              <SwitchComponent
                onChange={(checked: boolean) => setIsAllowed(checked)}
              />
            </Tooltip>
          </Div>
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default LoanOverview;
