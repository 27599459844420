const ENVIRONMENT = process.env.REACT_APP_ENV_VALUE || 'local';
export const isStage = ENVIRONMENT === 'stage';
export const isLocal = ENVIRONMENT === 'local';
export const isProduction = ['master', 'release'].includes(ENVIRONMENT);
export let bambooHrOrganisations: number[];
export let contractEnabledOrg: number[];
export let LoanEnabledOrg: number[];
export let orgsWithSeparateEPFExecutives: number[] = [];
export let epfBorneByEmployerOrg: number[] = [];
export let pastYearEnableOrg: number[] = [];
epfBorneByEmployerOrg = [];
interface orgBasedFeatureConfig {
  exportConfig: {
    krestonExportCompanies: number[];
    ytdEnabledCompanies: number[];
    bakertillyOrgIds: number[];
  };
}

export const disableSickleavesOrg = 73; //this disables displaying sick leaves for organisation kbooks (GH-1564)

export const OCCUPATION_GRADE_URL =
  'https://docs.google.com/spreadsheets/d/18-Cf8tM7Bb8W8wnE1OP620UGbbdkNSC6/edit#gid=1013098337';

export const SENTRY_URL = isProduction
  ? 'https://3f5c807e0e5e44d39c977de3ea43765c@o1170660.ingest.sentry.io/6264388'
  : isStage
  ? 'https://288f7bf0deda494a9d369ccaff5be312@o1170660.ingest.sentry.io/6264400'
  : null;

export const orgBasedFeatureConfig: orgBasedFeatureConfig = {
  exportConfig: {
    krestonExportCompanies: [
      161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    ],
    ytdEnabledCompanies: [],
    bakertillyOrgIds: [3, 291],
  },
};

if (ENVIRONMENT === 'stage') {
  bambooHrOrganisations = [2, 31];
  contractEnabledOrg = [271, 286, 287];
  LoanEnabledOrg = [];
  orgsWithSeparateEPFExecutives = [220, 221];
  epfBorneByEmployerOrg = [222];
  pastYearEnableOrg = [];

  orgBasedFeatureConfig.exportConfig.ytdEnabledCompanies = [
    3, 295, 296, 299, 300, 301, 302, 303, 304, 305, 306, 308, 309, 310, 311,
  ];

  orgBasedFeatureConfig.exportConfig.bakertillyOrgIds = [3, 291];

  orgBasedFeatureConfig.exportConfig.krestonExportCompanies = [
    30, 166, 168, 169, 286, 6, 206, 3, 209, 177, 258,
  ];
} else if (ENVIRONMENT === 'master') {
  bambooHrOrganisations = [9, 10, 127];
  contractEnabledOrg = [9, 10, 83];
  LoanEnabledOrg = [9, 10, 83, 109, 111, 112, 113, 114, 51];
  for (let i = 76; i <= 81; i++) {
    orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(i);
  }
  for (let i = 89; i <= 101; i++) {
    if (i !== 95 && i !== 96) {
      orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(i);
    }
  }
  for (let i = 109; i <= 118; i++) {
    if (i !== 110) {
      orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(i);
    }
  }
  // This 64 is testing
  orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(64);
  pastYearEnableOrg = [230];
  for (let i = 76; i <= 81; i++) {
    orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(i);
  }
  for (let i = 89; i <= 101; i++) {
    if (i !== 95 && i !== 96) {
      orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(i);
    }
  }
  for (let i = 109; i <= 118; i++) {
    if (i !== 110) {
      orgBasedFeatureConfig.exportConfig.bakertillyOrgIds.push(i);
    }
  }

  //Production rebuild

  // 186
  orgsWithSeparateEPFExecutives = [];

  // 149
  epfBorneByEmployerOrg = [];

  orgBasedFeatureConfig.exportConfig.ytdEnabledCompanies = [185, 51];

  for (let id = 142; id <= 191; id++) {
    orgBasedFeatureConfig.exportConfig.krestonExportCompanies.push(id);
  }

  // testing accounts with kreston export sheet
  orgBasedFeatureConfig.exportConfig.krestonExportCompanies.push(
    63,
    87,
    253,
    262,
    263,
    264,
    265,
  );
}

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:3001/api';
