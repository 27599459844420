import React, { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';
import UserPeopleSelect from '../../../people/shared/UserPeopleSelect';
import { AccountDetailLayout } from '../../components/AccountDetailLayout';
import { B, CustomDivider } from '../../../../components';
import theme from '../../../../theme';
import LoanDetailsOverview from '../../../people/pages/loan/LoanDetailsOverview';
import LoanListTable from '../../../people/pages/loan/LoanListTable';
import { useGetUserLoanList } from '../../../../api/loanHooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';

const MainContainer = styled.div`
  padding: 32px 24px 0px;
  @media (max-width: 768px) {
    padding: 0px 0px 24px;
  }
`;

const LoanDetails: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);
  const { data: loanList = [], refetch: getloanList } = useGetUserLoanList(me?.id);

  useEffect(() => {
    if (me?.id) {
      getloanList();
    }
  }, [me?.id]);


  return (
    <>
      <AccountDetailLayout>
        <MainContainer>
          <Row>
            <Col xs={0} sm={0} md={0} lg={16} xl={16} xxl={19}>
              <B type="b-large-semibold" Pb="24px">
                Loan Details
              </B>
            </Col>
            <Col xs={12} sm={14} md={12} lg={0} xl={0} xxl={0}>
              <UserPeopleSelect defaultIndex="5" />
            </Col>
          </Row>
          <LoanDetailsOverview loanList={loanList} />
          <CustomDivider
            mt={16}
            mb={32}
            borderTop={`0.5px solid ${theme.gray300}`}
          />
          <LoanListTable loanList={loanList} userLoan />
        </MainContainer>
      </AccountDetailLayout>
    </>
  );
};

export default LoanDetails;
