import styled from '@emotion/styled';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC, useEffect, useState } from 'react';
import { B, Div, InputComp } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { CustomLeaveTypesI } from '../../../types/organization.types';
import { EditSVG, GrayCancel } from '../../../assets';
import { InputTag } from './AddCustomLeave';
import { Validation } from '../../../common/validation';

const MainDiv = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 22px 16px 19px;
  height: 56px;
  justify-content: space-between;
`;

const CustomFormItem = styled(FormItem)`
  width: 187px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CustomInputComp = styled(InputComp)`
  height: 24px;
  width: 160px;
  margin-left: 10px;
`;

const EditIcon = styled.img`
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

interface Props {
  customLeaves: CustomLeaveTypesI[];
  onEdit: (id: number | string) => void;
  onRemove: (id: string) => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onChangeNoOfDays: (value: number, id: number | string) => void;
  onChangeCheckBox: (e: CheckboxChangeEvent, id: number | string) => void;
}

const CustomLeaveTypeList: FC<Props> = ({
  customLeaves,
  onEdit,
  onRemove,
  onChange,
  onChangeNoOfDays,
  onChangeCheckBox,
}) => {
  const [editIndex, setEditIndex] = useState(-1);

  const edit = (
    index: number,
    customLeaveName: string,
    id: number | string,
  ) => {
    if (index === editIndex) {
      return (
        <FormItem
          name={customLeaveName + index}
          rules={[
            {
              required: true,
              message: 'Please enter custom leaves',
            },
          ]}>
          <CustomInputComp
            onChange={(e) => onChange(e)}
            onBlur={() => {
              onEdit(id);
              setEditIndex(-1);
            }}
            defaultValue={customLeaveName}
          />
        </FormItem>
      );
    } else {
      return (
        <B type="b-default" pl="18px" pt="6px">
          {customLeaveName}
        </B>
      );
    }
  };

  return (
    <>
      {customLeaves.map((item, index) => {
        return (
          <MainDiv>
            <Div display="flex">
              <FormItem name={'isEnable' + index}>
                <CheckBoxComp
                  defaultChecked={item.isEnable}
                  onChange={(e) => onChangeCheckBox(e, item.id)}
                />
              </FormItem>
              {edit(index, item.customLeaveName, item.id)}
            </Div>
            <Div display="flex">
              <Div pt="4px" pr="36px">
                <EditIcon onClick={() => setEditIndex(index)} src={EditSVG} />
              </Div>
              <CustomFormItem
                rules={[
                  {
                    pattern: Validation.NoOfDaysPerYearPattern,
                    message: 'Should be between 0 and 365',
                  },
                ]}
                name={'noOfDays' + index}>
                <InputComp
                  onChange={(e) => {
                    onChangeNoOfDays(Number(e.target.value), item.id);
                  }}
                  defaultValue={item.noOfDays}
                  size="small"
                  suffix={<InputTag>days</InputTag>}
                />
              </CustomFormItem>

              <Div pl="38px" pt="4px">
                <CloseIcon
                  onClick={() => {
                    if (item.id) {
                      onRemove(item.id);
                    }
                  }}
                  src={GrayCancel}
                />
              </Div>
            </Div>
          </MainDiv>
        );
      })}
    </>
  );
};

export default CustomLeaveTypeList;
