import { ColorObject } from "../types/colorObject.types";

const colorOptions: ColorObject[] = [
    { bgColor: '#FFEBEE', textColor: '#BD0F2C' },
    { bgColor: '#FDEFE2', textColor: '#C56511' },
    { bgColor: '#E7F9F3', textColor: '#216E55' },
    { bgColor: '#EDEEFD', textColor: '#05128A' },
    { bgColor: '#ECFAFE', textColor: '#1F84A3' },
    { bgColor: '#F2FFD1', textColor: '#526E0C' },
    { bgColor: '#FFF7E0', textColor: '#935F10' },
    { bgColor: '#FDF1F7', textColor: '#973562' },
  ];
  
  export const getRandomColor=(prevColor: ColorObject)=> {
    let newColor: ColorObject;
  
    do {
      newColor = colorOptions[Math.floor(Math.random() * colorOptions.length)];
    } while (newColor.bgColor === prevColor.bgColor && newColor.textColor === prevColor.textColor);
  
    return newColor;
  }