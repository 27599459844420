import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LayoutRouteProps,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import AccountPayDetails from './containers/account-details/pages/account-pay-details/AccountPayDetails';
import BankDetail from './containers/account-details/pages/bank-details/BankDetail';
import DocumentDetail from './containers/account-details/pages/document-details/DocumentDetail';
import EmploymentDetail from './containers/account-details/pages/employement-details/EmploymentDetail';
import Profile from './containers/account-details/pages/persona-details/Profile';
import ForgotPassword from './containers/auth/ForgotPassword';
import Login from './containers/auth/Login';
import PasswordResetLinkSent from './containers/auth/PasswordResetLinkSent';
import RestPassword from './containers/auth/RestPassword';
import Signup from './containers/auth/Signup';
import ViewNonUserContract from './containers/contract/contract-view/ViewNonUserContract';
import ContractEditHome from './containers/contract/ContractEditHome';
//contract......................................................
import ContractPage from './containers/contract/ContractPage';
import ContractViewHome from './containers/contract/ContractViewHome';
import ExpenseCategoriesPage from './containers/expenses/pages/ExpenseCategoriesPage';
import ExpenseManagePage from './containers/expenses/pages/ExpenseManagePage';
// Expenses
import ExpensesListPage from './containers/expenses/pages/ExpensesListPage';
import ExpensesMerchantsPage from './containers/expenses/pages/ExpensesMerchantsPage';
import GeneratedBankFiles from './containers/expenses/pages/generated-bank-files/GeneratedBankFiles';
import Home from './containers/home/Home';
import EmptyPage from './containers/layout/EmptyPage';
import Layout from './containers/layout/Layout';
import LeaveBalancePage from './containers/leave/leave-balance/LeaveBalancePage';
//Leave........................
import LeaveHomePage from './containers/leave/LeaveHomePage';
import ApplyLeave from './containers/leave/new-leave/ApplyLeave';
import PayrollHomePage from './containers/payroll/pages/payroll-home/PayrollHomePage';
import PayslipLoading from './containers/payroll/pages/payroll-loading/PayslipLoading';
import PayrollViewPage from './containers/payroll/pages/payroll-view/PayrollViewPage';
import PayrunUsingCSV from './containers/payroll/pages/payrun-using-CSV/PayrunUsingCSV';
import PaySlipPage from './containers/payroll/pages/payslip/PaySlipPage';
import PublishPayrun from './containers/payroll/pages/publish-payrun/PublishPayrun';
import AddEmployeeManuallyPage from './containers/people/pages/addEmployeeManually/AddEmployeeManuallyPage';
import AddEmployeesCSV from './containers/people/pages/AddEmployeesUsingCSV/AddEmployeesCSV';
// People --------
import BankDetailsPage from './containers/people/pages/bank-page/BankDetailsPage';
import Document from './containers/people/pages/document/Document';
import EmergencyDetails from './containers/people/pages/emergency/EmergencyDetails';
import EmploymentPage from './containers/people/pages/employment/EmploymentPage';
import LoanPage from './containers/people/pages/loan/LoanPage';
import AllPeopleLogTrail from './containers/people/pages/log-trail/AllPeopleLogTrail';
import EmployeeLogTrailPage from './containers/people/pages/log-trail/EmployeeLogTrailPage';
import NotePage from './containers/people/pages/notes/NotePage';
import PayDetails from './containers/people/pages/pay-info/PayItemsPage';
import PeopleSettingsPage from './containers/people/pages/people-settings/PeopleSettingsPage';
import PersonalDetails from './containers/people/pages/personal-details/PersonalDetails';
import PeopleTable from './containers/people/PeopleTable';
import HeadCountReportPage from './containers/reports/pages/employee/HeadCountReportPage';
import LeaveBalanceReportPage from './containers/reports/pages/leave/LeaveBalanceReport';
import DetailedReportPage from './containers/reports/pages/payroll/DetailedReportPage';
// Reports
import FilesAndForms from './containers/reports/pages/files-and-forms/FilesAndForms';
import SummaryReportPage from './containers/reports/pages/payroll/SummaryReportPage';
//Accounting Firms
import AccountingFirms from './containers/settings/accounting-firms/AccountingFirms';
import BankInformation from './containers/settings/BankInformation';
// Settings --------
import CompanySettings from './containers/settings/company-settings/CompanySettings';
import Customization from './containers/settings/Customization';
import SystemUpdates from './containers/settings/SystemUpdates';
//Export
import DataExportPage from './containers/settings/data-exports/DataExportPage';
import IntegrationLogTrailPage from './containers/settings/integration-apps/IntegrationLogTrailPage';
import IntegrationSettingPage from './containers/settings/integration-apps/IntegrationSettingPage';
import IntegrationListPage from './containers/settings/integration-list/IntegrationListPage';
import Security from './containers/settings/Security';
import AccountHomePage from './containers/super-admin/pages/account-page/AccountHomePage';
import CreateNewOrganization from './containers/super-admin/pages/create-organization/CreateNewOrganization';
//Super Admin
import SuperHomePage from './containers/super-admin/pages/home-page/SuperHomePage';
import SuperSystemUpdates from './containers/super-admin/pages/system-updates/SuperSystemUpdates';
// Other
import BankConfirmThankYou from './public-widgets/csv-onboarding/open-bank-confirmation-thank-you/BankConfirmThankYou';
// Payroll
import BankConfirmation from './public-widgets/csv-onboarding/open-bank-confirmation/BankConfirmation';
import MapColumns from './public-widgets/csv-onboarding/open-column-mapping/MapColumns';
import UploadNewPayRunCSV from './public-widgets/csv-onboarding/open-payroll/UploadNewPayRunCSV';
import IndividualSalary from './public-widgets/csv-onboarding/open-personal-salary-edit/IndividualSalary';
import SalaryInformation from './public-widgets/csv-onboarding/open-salary-information/SalaryInformation';
//Loan
import AddLoanPage from './containers/loan/add-loan/AddLoanPage';
import LoanHome from './containers/loan/loan-home/LoanHome';
import ViewLoanPage from './containers/loan/view-loan/ViewLoanPage';
//Gratuity
import GratuityHome from './containers/gratuity/gratuity-home/GratuityHome';
import GratuityLogTrail from './containers/gratuity/gratuity-home/GratuityLogTrail';
import ViewGratuityPage from './containers/gratuity/view-gratuity/ViewGratuityPage';
// public
import LoanDetails from './containers/account-details/pages/loan-details/LoanDetails';
import ViewUserLoanPage from './containers/account-details/pages/loan-details/ViewUserLoanPage';
import LoanGeneratedBankFilesTable from './containers/loan/generatedBankFile/LoanGeneratedBankFilesTable';
import IndividualLoanLogTrail from './containers/loan/shared/IndividualLoanLogTrail';
import LoanLogTrail from './containers/loan/shared/LoanLogTrail';
import Subscription from './containers/subscription/Subscription';
import TimeAttendance from './containers/time-attendance/pages/home/TimeAttendance';
import View from './containers/time-attendance/pages/view/View';
import ViewAttendance from './containers/time-attendance/pages/viewAttendance/ViewAttendance';
import SalaryCalculator from './public-widgets/salary-calculator/SalaryCalculator';
import { DispatchType, RootState } from './store/reducer';
import { UserRoleIdEnum } from './types';
import ViewTemplates from './containers/contract/templates/ViewTemplates';
import EmailOnboarding from './public-widgets/email-onboarding/EmailOnBoarding';
import TeamConfiguration from './containers/teamConfiguration/TeamConfiguration';
import { Permission } from './types/permissions.enum';
import { SubscriptionEnum } from './types/subscription.types';
import { getPathWithOrgId } from './util/getPathWithOrgId';
import { isAllowedAnyOf } from './util/permissionUtil';
import { getCurrentAuthToken } from './util/storageUtil';
import TimeAttendanceSettings from './containers/time-attendance/time-attendance-settings/TimeAttendanceSettings';
import AttendanceLogTrail from './containers/time-attendance/pages/logtrail/AttendanceLogTrail';
import { message } from 'antd';
import ContractSignedThankYou from './public-widgets/csv-onboarding/contract-signed-thank-you/ContractSignedThankYou';
import SuperSystemReports from './containers/super-admin/pages/system-reports/SuperSystemReports';
import { logOutUser } from './store/actions';
import { CustomFeature, CustomizedFeatures } from './components/CustomFeature';
import EmptyPages from './containers/layout/EmptyPage';
import { ManageLeaveBalancePage } from './containers/leave/new-leave/ManageLeaveBalancePage';
import LeaveLogTrail from './containers/leave/shared/LeaveLogTrail';

interface Props extends LayoutRouteProps {
  children: ReactNode;
  title?: string;
  requires: string;
  isLayout?: boolean;
}

const PrivateContent: FC<Props> = ({
  requires,
  children,
  title,
  isLayout = true,
  ...props
}) => {
  const me = useSelector((state: RootState) => state.people.me);
  const dispatch: DispatchType = useDispatch();

  const location = useLocation();

  const currentAuthToken = getCurrentAuthToken();

  if (currentAuthToken) {
    if (
      !me.organization?.timeAttendanceSettings?.isTimeAttendance &&
      location.pathname === '/time-attendance/'
    ) {
      message.error('Time Attendance is not enabled');
      return <Navigate to="/time-attendance/settings" />;
    }

    if (
      !me?.organization?.customizedFeatures?.isEnablePayroll &&
      location.pathname === '/payroll'
    ) {
      return <Navigate to={getPathWithOrgId('/home')} />;
    }

    if (me?.subscriptionStatus === SubscriptionEnum.SUSPENDED) {
      return (
        <>
          {(requires && location?.pathname == '/subscription') ||
          location?.pathname == '/payroll' ||
          location?.pathname == '/home' ? (
            <Layout {...props} title={title}>
              {children}
            </Layout>
          ) : me.roleId === UserRoleIdEnum.USER ? (
            <Navigate to={getPathWithOrgId('/home')} />
          ) : (
            <Navigate to={getPathWithOrgId('/payroll')} />
          )}
        </>
      );
    } else {
      return (
        <>
          {requires && isLayout ? (
            <Layout {...props} title={title}>
              {children}
            </Layout>
          ) : requires && !isLayout ? (
            <Fragment>{children}</Fragment>
          ) : (
            <Navigate to="/" />
          )}
        </>
      );
    }
  } else {
    dispatch(logOutUser());
    return (
      <Navigate
        to={`/login?redirectTo=${location?.pathname}${location?.search}`}
      />
    );
  }
};

const PrivateLayout: FC<Omit<Props, 'path'>> = ({
  title,
  requires,
  isLayout,
  children,
  ...rest
}) => {
  return (
    <PrivateContent title={title} requires={requires} isLayout={isLayout}>
      {children}
    </PrivateContent>
  );
};

// TODO: Fix me
// const authorizationStrategy = (roles: string[], requirement: string) => {
//   return roles.find((role) => role === requirement);
// };
const IndexPage = () => {
  const me = useSelector((state: RootState) => state.people.me);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAllowedAnyOf([Permission.MANAGE_PAYROLL])) {
      navigate(getPathWithOrgId('/payroll'));
    } else if (isAllowedAnyOf([Permission.SUPER_ADMIN_FEATURES])) {
      navigate(getPathWithOrgId('/super-admin'));
    } else {
      navigate(getPathWithOrgId('/home'));
    }
  }, [me]);

  return null;
};

const publicRoutes = (
  <Fragment>
    <Route path="/login" element={<Login />} />
    <Route path="/signup/:token" element={<Signup />} />
    <Route path="/open-salary-calculator" element={<SalaryCalculator />} />
    <Route path="/open-payroll" element={<UploadNewPayRunCSV />} />
    <Route path="/open-column-mapping" element={<MapColumns />} />
    <Route path="/open-salary-information" element={<SalaryInformation />} />
    <Route path="/open-bank-confirmation" element={<BankConfirmation />} />
    <Route path="/onboarding-form/:token" element={<EmailOnboarding />} />
    <Route
      path="/open-bank-confirmation-thank-you"
      element={<BankConfirmThankYou />}></Route>

    <Route
      path="/contract-signed-non-user"
      element={<ContractSignedThankYou />}></Route>

    <Route
      path="/open-personal-salary/edit"
      element={<IndividualSalary />}></Route>
    <Route path="/resetPassword/:token" element={<RestPassword />}></Route>
    <Route path="/resetPassword" element={<RestPassword />}></Route>
    <Route path="/forgetPassword" element={<ForgotPassword />}></Route>
    <Route
      path="/passwordResetLinkSent"
      element={<PasswordResetLinkSent />}></Route>
    {/* TODO: use this as reference */}
    <Route
      path="/public-contract-view/:token"
      element={<ViewNonUserContract />}></Route>
    <Route path="/404" element={<EmptyPage />}></Route>
  </Fragment>
);

const companySettingsRoutes = (
  <Fragment>
    <Route
      path="/company-settings/integrations/:integration/log-trail"
      element={
        <PrivateLayout
          title="Audit Trail"
          requires={Permission.MANAGE_INTEGRATION}>
          <IntegrationLogTrailPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/company-settings/integrations/:integration"
      element={
        <PrivateLayout
          title="Integrations"
          requires={Permission.MANAGE_INTEGRATION}>
          <IntegrationSettingPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/company-settings/integrations"
      element={
        <PrivateLayout
          title="Integrations"
          requires={Permission.MANAGE_INTEGRATION}>
          <IntegrationListPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/company-settings/data-export"
      element={
        <PrivateLayout title="Data Exports" requires={Permission.MANAGE_REPORT}>
          <DataExportPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/company-settings/accounting-firms"
      element={
        <PrivateLayout
          title="Accounting Firms"
          requires={Permission.MANAGE_ACCOUNTANTS}>
          <AccountingFirms />
        </PrivateLayout>
      }
    />
    <Route
      path="/company-settings"
      element={
        <PrivateLayout
          title="Company Settings"
          requires={Permission.MANAGE_ORG_SETTINGS}>
          <CompanySettings />
        </PrivateLayout>
      }
    />
    <Route
      path="/customization"
      element={
        <PrivateLayout
          title="Customization"
          requires={Permission.MANAGE_ORG_SETTINGS}>
          <Customization />
        </PrivateLayout>
      }
    />
    <Route
      path="/manageLeaveBalance"
      element={
        <PrivateLayout
          title="Leave"
          requires={Permission.MANAGE_ASSIGNED_USERS}>
          <CustomFeature organizationPermission={CustomizedFeatures.LEAVE}>
            <ManageLeaveBalancePage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/security"
      element={
        <PrivateLayout title="Security" requires={Permission.USER_ME}>
          <Security />
        </PrivateLayout>
      }
    />
    <Route
      path="/bank-information"
      element={
        <PrivateLayout
          title="Bank Information"
          requires={Permission.MANAGE_ORG_SETTINGS}>
          <BankInformation />
        </PrivateLayout>
      }
    />
    <Route
      path="/system-updates"
      element={
        <PrivateLayout
          title="System Updates"
          requires={Permission.MANAGE_ORG_SETTINGS}>
          <SystemUpdates />
        </PrivateLayout>
      }
    />
  </Fragment>
);

const homeRoute = (
  <Route
    path="/home"
    element={
      <PrivateLayout title="Home" requires={Permission.USER_ME}>
        <Home />
      </PrivateLayout>
    }
  />
);

const teamsConfigRoute = (
  <Fragment>
    <Route
      path="/team-configuration"
      element={
        <PrivateLayout requires={Permission.USER_ME} isLayout={false}>
          <TeamConfiguration />
        </PrivateLayout>
      }
    />
  </Fragment>
);
const peopleRoutes = (
  <Fragment>
    <Route
      path="/contact/bank-details/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <BankDetailsPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/pay-details/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.CREATE_EDIT_USERS}>
          <PayDetails />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/employment-details/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <EmploymentPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/personal-details/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <PersonalDetails />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/notes/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <NotePage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/log-view/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.VIEW_USER_INFO}>
          <EmployeeLogTrailPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/emergency-details/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <EmergencyDetails />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/add-new-employee/:roleId?"
      element={
        <PrivateLayout
          title="Add New Employee"
          requires={Permission.CREATE_EDIT_USERS}>
          <AddEmployeeManuallyPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/document/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.CREATE_EDIT_USERS}>
          <Document />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/loan-details/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.CREATE_EDIT_USERS}>
          <LoanPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/settings/:employeeKey"
      element={
        <PrivateLayout title="Contacts" requires={Permission.CREATE_EDIT_USERS}>
          <PeopleSettingsPage />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/add-employees-using-file"
      element={
        <PrivateLayout
          title="Bulk Employee Import"
          requires={Permission.CREATE_EDIT_USERS}>
          <AddEmployeesCSV />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact/log-trail"
      element={
        <PrivateLayout
          title="People Log Trail "
          requires={Permission.CREATE_EDIT_USERS}>
          <AllPeopleLogTrail />
        </PrivateLayout>
      }
    />
    <Route
      path="/contact"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <PeopleTable />
        </PrivateLayout>
      }
    />
  </Fragment>
);

const accountDetailRoutes = (
  <Fragment>
    <Route
      path="/account-details/personal-details"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <Profile />
        </PrivateLayout>
      }
    />
    <Route
      path="/account-details/employment-details"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <EmploymentDetail />
        </PrivateLayout>
      }
    />
    <Route
      path="/account-details/document"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <DocumentDetail />
        </PrivateLayout>
      }
    />
    <Route
      path="/account-details/pay-details"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <AccountPayDetails />
        </PrivateLayout>
      }
    />
    <Route
      path="/account-details/bank-detail"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <BankDetail />
        </PrivateLayout>
      }
    />
    <Route
      path="/account-details/loan-details"
      element={
        <PrivateLayout title="Contacts" requires={Permission.USER_ME}>
          <LoanDetails />
        </PrivateLayout>
      }
    />
  </Fragment>
);

const leaveRoutes = (
  <Fragment>
    <Route
      path="/leave-balance"
      element={
        <PrivateLayout title="Leaves" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.LEAVE}>
            <LeaveBalancePage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/leave/apply"
      element={
        <PrivateLayout title="Leaves" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.LEAVE}>
            <ApplyLeave />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/leave"
      element={
        <PrivateLayout title="Leaves" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.LEAVE}>
            <LeaveHomePage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/leave/log-trail"
      element={
        <PrivateLayout title="Leave Log Trail" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.LEAVE}>
            <LeaveLogTrail />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);

const timeAttendanceRoutes = (
  <Fragment>
    <Route
      path="/time-attendance"
      element={
        <PrivateLayout title="Time Attendance" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.TIMEATTENDANCE}>

          <TimeAttendance />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/time-attendance/view/:id"
      element={
        <PrivateLayout title="Time Attendance" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.TIMEATTENDANCE}>

          <View />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/time-attendance/view/:id/:employeeId"
      element={
        <PrivateLayout title="Time Attendance" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.TIMEATTENDANCE}>

          <ViewAttendance />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/time-attendance/settings"
      element={
        <PrivateLayout
          title="Time Attendance"
          requires={Permission.MANAGE_ORG_SETTINGS}>
          <CustomFeature organizationPermission={CustomizedFeatures.TIMEATTENDANCE}>

          <TimeAttendanceSettings />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/time-attendance/log-trail"
      element={
        <PrivateLayout
          title="Time Attendance Log Trail"
          requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.TIMEATTENDANCE}>
            
          <AttendanceLogTrail />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);

const payrollRoutes = (
  <Fragment>
    <Route
      path="/payroll"
      element={
        <PrivateLayout title="Payroll" requires={Permission.MANAGE_PAYROLL}>
          <CustomFeature organizationPermission={CustomizedFeatures.PAYROLL}>
            <PayrollHomePage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/payroll-loading/:payrollKey"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_PAYROLL}>
          <CustomFeature organizationPermission={CustomizedFeatures.PAYROLL}>
            <PayslipLoading />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/Payroll-view/:payrollKey"
      element={
        <PrivateLayout
          title="Salary Information"
          requires={Permission.MANAGE_PAYROLL}>
          <CustomFeature organizationPermission={CustomizedFeatures.PAYROLL}>
            <PayrollViewPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/Payroll-publish/:payrollKey"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_PAYROLL}>
          <CustomFeature organizationPermission={CustomizedFeatures.PAYROLL}>
            <PublishPayrun />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/create-payroll-using-file"
      element={
        <PrivateLayout
          title="Payrun Using CSV"
          requires={Permission.MANAGE_PAYROLL}>
          <CustomFeature organizationPermission={CustomizedFeatures.PAYROLL}>
            <PayrunUsingCSV />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);
const suspendRoutes = (
  <Route
    path="/subscription"
    element={
      <PrivateLayout title="Subscription Details" requires={Permission.USER_ME}>
        <Subscription />
      </PrivateLayout>
    }
  />
);
const reportRoutes = (
  <Fragment>
    <Route
      path="/reports/payroll/summary"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_REPORT}>
          <CustomFeature organizationPermission={CustomizedFeatures.REPORT}>
            <SummaryReportPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/reports/payroll/detailed"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_REPORT}>
          <CustomFeature organizationPermission={CustomizedFeatures.REPORT}>
            <DetailedReportPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/reports/payroll/files-and-forms"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_REPORT}>
          <CustomFeature organizationPermission={CustomizedFeatures.REPORT}>
            <FilesAndForms />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/reports/employee/head-count"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_REPORT}>
          <CustomFeature organizationPermission={CustomizedFeatures.REPORT}>
            <HeadCountReportPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/reports/employee/leave-balance"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_REPORT}>
          <CustomFeature organizationPermission={CustomizedFeatures.REPORT}>
            <LeaveBalanceReportPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/reports"
      element={
        <PrivateLayout title="Reports" requires={Permission.MANAGE_REPORT}>
          <CustomFeature organizationPermission={CustomizedFeatures.REPORT}>
            <Navigate to="/reports/payroll/summary" />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);
const superAdminRoutes = (
  <Fragment>
    <Route
      path="/super-admin/organization/:accountId"
      element={
        <PrivateLayout
          title="Organization"
          requires={Permission.SUPER_ADMIN_FEATURES}>
          <AccountHomePage />
        </PrivateLayout>
      }
    />
    <Route
      path="/super-admin/create-new-organization"
      element={
        <PrivateLayout
          title="New Organization"
          requires={Permission.SUPER_ADMIN_FEATURES}>
          <CreateNewOrganization />
        </PrivateLayout>
      }
    />
    <Route
      path="/super-admin"
      element={
        <PrivateLayout
          title="Dashboard"
          requires={Permission.SUPER_ADMIN_FEATURES}>
          <SuperHomePage />
        </PrivateLayout>
      }
    />
    <Route
      path="/all-system-updates"
      element={
        <PrivateLayout
          title="System Updates"
          requires={Permission.SUPER_ADMIN_FEATURES}>
          <SuperSystemUpdates />
        </PrivateLayout>
      }
    />
    <Route
      path="/system-reports"
      element={
        <PrivateLayout
          title="Active Organization Reports"
          requires={Permission.SUPER_ADMIN_FEATURES}>
          <SuperSystemReports />
        </PrivateLayout>
      }
    />
  </Fragment>
);

const contractRoutes = (
  <Fragment>
    <Route
      path="/contract/edit/:contractId"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="New Contract">
          <CustomFeature organizationPermission={CustomizedFeatures.CONTRACT}>
            <ContractEditHome />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/contract/view/:contractId"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Sign Contract">
          <CustomFeature organizationPermission={CustomizedFeatures.CONTRACT}>
            <ContractViewHome />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/contract"
      element={
        <PrivateLayout title="Contract" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.CONTRACT}>
            <ContractPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/contract/templates/view/:templatesId"
      element={
        <PrivateLayout title="Templates" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.CONTRACT}>
            <ViewTemplates />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);

const expenseRoutes = (
  <Fragment>
    <Route
      path="/expenses"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Expenses">
          <CustomFeature organizationPermission={CustomizedFeatures.EXPENSES}>
            <ExpensesListPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/expense/create"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Add New Expense">
          <CustomFeature organizationPermission={CustomizedFeatures.EXPENSES}>
            <ExpenseManagePage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/expense/update/:expenseId"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Update Expense">
          <CustomFeature organizationPermission={CustomizedFeatures.EXPENSES}>
            <ExpenseManagePage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/expense/generated-bank-files"
      element={
        <PrivateLayout
          requires={Permission.MANAGE_EXPENSE_STATUS_ADVANCED}
          title="Expense">
          <CustomFeature organizationPermission={CustomizedFeatures.EXPENSES}>
            <GeneratedBankFiles />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/expense/categories"
      element={
        <PrivateLayout
          requires={Permission.MANAGE_EXPENSE_SETTINGS}
          title="Categories">
          <CustomFeature organizationPermission={CustomizedFeatures.EXPENSES}>
            <ExpenseCategoriesPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/expense/merchants"
      element={
        <PrivateLayout
          requires={Permission.MANAGE_EXPENSE_SETTINGS}
          title="Merchants">
          <CustomFeature organizationPermission={CustomizedFeatures.EXPENSES}>
            <ExpensesMerchantsPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);

const loanRoutes = (
  <Fragment>
    <Route
      path="/loan/log-trail"
      element={
        <PrivateLayout title="Loan Log Trail" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <LoanLogTrail />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/loan/loan-logs/:id"
      element={
        <PrivateLayout title="Loan Log Trail" requires={Permission.USER_ME}>
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <IndividualLoanLogTrail />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/loan/generated-bank-files"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Loan Management">
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <LoanGeneratedBankFilesTable />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/loans"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Loan Management">
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <LoanHome />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/loan/create"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Add New Loan">
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <AddLoanPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/loan/:id"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Loan Details">
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <ViewLoanPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/user-loan/:id"
      element={
        <PrivateLayout requires={Permission.USER_ME} title="Loan Details">
          <CustomFeature organizationPermission={CustomizedFeatures.LOAN}>
            <ViewUserLoanPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);

const gratuityRoutes = (
  <Fragment>
    <Route
      path="/gratuity/audit-logs"
      element={
        <PrivateLayout
          title="Gratuity Log Trail"
          requires={Permission.MANAGE_GRATUITY}>
          <CustomFeature organizationPermission={CustomizedFeatures.GRATUITY}>
            <GratuityLogTrail />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/gratuity"
      element={
        <PrivateLayout requires={Permission.MANAGE_GRATUITY} title="Gratuity">
          <CustomFeature organizationPermission={CustomizedFeatures.GRATUITY}>
            <GratuityHome />
          </CustomFeature>
        </PrivateLayout>
      }
    />
    <Route
      path="/gratuity-view/:id"
      element={
        <PrivateLayout requires={Permission.MANAGE_GRATUITY} title="Gratuity">
          <CustomFeature organizationPermission={CustomizedFeatures.GRATUITY}>
            <ViewGratuityPage />
          </CustomFeature>
        </PrivateLayout>
      }
    />
  </Fragment>
);

export default function RootRoutes() {
  return (
    <>
      <Routes>
        {publicRoutes}
        {homeRoute}
        {teamsConfigRoute}
        {companySettingsRoutes}
        {peopleRoutes}
        {accountDetailRoutes}
        {leaveRoutes}
        {timeAttendanceRoutes}
        {payrollRoutes}
        {reportRoutes}
        {superAdminRoutes}
        {contractRoutes}
        {expenseRoutes}
        {loanRoutes}
        {gratuityRoutes}
        {suspendRoutes}
        <Route
          path="/personal-salary-information/:payrollKey/employee/:employeeKey/:salaryInformationViewType"
          element={
            <PrivateLayout
              title="Salary Information"
              requires={Permission.USER_ME}>
              <CustomFeature
                organizationPermission={CustomizedFeatures.PAYROLL}>
                <PaySlipPage />
              </CustomFeature>
            </PrivateLayout>
          }
        />
        <Route
          path="/"
          element={
            <PrivateLayout title="Welcome!" requires={Permission.USER_ME}>
              <IndexPage />
            </PrivateLayout>
          }
        />
        <Route path="*" element={<EmptyPages />} />
      </Routes>
    </>
  );
}
