import React, { FC, useEffect, useRef, useState } from 'react';
import { Image, Layout as AntLayout, Menu } from 'antd';
import _, { toInteger } from 'lodash';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { CustomIcon } from '../../../components';
import theme from '../../../theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { useGetMenuItems } from '../../../util/useGetMenuItems';
import { MenuItemType } from '../../../types';
import { SubscriptionEnum } from '../../../types/subscription.types';
import styled from '@emotion/styled';

const { Sider } = AntLayout;
const { SubMenu } = Menu;
interface Props {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  topMenuRef: React.RefObject<HTMLDivElement>;
}

const SiderComp = styled(Sider)`
  overflow: auto !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0 !important;
  margin-top: 46px;
  z-index: 1000;
  background-color: ${theme.blue900} !important;
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: ${theme.blue900};
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    margin-bottom: 15px;
  }
`;
const MenuIcon = styled(Image)`
  margin: 0px 0px 3.5px 8px;
`;
const Span = styled.span`
  padding-left: 20px;
`;
const MenuLink = styled(Link)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #ffffff;
`;
const MenuItem = styled(Menu.Item)`
  border-radius: 8px;
  font-size: 16px;
  margin: 0px;
  padding: 0px;

  .ant-menu-inline > .ant-menu-item {
    height: 45;
  }
  .ant-image-img {
    margin-bottom: 5px;
  }
`;

const SubMenuItem = styled(SubMenu)`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;

  ul {
    background: transparent !important;
  }
  .ant-menu-submenu-title {
    padding-left: 33px !important;
    margin-bottom: 15px;
  }
  .anticon {
    margin-right: 10px;
  }

  .ant-menu-submenu-arrow {
    padding-right: 30px !important;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: #000;
  }
`;
const SubMenus = styled(Menu.Item)<{ collapsed: boolean }>`
  background-color: ${(props) => props.collapsed && 'transparent'} !important;
  font-size: 14px;
  padding-left: 65px !important;
`;

const MobileSideMenu: FC<Props> = ({ collapsed, setCollapsed, topMenuRef }) => {
  const sideMenuRef = useRef<HTMLDivElement>(null);
  const menuItems = useGetMenuItems();
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [isSuspend, setISSuspend] = useState<boolean>(false);
  const me = useSelector((state: RootState) => state.people.me);

  const getCurrentActiveMenu = () => {
    const newMenu: MenuItemType[] = [];
    _.map(menuItems, (menu) => {
      if (_.isEmpty(menu.subMenus)) {
        newMenu.push(menu);
        setCollapsed(collapsed);
      } else {
        _.map(menu.subMenus, (subMenu) => {
          newMenu.push(subMenu);
        });
      }
    });
    const currentPath = _.findIndex(
      newMenu,
      (item) => item.path === location.pathname,
    );

    return currentPath;
  };
  const [activeMenu, setActiveMenu] = useState(getCurrentActiveMenu());

  useEffect(() => {
    if (me) {
      setISSuspend(me.subscriptionStatus === SubscriptionEnum.SUSPENDED);
    }
  }, [me]);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const handleSideMenuCollapse = (event: MouseEvent) => {
    if (
      sideMenuRef.current &&
      !sideMenuRef.current.contains(event.target as Node) &&
      !topMenuRef.current.contains(event.target as Node)
    ) {
      setCollapsed(true);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleSideMenuCollapse);
    return () => {
      document.removeEventListener('mousedown', handleSideMenuCollapse);
    };
  }, []);

  return (
    <>
      <SiderComp
        ref={sideMenuRef}
        width={250}
        collapsedWidth={0}
        trigger={null}
        collapsible
        collapsed={collapsed}>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[activeMenu.toString()]}
          style={{ marginTop: 46 }}
          defaultOpenKeys={activeMenu > 3 ? ['sub1'] : undefined}
          onClick={(event) => {
            setActiveMenu(toInteger(event.key));
            setSubMenuOpen(!(activeMenu < 3));
            setCollapsed(!collapsed);
          }}>
          {_.map(menuItems, (menuItem, i) =>
            _.isEmpty(menuItem.subMenus) ? (
              menuItem.displayName === 'Contracts' ? (
                <MenuItem key={i} disabled={isSuspend}>
                  <MenuIcon
                    width={18}
                    height={18}
                    preview={false}
                    src={
                      location.pathname == menuItem.path
                        ? require(`../../../assets/Icons/menu-icons/${menuItem.icon}`)
                        : require(`../../../assets/Icons/menu-icons/${menuItem.iconUnselected}`)
                    }
                  />
                  <MenuLink to={menuItem.path}>
                    <Span>{menuItem.displayName}</Span>
                  </MenuLink>
                </MenuItem>
              ) : menuItem.displayName === 'Loan' ? (
                <MenuItem key={i} disabled={isSuspend}>
                  <MenuIcon
                    width={18}
                    height={18}
                    preview={false}
                    src={
                      location.pathname == menuItem.path
                        ? require(`../../../assets/Icons/menu-icons/${menuItem.icon}`)
                        : require(`../../../assets/Icons/menu-icons/${menuItem.iconUnselected}`)
                    }
                  />
                  <MenuLink to={menuItem.path}>
                    <Span>{menuItem.displayName}</Span>
                  </MenuLink>
                </MenuItem>
              ) : (
                <MenuItem key={i} disabled={isSuspend}>
                  <MenuIcon
                    width={18}
                    height={18}
                    preview={false}
                    src={
                      location.pathname == menuItem.path
                        ? require(`../../../assets/Icons/menu-icons/${menuItem.icon}`)
                        : require(`../../../assets/Icons/menu-icons/${menuItem.iconUnselected}`)
                    }
                  />
                  <MenuLink to={menuItem.path}>
                    <Span>{menuItem.displayName}</Span>
                  </MenuLink>
                </MenuItem>
              )
            ) : (
              <>
                <SubMenuItem
                  disabled={isSuspend}
                  title={menuItem.displayName}
                  onTitleClick={({}) => {
                    setSubMenuOpen(true);
                  }}
                  icon={
                    <CustomIcon
                      icon={
                        subMenuOpen && location.pathname == menuItem.path
                          ? `menu-icons/${menuItem.icon}`
                          : `menu-icons/${menuItem.iconUnselected}`
                      }
                    />
                  }
                  key={i}>
                  {_.map(menuItem.subMenus, (subMenu, j) => {
                    if (
                      subMenu.type === 'DATA_IMPORT' &&
                      !organizationData?.dataExtractorTypes
                    ) {
                      return null;
                    }
                    return (
                      <SubMenus key={i + j} collapsed={collapsed}>
                        <Link to={subMenu.path}>{subMenu.displayName}</Link>
                      </SubMenus>
                    );
                  })}
                </SubMenuItem>
              </>
            ),
          )}
        </Menu>
      </SiderComp>
    </>
  );
};

export default MobileSideMenu;
