import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Row, Select, Spin } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import styled from '@emotion/styled';

import {
  B,
  DatePickerComp,
  InputComp,
  LinkButton,
  SelectComp,
} from '../../../components';
import { ModalComponent } from '../../../components/ModalComponent';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import { EMPLOYEE_TYPES } from '../../../configs';
import { DATE_FORMATS } from '../../../configs/dateConfig';
import { getMinimumUserInfo, getMyOrganization } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import { UserHistoryI } from '../../../types/people.types';
import { InfoSVG, RefreshSVG } from '../../../assets';
import dayjs from 'dayjs';

const { Option } = Select;

const SelectComponent = styled(Select)`
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .ant-select-selector {
    border: 1px solid ${theme.gray400} !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .ant-select-selector:hover {
    border: 1px solid ${theme.blue500} !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px !important;
  }
  .ant-select-selector:focus {
    border: 1px solid ${theme.blue500} !important;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1) !important;
  }
  .ant-select-arrow {
    color: black;
  }
`;

const ExperienceTitleSection = styled.div`
  padding: 0px 20px;
`;
const BranchSection = styled.div`
  padding: 0px 20px 16px;
  border-bottom: 1px solid ${theme.gray300};
`;
const PositionLevelSection = styled.div`
  padding: 16px 20px 16px;
  border-bottom: 1px solid ${theme.gray300};
`;
const JobDurationSection = styled.div`
  padding: 16px 20px 0px;
  border-bottom: 1px solid ${theme.gray300};
`;
const ManagerSection = styled.div`
  padding: 16px 20px 0px;
  .ant-divider-horizontal {
    @media (max-width: 450px) {
      margin: 0px;
    }
  }
`;

const RefreshColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
`;

const RefreshIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-top: 3px;
`;

const RefetchContentColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4px !important;
  @media (max-width: 450px) {
    margin-top: 0px;
    padding: 0px 12px !important;
  }
`;

const ExperienceModalForm = styled(Form)`
  .ant-form-item-label > label {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.blue700};
    padding-bottom: 0px;
  }

  .ant-col-xs-24.ant-col-sm-24 {
    @media (max-width: 450px) {
      height: 90px;
    }
  }
`;

interface Props {
  visible: boolean;
  onCancel: () => void;
  experience?: UserHistoryI | null;
  onSubmit: (experience: UserHistoryI) => void;
  onRemove: () => void;
  peopleId: number | null;
  joinedDate?: string | null;
}

export const ExperienceModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  experience,
  onRemove,
  peopleId,
  joinedDate,
}) => {
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [isCurrentJob, setIsCurrentJob] = useState(false);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const organizationLoading = useSelector(
    (state: RootState) => state.organization.loading,
  );

  const managers = useSelector(
    (state: RootState) => state.people.allPeoples,
  ).filter((person) => person.id !== peopleId); // Removing current user from the list of managers's list

  const positionLevel: string[] = organizationData?.designations || [];
  const locations = organizationData?.location || [];

  useEffect(() => {
    dispatch(getMinimumUserInfo());
    if (experience && experience.isCurrentJob) {
      setIsCurrentJob(true);
    }
  }, []);

  const refetch = () => {
    dispatch(getMyOrganization());
  };

  const onFinishForm = (values: UserHistoryI) => {
    const id = experience?.id;
    const startDate = values.startDate
      ? dayjs(values.startDate).format(DATE_FORMATS.iso_date_format)
      : null;
    const endDate = values.endDate
      ? dayjs(values.endDate).format(DATE_FORMATS.iso_date_format)
      : null;

    if (values.managerId?.toString() === '') {
      values.managerId = null;
    }

    onSubmit({
      ...values,
      startDate,
      endDate,
      id: id,
    });
  };

  const initialValues = experience
    ? {
        ...experience,
        startDate: dayjs(experience.startDate),
        endDate: experience.endDate ? dayjs(experience.endDate) : dayjs(),
      }
    : {
        branchName: locations && locations.length ? locations[0] : undefined,
        employmentType: 'Full Time',
      };

  const joinedDateMoment = dayjs(joinedDate);

  const disabledStartDate = (startValue: dayjs.Dayjs) => {
    if (!startValue) {
      return false;
    }
    return !joinedDateMoment || startValue <= joinedDateMoment;
  };

  const disabledEndDate = (endValue: dayjs.Dayjs) => {
    if (!endValue || !form.getFieldValue('startDate')) {
      return false;
    }
    const startDate = dayjs(form.getFieldValue('startDate'));
    return endValue <= startDate;
  };
  return (
    <>
      <ModalComponent
        hideRemoveButton={experience ? false : true}
        removeText="Remove Experience"
        deleteModalTitle="Delete Experience"
        deleteModalContent="Are you sure you want to delete experience?"
        deleteModalButtonText="Save and Continue"
        p="24px 0px 0px"
        onRemove={() => onRemove()}
        form={form}
        submitText={experience ? 'Update Experience' : 'Add Experience'}
        visible={visible}
        width="906px"
        title={
          <div
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <B type="b-large-semibold" color={theme.black}>
              {experience ? 'Update Experience' : 'Experience'}
            </B>
          </div>
        }
        onCancel={() => onCancel()}>
        <Spin spinning={organizationLoading}>
          <ExperienceModalForm
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={(values) => {
              onFinishForm(values as UserHistoryI);
            }}
            initialValues={initialValues}>
            <ExperienceTitleSection>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="designation"
                    label="Designation"
                    tooltip={{
                      placement: 'rightTop',
                      title:
                        "This refers to the employee's 'Job Title' or the position held within the organization.",
                      icon: <img src={InfoSVG} />,
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter designation/Job title!',
                      },
                    ]}>
                    <InputComp
                      size="small"
                      placeholder="Designation/Job Title"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="employmentType" label="Employment Type">
                    <SelectComp
                      size="middle"
                      defaultValue="Full Time"
                      options={EMPLOYEE_TYPES}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ExperienceTitleSection>
            <BranchSection>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                  <Row>
                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                      <Form.Item name="branchName" label="Branch">
                        <SelectComp
                          size="middle"
                          showSearch
                          placeholder="Branch Name"
                          defaultValue={locations[0]}
                          options={locations.map((designation) => {
                            return {
                              label: designation,
                              value: designation,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <RefreshColumn xs={2} sm={2} md={2} lg={2} xl={2}>
                      <LinkButton
                        ml="16px"
                        onClick={() => refetch?.()}
                        icon={<RefreshIcon src={RefreshSVG} />}></LinkButton>
                    </RefreshColumn>
                  </Row>
                </Col>
                <RefetchContentColumn xs={24} sm={24} md={11} lg={11} xl={11}>
                  <Row>
                    <div>
                      <B type="b-small" color={theme.gray600} pt="30px">
                        Select the specific company branch or location tied to
                        this employment experience record.
                      </B>
                    </div>
                  </Row>
                  <Row>
                    <LinkButton
                      size="small"
                      mt="2px"
                      ml="-39px"
                      rel="noopener noreferrer"
                      onClick={() =>
                        window.open('/company-settings', '_blank')
                      }>
                      Manage Branch
                    </LinkButton>
                  </Row>
                </RefetchContentColumn>
              </Row>
            </BranchSection>
            <PositionLevelSection>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                  <Row>
                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                      <Form.Item
                        name="positionLevel"
                        label="Position Level"
                        rules={[
                          {
                            required: false,
                            message: 'Please select your position level!',
                          },
                        ]}>
                        <SelectComp
                          size="middle"
                          placeholder="Select Position Level"
                          showSearch
                          options={positionLevel.map((positionLevel) => {
                            return {
                              label: positionLevel,
                              value: positionLevel,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <RefreshColumn xs={2} sm={2} md={2} lg={2} xl={2}>
                      <LinkButton
                        ml="16px"
                        size="small"
                        onClick={() => refetch?.()}
                        icon={<RefreshIcon src={RefreshSVG} />}></LinkButton>
                    </RefreshColumn>
                  </Row>
                </Col>
                <RefetchContentColumn xs={24} sm={24} md={11} lg={11} xl={11}>
                  <Row>
                    <div>
                      <B type="b-small" color={theme.gray600} pt="30px">
                        Set the responsibility level and expectations of roles
                        at your organization, They may be further defined by
                        impact.
                      </B>
                    </div>
                  </Row>
                  <Row>
                    <LinkButton
                      ml="-16px"
                      mt="5px"
                      p={0}
                      rel="noopener noreferrer"
                      onClick={() => {
                        window.open('/customization', '_blank');
                      }}>
                      Manage Position Level
                    </LinkButton>
                  </Row>
                </RefetchContentColumn>
              </Row>
            </PositionLevelSection>
            <JobDurationSection>
              <Row>
                <Form.Item name="isCurrentJob" valuePropName="checked">
                  <CheckBoxComp
                    onChange={() => {
                      setIsCurrentJob(form.getFieldValue('isCurrentJob'));
                    }}>
                    I am currently working in this role
                  </CheckBoxComp>
                </Form.Item>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    rules={[
                      {
                        required: true,
                        message: 'required',
                      },
                    ]}>
                    <DatePickerComp
                      size="middle"
                      disabledDate={(current) => disabledStartDate(current)}
                      onChange={(value, dateString: string) => {
                        form.setFieldsValue({ startDate: dayjs(dateString) });
                      }}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
                {isCurrentJob ? null : (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="endDate"
                      label="End Date"
                      rules={[
                        {
                          required: !isCurrentJob,
                          message: 'required',
                        },
                      ]}>
                      <DatePickerComp
                        size="middle"
                        disabled={isCurrentJob}
                        disabledDate={(current) => disabledEndDate(current)}
                        format="YYYY-MM-DD"
                        onChange={(value, dateString: string) => {
                          form.setFieldsValue({ endDate: dayjs(dateString) });
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </JobDurationSection>
            <ManagerSection>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="managerId"
                    label="Manager"
                    rules={[
                      {
                        required: false,
                        message: 'required',
                      },
                    ]}>
                    <SelectComponent
                      id="pm"
                      size="middle"
                      placeholder=" Select a manager"
                      optionFilterProp="children"
                      showSearch>
                      <>
                        <Option key=" " value="">
                          Unselect
                        </Option>
                        {managers.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.fullName}
                          </Option>
                        ))}
                      </>
                    </SelectComponent>
                  </Form.Item>
                </Col>
              </Row>
            </ManagerSection>
          </ExperienceModalForm>
        </Spin>
      </ModalComponent>
    </>
  );
};
