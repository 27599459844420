import { useDispatch, useSelector } from 'react-redux';
import { MenuItemType, Permission } from '../types';
import { DispatchType, RootState } from '../store/reducer';
import { CustomizedFeaturesI } from '../types/superAdmin.types';

export const SUPER_ADMIN_SIDE_MENU: MenuItemType[] = [
  {
    displayName: 'Super Admin',
    path: '/super-admin',
    icon: 'home.svg',
    iconUnselected: 'home-unselected.svg',
  },
  {
    displayName: 'Reports',
    path: '/system-reports',
    icon: 'report.svg',
    iconUnselected: 'report-unselected.svg',
  },
  {
    displayName: 'System Updates',
    path: '/all-system-updates',
    icon: 'system-update.svg',
    iconUnselected: 'system-update-unselected.svg',
  },
];

export const SideMenuItems = (
  customizedFeatures: CustomizedFeaturesI,
): MenuItemType[] => {
  return [
    {
      displayName: 'Home',
      path: '/home',
      icon: 'home.svg',
      iconUnselected: 'home-unselected.svg',
      isUserVisible: true,
    },
    {
      displayName: 'Contacts',
      path: '/contact',
      icon: 'people.svg',
      iconUnselected: 'people-unselected.svg',
      hasException: true,
      permissions: [Permission.CREATE_EDIT_USERS],
      isUserVisible: true,
    },
    {
      displayName: 'Payroll',
      path: '/payroll',
      icon: 'payroll.svg',
      iconUnselected: 'payroll-unselected.svg',
      permissions: [Permission.MANAGE_PAYROLL],
      isUserVisible: customizedFeatures?.isEnablePayroll,
    },
    {
      displayName: 'Contracts',
      path: '/contract',
      icon: 'contract.svg',
      iconUnselected: 'contract-unselected.svg',
      permissions: [Permission.USER_ME],
      isUserVisible: customizedFeatures?.isEnableContract,
    },
    {
      displayName: 'Leave',
      path: '/leave',
      icon: 'leave.svg',
      iconUnselected: 'leave-unselected.svg',
      isUserVisible: customizedFeatures?.isEnableLeave,
      subMenus: [
        {
          displayName: 'Leave Requests',
          path: '/leave',
          isUserVisible: customizedFeatures?.isEnableLeave,
        },
        {
          displayName: 'Balance',
          path: '/leave-balance',
          isUserVisible: customizedFeatures?.isEnableLeave,
        },
      ],
    },
    {
      displayName: 'Time Attendance',
      path: '/time-attendance',
      icon: 'time-attendance.svg',
      iconUnselected: 'time-attendance-unselected.svg',
      isUserVisible: customizedFeatures?.isEnableTimeAttendance,
    },
    {
      displayName: 'Expenses',
      path: '/expenses',
      icon: 'expense.svg',
      iconUnselected: 'expense-unselected.svg',
      isUserVisible: customizedFeatures?.isEnableExpenses,
    },
    {
      displayName: 'Loan',
      path: '/loans',
      icon: 'loan.svg',
      iconUnselected: 'loan-unselected.svg',
      permissions: [Permission.MANAGE_ORG_SETTINGS],
      isUserVisible: customizedFeatures?.isEnableLoan,
    },
    {
      displayName: 'Gratuity',
      path: '/gratuity',
      icon: 'gratuity.svg',
      iconUnselected: 'gratuity-unselected.svg',
      permissions: [Permission.MANAGE_GRATUITY],
      isUserVisible: customizedFeatures?.isEnableGratuity,
    },
    {
      displayName: 'Reports',
      path: '/reports/payroll/summary',
      icon: 'report.svg',
      iconUnselected: 'report-unselected.svg',
      permissions: [Permission.MANAGE_REPORT],
      isUserVisible: customizedFeatures?.isEnableReport,
    },
    {
      displayName: 'Settings',
      path: '/settings',
      icon: 'settings.svg',
      iconUnselected: 'settings-unselected.svg',
      isUserVisible: true,
      subMenus: [
        {
          displayName: 'Company settings',
          path: '/company-settings',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: true,
        },
        {
          displayName: 'Time Attendance',
          path: '/time-attendance/settings',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: customizedFeatures?.isEnableTimeAttendance,
        },
        {
          displayName: 'Leave',
          path: '/manageLeaveBalance',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: customizedFeatures?.isEnableLeave,
        },
        {
          displayName: 'Customization',
          path: '/customization',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: true,
        },
        {
          displayName: 'Integrations',
          path: '/company-settings/integrations',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: true,
        },
        {
          displayName: 'Data Exports',
          path: '/company-settings/data-export',
          permissions: [Permission.MANAGE_REPORT],
          isUserVisible: true,
        },
        // {
        //   displayName: 'Accounting Firms',
        //   path: '/company-settings/accounting-firms',
        //   permissions: [Permission.MANAGE_ACCOUNTANTS],
        // },
        {
          displayName: 'Bank Information',
          path: '/bank-information',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: true,
        },
        {
          displayName: 'System Updates',
          path: '/system-updates',
          permissions: [Permission.MANAGE_ORG_SETTINGS],
          isUserVisible: true,
        },
      ],
    },
  ];
};

export enum ReportStatusEnum {
  summary = 'summary',
  detailedReport = 'detailedReport',
  filesAndForms = 'filesAndForms',
  headCount = 'headCount',
  leave = 'leave',
}

export enum PeopleStatusEnum {
  personnelDetails = 'personnelDetails',
  emergencyDetails = 'emergencyDetails',
  employmentDetails = 'employmentDetails',
  notificationDetails = 'notificationDetails',
  userDocument = 'userDocument',
  bankDetails = 'bankDetails',
  userPayDetails = 'userPayDetails',
  userHistory = 'userHistory',
  loanDetails = 'loanDetails',
}

export interface PeopleMenuItemType {
  displayName: string;
  path: string;
  statusKey: PeopleStatusEnum;
}

export const PEOPLE_MENU_ITEMS: PeopleMenuItemType[] = [
  {
    displayName: 'Personal Details',
    path: '/contact/personal-details',
    statusKey: PeopleStatusEnum.personnelDetails,
  },
  {
    displayName: 'Employment Details',
    path: '/contact/employment-details',
    statusKey: PeopleStatusEnum.employmentDetails,
  },
  {
    displayName: 'Pay Details',
    path: '/contact/pay-details',
    statusKey: PeopleStatusEnum.userPayDetails,
  },
  {
    displayName: 'Bank Details',
    path: '/contact/bank-details',
    statusKey: PeopleStatusEnum.bankDetails,
  },
  {
    displayName: 'Loan Details',
    path: '/contact/loan-details',
    statusKey: PeopleStatusEnum.loanDetails,
  },
  {
    displayName: 'Document',
    path: '/contact/document',
    statusKey: PeopleStatusEnum.userDocument,
  },
  {
    displayName: 'Notes',
    path: '/contact/notes',
    statusKey: PeopleStatusEnum.notificationDetails,
  },
];

export interface ProfileMenuItemType {
  displayName: string;
  path: string;
  subMenus?: { displayName: string; path: string }[];
}
export const PROFILE_MENU_ITEMS: ProfileMenuItemType[] = [
  {
    displayName: 'Personal Details',
    path: '/account-details/personal-details',
  },
  {
    displayName: 'Employment Details',
    path: '/account-details/employment-details',
  },
  {
    displayName: 'Pay Details',
    path: '/account-details/pay-details',
  },
  {
    displayName: 'Bank Details',
    path: '/account-details/bank-detail',
  },
  {
    displayName: 'Loan Details',
    path: '/account-details/loan-details',
  },
  {
    displayName: 'Document',
    path: '/account-details/document',
  },
];

export interface ReportMenuItemType {
  displayName: string;
  path: string;
  statusKey: ReportStatusEnum;
}

export const REPORT_MENU_ITEMS: ReportMenuItemType[] = [
  {
    displayName: 'Payroll Summary',
    path: '/reports/payroll/summary',
    statusKey: ReportStatusEnum.summary,
  },
  {
    displayName: 'Payroll Detailed Report',
    path: '/reports/payroll/detailed',
    statusKey: ReportStatusEnum.detailedReport,
  },
  {
    displayName: 'Files and Forms',
    path: '/reports/payroll/files-and-forms',
    statusKey: ReportStatusEnum.filesAndForms,
  },
  {
    displayName: 'Head Count',
    path: '/reports/employee/head-count',
    statusKey: ReportStatusEnum.headCount,
  },
  {
    displayName: 'Leave',
    path: '/reports/employee/leave-balance',
    statusKey: ReportStatusEnum.leave,
  },
];

export const HR_ADMIN_MENU = [
  PeopleStatusEnum.personnelDetails,
  PeopleStatusEnum.emergencyDetails,
  PeopleStatusEnum.employmentDetails,

  PeopleStatusEnum.bankDetails,
  PeopleStatusEnum.userDocument,
];

export const GUEST_ADMIN_MENU = [
  PeopleStatusEnum.personnelDetails,
  PeopleStatusEnum.userDocument,
  PeopleStatusEnum.notificationDetails,
];
export const LOAN_DISABLE_ORG_MENU = [
  PeopleStatusEnum.personnelDetails,
  PeopleStatusEnum.emergencyDetails,
  PeopleStatusEnum.employmentDetails,
  PeopleStatusEnum.userPayDetails,
  PeopleStatusEnum.bankDetails,
  PeopleStatusEnum.userDocument,
  PeopleStatusEnum.notificationDetails,
];
