import React, { FC } from 'react';

import { B, Div } from '../../../../components';
import { OrganizationLeaveI } from '../../../../types';
import { isValidMoment } from '../../../../util/isValidMoment';

interface Props {
  data: string;
}
const LeavePeriodDisplay: FC<Props> = ({ data }) => {
  let dates: string[];
  let dateHours: string[];
  if (data.includes(' - ')) {
    dates = data.split(' - ');
  } else if (data.includes(' / ')) {
    dateHours = data.split(' / ');
  }

  return dates ? (
    <Div type="b-small">
      {isValidMoment(dates[0], '-', 'YYYY/MM/DD').toString()} {' - '}
      {isValidMoment(dates[1], '-', 'YYYY/MM/DD').toString()}
    </Div>
  ) : dateHours ? (
    <Div type="b-small">
      {isValidMoment(dateHours[0], '-', 'YYYY/MM/DD').toString()} {' - '}
      {dateHours[1]} hours
    </Div>
  ) : (
    <Div type="b-small">
      {isValidMoment(data, '-', 'YYYY/MM/DD').toString()}
    </Div>
  );
};
export default LeavePeriodDisplay;
