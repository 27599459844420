import React, { FC, useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import {
  DatePickerComp,
  Image,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import {
  NotificationItemI,
  NotificationTypeEnum,
} from '../../../../types/notification.types';
import moment from 'moment';
import theme from '../../../../theme';
import dayjs from 'dayjs';
import { PayrollCalenderSVG } from '../../../../assets';
import styled from '@emotion/styled';

const { TextArea } = Input;
interface Props {
  onCancel: () => void;
  editItem?: NotificationItemI;
  onSubmit: (notification: NotificationItemI) => void;
}
const MainCard = styled.div`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 200px;
  @media (max-width: 450px) {
    height: auto;
  }
`;
const Note = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 130px;
`;
const NoteTextArea = styled(TextArea)`
  border: none;
  padding: 16px 24px !important;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {
    border: none;
    box-sizing: none;
    filter: none;
    border-radius: none;
  }
`;
export const RemindNote = styled.div`
  display: flex;
  margin-top: 12px;
  padding: 0px 12px;
  @media (max-width: 450px) {
    display: inherit;
    margin-bottom: 10px;
  }
`;
export const DatePicker = styled(DatePickerComp)`
  border: none;
  padding-left: 0px;
  .ant-picker-input {
    display: flex;
    flex-direction: row-reverse;
  }
  input::placeholder {
    color: #000000 !important;
  }
  @media (max-width: 450px) {
    width: 100%;
    border: 1px solid #9e9e9e;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
export const After3Months = styled(Button)<{ selectColor: string | null }>`
  margin-left: auto;
  margin-right: 12px;
  color: ${(Props) => Props.selectColor};
  background: #ffffff;
  border: ${(Props) =>
    Props.selectColor ? `1px solid #0052EA` : `1px solid #9E9E9E`};
  box-sizing: border-box;
  border-radius: 4px;
  &:focus {
    border-color: ${theme.blue600};
    color: ${theme.blue600};
  }
  &:hover {
    border-color: ${theme.blue600};
    color: ${theme.blue600};
  }
  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const After6Months = styled(Button)<{ color: string | null }>`
  margin-right: 12px;
  color: ${(Props) => Props.color};
  background: #ffffff;
  border: ${(Props) =>
    Props.color ? `1px solid #0052EA` : `1px solid #9E9E9E`};
  box-sizing: border-box;
  border-radius: 4px;
  &:focus {
    border-color: ${theme.blue600};
    color: ${theme.blue600};
  }
  &:hover {
    border-color: ${theme.blue600};
    color: ${theme.blue600};
  }
  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
const NoteFooter = styled.div`
  margin-bottom: 0px;
  padding: 16px 24px;
  background: #f9f9f9;
  border: 1px solid #eaeaec;
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 450px) {
    margin-top: 85px;
  }
`;
const NoteForm: FC<Props> = ({ onCancel, editItem, onSubmit }) => {
  const [remindDate, setRemindDate] = useState<string | undefined | null>(
    undefined,
  );
  const [color, setColor] = useState<string | null>(null);
  const [selectColor, setSelectColor] = useState<string | null>(null);
  const [noteText, setNoteText] = useState<string>('');
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    if (editItem) {
      setNoteText(editItem.note);
      setRemindDate(editItem.reminderDate);
    }
  }, [editItem]);

  return (
    <MainCard>
      <Note>
        <NoteTextArea
          name="note"
          onChange={(e) => setNoteText(e.target.value)}
          value={noteText}
          placeholder="Write your note here"
          autoSize={{ minRows: 2, maxRows: 2 }}
        />

        <RemindNote>
          <After3Months
            selectColor={selectColor}
            onClick={() => {
              setRemindDate(moment().add(3, 'months').format(dateFormat));
              setSelectColor('#0052EA');
              setColor(null);
            }}>
            Remind this after 3 months
          </After3Months>
          <After6Months
            color={color}
            onClick={() => {
              setRemindDate(moment().add(6, 'months').format(dateFormat));
              setColor('#0052EA');
              setSelectColor(null);
            }}>
            Remind this after 6 months
          </After6Months>
          <DatePicker
            suffixIcon={<Image src={PayrollCalenderSVG} marginRight="7px" />}
            size="middle"
            placeholder="Pick a date to remind"
            value={remindDate ? dayjs(remindDate) : undefined}
            onChange={(date) => {
              date
                ? setRemindDate(moment().format(dateFormat))
                : setRemindDate(null);
              setSelectColor(null);
              setColor(null);
            }}
            disabledDate={(current) => {
              return current && current.isBefore(dayjs().endOf('day'));
            }}
          />
        </RemindNote>
      </Note>
      <NoteFooter>
        <PrimaryButton
          disabled={!noteText}
          onClick={() => {
            if (editItem) {
              onSubmit({
                type: NotificationTypeEnum.NOTE,
                note: noteText,
                reminderDate: remindDate,
                id: editItem.id,
              });
            } else {
              onSubmit({
                type: NotificationTypeEnum.NOTE,
                note: noteText,
                reminderDate: remindDate,
              });
            }
          }}>
          {editItem ? 'Edit Note' : 'Add Note'}
        </PrimaryButton>
        <SecondaryButton ml={16} onClick={() => onCancel()}>
          Cancel
        </SecondaryButton>
      </NoteFooter>
    </MainCard>
  );
};

export default NoteForm;
