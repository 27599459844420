import React, { FC, ReactNode } from 'react';

import theme from '../../../theme';
import { IExpenseStatus } from '../../../types/expense.types';
import { LeaveStatus } from '../../home/homeUtil';
import { AttendanceFilterTypes } from '../../../types/attendance.types';
import { Tag } from 'antd';
import { B } from '../../../components';

interface Props {
  children?: ReactNode;
  status: string;
}

export const ExpenseStatusTag: FC<Props> = ( {status} ) => {
  {
    switch (status) {
      case IExpenseStatus.PENDING:
      case LeaveStatus.PENDING:
        return (
          <Tag color={theme.lightBlue50} bordered={false}>
            <B type="b-small" color={theme.blue500}>
              Pending
            </B>
          </Tag>
        );
      case AttendanceFilterTypes.APPROVED:
      case IExpenseStatus.APPROVED:
      case LeaveStatus.ACCEPTED:
        return (
          <Tag color={theme.green50} bordered={false}>
            <B type="b-small" color={theme.green300}>
              Approved
            </B>
          </Tag>
        );
      case IExpenseStatus.REJECTED:
      case LeaveStatus.REJECTED:
        return (
          <Tag color={theme.red50} bordered={false}>
            <B type="b-small" color={theme.red300}>
              Rejected
            </B>
          </Tag>
        );
      case IExpenseStatus.PAID:
        return (
          <Tag color={theme.green50} bordered={false}>
            <B type="b-small" color={theme.green300}>
              Paid
            </B>
          </Tag>
        );
      case IExpenseStatus.ARCHIVED:
        return (
          <Tag color={theme.gray100} bordered={false}>
            <B type="b-small" color={theme.gray600}>
              Archived
            </B>
          </Tag>
        );
      case AttendanceFilterTypes.DRAFT:
        return (
          <Tag color={theme.warn} bordered={false}>
            <B type="b-small" color={theme.orange300}>
              Draft
            </B>
          </Tag>
        );
      default:
        return null;
    }
  }
};
