import React, { FC, useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  BorderProps,
  border,
} from 'styled-system';

import styled from '@emotion/styled';

import { B, Div, InputComp, SwitchComponent } from '../../../components';
import theme from '../../../theme';
import { LeavePolicyGroupI, LeaveSettingsI, LeaveTypesI } from '../../../types';
import FullTimeLeaveBalance from './FullTimeLeaveBalance';
import ProbationLeaveBalance from './ProbationLeaveBalance';
import { Validation } from '../../../common/validation';

import {
  CustomLeaveTypesI,
  ProRataPayItemTypesI,
  WorkTimeUnitType,
} from '../../../types/organization.types';
import { Divider } from '../../../components/Divider';
import CustomLeave from './CustomLeave';

const LeaveBalanceDiv = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;
interface Props {
  leavePolicyGroups: LeavePolicyGroupI[];
  leaveSettings: LeaveSettingsI | null;
  form: FormInstance<any>;
  onChange: (value: boolean) => void;
  payItemTypes?: ProRataPayItemTypesI | null;
  workTimeUnitType?: WorkTimeUnitType | null | string;
  customLeaveTypes?: CustomLeaveTypesI[];
  customLeaves: CustomLeaveTypesI[];
  setCustomLeaves: (v: CustomLeaveTypesI[]) => void;
}
const AvailableLeaveBalance: FC<Props> = ({
  leavePolicyGroups,
  leaveSettings,
  form,
  payItemTypes,
  customLeaveTypes,
  workTimeUnitType,
  customLeaves,
  setCustomLeaves,
}) => {
  const [isLeaveReset, setIsLeaveReset] = useState<boolean>(false);

  const [fullTimeLeaveBalance, setTimeLeaveBalance] = useState<LeaveTypesI[]>(
    [],
  );
  const [probationLeaveBalance, setProbationLeaveBalance] = useState<
    LeaveTypesI[]
  >([]);
  const [isCheckPayItemValidation, setCheckPayItemValidation] =
    useState<boolean>();
  const [checkPayItem, setCheckPayItem] = useState<{
    BASIC: boolean | undefined;
    FIXED_ALLOWANCE: boolean | undefined;
    VARIABLE_ALLOWANCE: boolean | undefined;
  }>({
    BASIC: false,
    FIXED_ALLOWANCE: false,
    VARIABLE_ALLOWANCE: false,
  });

  useEffect(() => {
    setCheckPayItemValidation(Object.values(checkPayItem).some((item) => item));
  }, [checkPayItem]);

  useEffect(() => {
    if (payItemTypes) {
      setCheckPayItem({
        BASIC: payItemTypes?.BASIC,
        FIXED_ALLOWANCE: payItemTypes?.FIXED_ALLOWANCE,
        VARIABLE_ALLOWANCE: payItemTypes?.VARIABLE_ALLOWANCE,
      });
    }
  }, [payItemTypes]);

  useEffect(() => {
    if (leaveSettings) {
      setIsLeaveReset(leaveSettings?.leaveReset?.status);
      form.setFieldsValue({
        date: leaveSettings?.leaveReset.date && leaveSettings.leaveReset.date,
        rate: leaveSettings?.noPay?.rate ? leaveSettings.noPay.rate : 0,
        annualLeaveReset: !!leaveSettings?.leaveReset?.status,
      });
    }
  }, [leaveSettings]);

  useEffect(() => {
    if (leavePolicyGroups) {
      setTimeLeaveBalance(
        leavePolicyGroups
          .filter((group) => group.name === 'FULL TIME')
          .map((item) => {
            return item.leaveTypes;
          })[0],
      );
      setProbationLeaveBalance(
        leavePolicyGroups
          .filter((group) => group.name === 'PROBATION')
          .map((item) => {
            return item.leaveTypes;
          })[0],
      );
    }
  }, [leavePolicyGroups]);

  return (
    <>
      <LeaveBalanceDiv>
        <B type="b-large-semibold" pb="12px">
          Custom Opening Balance
        </B>
        <B type="b-default" color={theme.gray600} pb="24px">
          Customize your leaves beyond the shop and office act’s mandate. Please
          note that you cannot reduce annual and casual leaves beyond the
          minimum.
        </B>
        <Div border={`1px solid ${theme.gray300}`} p="24px" mb="32px">
          <B type="b-large-semibold" pb="24px">
            Full time
          </B>
          <FullTimeLeaveBalance
            LeaveBalance={fullTimeLeaveBalance}
            workTimeUnitType={workTimeUnitType}
          />
          <B type="b-large-semibold" py="24px">
            Probation
          </B>
          <ProbationLeaveBalance
            LeaveBalance={probationLeaveBalance}
            workTimeUnitType={workTimeUnitType}
          />

          <B type="b-large-semibold" py="24px">
            Custom Leave
          </B>
          <CustomLeave
            customLeaves={customLeaves}
            setCustomLeaves={setCustomLeaves}
            form={form}
            customLeaveList={customLeaveTypes}
          />
        </Div>
        <B type="b-large-semibold" pb="16px">
          Annual Leave Reset
        </B>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Div display="flex">
              <FormItem
                mb="16px"
                name="annualLeaveReset"
                valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) => setIsLeaveReset(checked)}
                />
              </FormItem>
              <B type="b-default" color={theme.gray500} pl="18px" pt="5px">
                Annual leave reset on company
              </B>
            </Div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {isLeaveReset && (
              <Div display="flex">
                <B
                  type="b-default-semibold"
                  color={theme.blue700}
                  pr="5px"
                  pt="5px">
                  Leave Reset Date
                </B>
                <FormItem
                  mb="16px"
                  width="60%"
                  name="date"
                  rules={[
                    {
                      required: isLeaveReset,
                      message: 'required leave reset date',
                    },
                    {
                      pattern: Validation.DayAndMonthPattern,
                      message: 'invalid leave reset date',
                    },
                  ]}>
                  <InputComp size="small" placeholder="Ex :-DD-MM" />
                </FormItem>
              </Div>
            )}
          </Col>
        </Row>
      </LeaveBalanceDiv>
      <Divider />
    </>
  );
};

export default AvailableLeaveBalance;
