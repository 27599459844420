import styled from '@emotion/styled';
import { Form, Tooltip, message, Spin } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs, { Dayjs } from 'dayjs';
import { uniqueId } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  useGetAttendanceItemData,
  useGetUserAttendanceItemData,
  useUpdateUserAttendanceData,
} from '../../../../api/attendanceHooks';
import {
  ChevronLeftBlue,
  ChevronRightBlueSVG,
  PlusBlueSVG,
} from '../../../../assets';
import {
  B,
  DefaultBody,
  Div,
  FooterComponent,
  H,
  SecondaryButton,
  TableComp,
} from '../../../../components';
import { EditableCell } from '../../../../components/EditableCell';
import {
  NextPreviousEmployeeDiv,
  NextPreviousEmployeeImage,
} from '../../../../components/PeopleNavigation';
import OvertimeNoPaySelector, {
  SelectorFilterTypes,
} from '../../../../components/selector/OvertimeNoPaySelector';
import theme from '../../../../theme';
import {
  IAttendanceUserSummary,
  IAttendanceUserTable,
  IUserAttendanceFormData,
  IUserAttendanceSummary,
} from '../../../../types/attendance.types';
import { useWindowSize } from '../../../../util/screenSize';
import {
  dayJsFromMinutes,
  minutesToNearestHour,
  monthToString,
} from '../../../../util/timeConvertion';
import { capitalizeUnderscore } from '../../../../util/utils';
import { StyledCard } from '../../../leave/new-leave/LeavesBalance';
import { StyledTableDiv } from '../../../people/PeopleTable';
import AddDateModal from '../../shared/AddDateModal';
import { AttendanceNavigation } from '../../shared/AttendanceNavigator';
import {
  Header,
  HeaderSection,
  LeftSection,
  MobileHideButton,
  RightSection,
} from '../home/TimeAttendance';
import { useGetOrganization } from '../../../../api/organizationHooks';

type Props = {
  title: string;
  amount: number;
};

type ParapProps = {
  id: string;
  employeeId: string;
};

const StyledSpin = styled(Spin)`
  margin-left: 3px;
  margin-top: 3px;
`;

const Overview = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledDiv = styled(Div)`
  display: flex;
  gap: 74px;
`;

const LastColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  align-items: center;
  gap: 16px;
  height: 32px;
`;

const ViewAttendance = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<IAttendanceUserTable[]>([]);
  const [editingKey, setEditingKey] = useState(null);
  const [visible, setVisible] = useState(false);
  const { isMobile } = useWindowSize();
  const params = useParams<ParapProps>();
  const [currentValue, setCurrentValue] = useState<string>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [attendanceList, setAttendanceList] = useState<
    IUserAttendanceSummary[]
  >([]);

  const [dateList, setDateList] = useState<string[]>([]);
  const navigate = useNavigate();

  const {
    data: userAttendance,
    isSuccess: userAttendanceSuccess,
    isLoading: isLoadingUserAttendance,
    mutate: getUserAttendance,
  } = useGetUserAttendanceItemData();

  //attendanceData
  const {
    data: attendanceData,
    mutate: fetchTimeAttendanceData,
    isLoading: isLoadingAttendance,
    isSuccess: fetchedTimeAttendanceData,
  } = useGetAttendanceItemData();
  const { mutate: updateUserAttendance } = useUpdateUserAttendanceData();

  const { mutate: getOrganizationData, data: organizationData } =
    useGetOrganization();

  const maxOtHours = organizationData?.dailyWorkingHours;

  useEffect(() => {
    getOrganizationData();
  }, []);

  useEffect(() => {
    getUserAttendance({ employeeId: params.employeeId, id: params.id });
    fetchTimeAttendanceData(params.id);
    if (params.employeeId) {
      setCurrentValue(params.employeeId);
    }
    setAttendanceList(attendanceData?.data);
  }, [currentValue]);

  useEffect(() => {
    setAttendanceList(attendanceData?.data);
    if (params.employeeId) {
      setCurrentValue(params.employeeId);
    }
  }, [fetchedTimeAttendanceData, attendanceData]);

  useEffect(() => {
    if (userAttendanceSuccess) {
      const userData: IAttendanceUserTable[] = userAttendance.data?.map(
        (value) => {
          const overtime = dayJsFromMinutes(value.overtimeMinutes);
          const noPay = dayJsFromMinutes(value.noPayMinutes);

          return {
            ...value,
            overtimeMinutes: overtime,
            noPayMinutes: noPay,
          };
        },
      );
      setDataSource(userData);
    }
    if (userAttendance?.data) {
      const tempArr: string[] = [];
      userAttendance.data.forEach((item) => {
        tempArr.push(item.date);
      });
      setDateList(tempArr);
    }
  }, [userAttendanceSuccess, userAttendance, currentValue]);

  const isEditing = (record: IUserAttendanceFormData) =>
    record.id === editingKey;

  const getNextId = (array: IUserAttendanceSummary[], currentId: string) => {
    const currentIndex = array?.findIndex(
      (item) => item.employeeId === currentId,
    );
    if (currentIndex === -1 || currentIndex === array?.length - 1) {
      return null;
    }
    setCurrentIndex(currentIndex + 1);
    return array[currentIndex + 1].employeeId;
  };

  const getPreviousId = (array, currentId) => {
    const currentIndex = array?.findIndex(
      (item) => item.employeeId === currentId,
    );
    if (currentIndex <= 0) {
      return null;
    }
    setCurrentIndex(currentIndex - 1);
    return array[currentIndex - 1].employeeId;
  };

  const urlChange = (index: string) => {
    if (index) {
      setCurrentValue(index);
    }
    const nextEmployee = attendanceList?.find(
      (employee) => employee?.employeeId === index?.toString(),
    );
    navigate(`/time-attendance/view/${params.id}/${nextEmployee?.employeeId}`);
  };

  const edit = (record: Partial<IAttendanceUserTable> & { id: React.Key }) => {
    form.setFieldsValue({
      noPayMinutes: record.noPayMinutes,
      overtimeMinutes: record.overtimeMinutes,
    });

    setEditingKey(record.id);
  };

  const onSave = async (id: string) => {
    try {
      const row = (await form.validateFields()) as IAttendanceUserTable;
      const formattedRow = {
        ...row,
        overtimeMinutes:
          row.overtimeMinutes.hour() * 60 + row.overtimeMinutes.minute(),
        noPayMinutes: row.noPayMinutes.hour() * 60 + row.noPayMinutes.minute(),
      };

      const newData: IAttendanceUserSummary[] = dataSource.map((value) => {
        const overtime =
          value.overtimeMinutes.hour() * 60 + value.overtimeMinutes.minute();
        const noPay =
          value.noPayMinutes.hour() * 60 + value.noPayMinutes.minute();

        return {
          ...value,
          overtimeMinutes: overtime,
          noPayMinutes: noPay,
        };
      });
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...formattedRow });
        updateUserAttendance(
          newData.find((item: IAttendanceUserSummary) => item.id === id),
          {
            onSuccess: () => {
              getUserAttendance({
                employeeId: params.employeeId,
                id: params.id,
              });
              setEditingKey(null);
              message.success('Attendance updated successfully');
            },
          },
        );
      } else {
        newData.push(formattedRow);
        setEditingKey(null);
      }
    } catch (errInfo) {
      message.error('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      width: 230,
      title: 'Date',
      dataIndex: 'date',
      render: (value) => {
        return (
          <B type="b-small">
            {value ? dayjs(value).format('DD MMM YYYY') : '-'}
          </B>
        );
      },
    },
    {
      width: 300,
      title: 'Over Time Hours',
      dataIndex: 'overtimeMinutes',
      render: (value: Dayjs) => {
        return <B type="b-small">{`${value.format('HH:mm')}`}</B>;
      },
      editable: true,
    },
    {
      width: 300,
      title: 'No Pay Hours',
      dataIndex: 'noPayMinutes',
      render: (value: Dayjs) => {
        return <B type="b-small">{`${value.format('HH:mm')}`}</B>;
      },
      editable: true,
    },
    {
      dataIndex: 'action',
      key: uniqueId(),
      width: 200,
    },
  ];
  const handleFilter = (value: CheckboxValueType[]) => {
    let filteredData: IAttendanceUserTable[] = userAttendance?.data?.map(
      (value) => {
        const overtime = dayjs()
          .startOf('day')
          .add(value.overtimeMinutes, 'minute');
        const noPay = dayjs().startOf('day').add(value.noPayMinutes, 'minute');

        return {
          ...value,
          overtimeMinutes: overtime,
          noPayMinutes: noPay,
        };
      },
    );

    if (value?.length === 2) {
      filteredData = filteredData?.filter(
        (data) =>
          data?.overtimeMinutes.hour() * 60 + data?.overtimeMinutes.minute() >
            0 &&
          data?.noPayMinutes.hour() * 60 + data?.noPayMinutes.minute() > 0,
      );
    } else if (
      value?.includes(capitalizeUnderscore(SelectorFilterTypes.OVERTIME))
    ) {
      filteredData = filteredData?.filter(
        (data) =>
          data?.overtimeMinutes.hour() * 60 + data?.overtimeMinutes.minute() >
          0,
      );
    } else if (
      value?.includes(capitalizeUnderscore(SelectorFilterTypes.NO_PAY))
    ) {
      filteredData = filteredData?.filter(
        (data) =>
          data?.noPayMinutes.hour() * 60 + data?.noPayMinutes.minute() > 0,
      );
    }

    setDataSource(filteredData);
  };
  const OverviewCard: FC<Props> = ({ title, amount }) => {
    return (
      <>
        {isMobile ? (
          <StyledCard>
            <B type="b-large-semibold" color={theme.gray600}>
              {amount > -1 ? amount : '-'}
            </B>
            <B type="b-small" color={theme.black}>
              {title}
            </B>
          </StyledCard>
        ) : (
          <div>
            <B type="b-small" color={theme.gray600}>
              {title}
            </B>
            <B type="b-default-semibold" color={theme.black}>
              {amount > -1 ? amount : '-'}
            </B>
          </div>
        )}
      </>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        render: (original, record) => {
          if (col.dataIndex === 'date') {
            return record.isExceeded ? (
              <>
                <span className="error-row"></span>
                <B type="b-small">
                  {original ? dayjs(original).format('DD MMM YYYY') : '-'}
                </B>
              </>
            ) : (
              <B type="b-small">
                {original ? dayjs(original).format('DD MMM YYYY') : '-'}
              </B>
            );
          }
          if (
            col.dataIndex === 'action' &&
            attendanceData?.status !== 'APPROVED' &&
            attendanceData?.status !== 'ARCHIVED'
          ) {
            const editable = isEditing(record);
            return editable ? (
              <LastColumn>
                <B
                  type="b-default"
                  color={theme.gray600}
                  onClick={() => setEditingKey('')}>
                  Cancel
                </B>
                <B
                  type="b-default"
                  color={theme.blue500}
                  onClick={() => onSave(record.id)}>
                  Save
                </B>
              </LastColumn>
            ) : (
              <LastColumn>
                <B
                  type="b-default"
                  color={theme.blue500}
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                  className="view-attendance-edit">
                  Edit
                </B>
              </LastColumn>
            );
          }
          return <B type="b-small">{original}</B>;
        },
      };
    }

    return {
      ...col,
      onCell: (record: IUserAttendanceFormData) => ({
        form,
        record,
        inputType: 'time',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        maxOtHours: maxOtHours,
        roundOff: organizationData?.timeAttendanceSettings.roundOffToNearest,
        defaultVal:
          col.title === 'No Pay Hours'
            ? record.noPayMinutes
            : col.title === 'Over Time Hours'
            ? record.overtimeMinutes
            : null,
      }),
    };
  });

  return (
    <>
      <Overview>
        <StyledDiv>
          <OverviewCard
            title="Total OT Hours"
            amount={minutesToNearestHour(userAttendance?.totalOtMinutes)}
          />
          <OverviewCard
            title="Total No Pay Hours"
            amount={minutesToNearestHour(userAttendance?.totalNoPayMinutes)}
          />
          <OverviewCard
            title="Total No of Attendance"
            amount={userAttendance?.totalAttendance}
          />
        </StyledDiv>
        <RightSection>
          <Div display="flex">
            <AttendanceNavigation
              attendanceList={attendanceList}
              currentValue={currentValue}
              onSelect={(index) => {
                urlChange(index);
              }}
            />
            <NextPreviousEmployeeDiv
              style={{ visibility: currentIndex !== 0 ? 'visible' : 'hidden' }}>
              <Tooltip placement="topLeft" title="Previous Employee">
                <NextPreviousEmployeeImage
                  onClick={() => {
                    if (attendanceList && currentValue) {
                      urlChange(getPreviousId(attendanceList, currentValue));
                    }
                  }}
                  src={ChevronLeftBlue}
                />
              </Tooltip>
            </NextPreviousEmployeeDiv>
            <NextPreviousEmployeeDiv
              style={{
                visibility:
                  currentIndex !== attendanceList?.length - 1
                    ? 'visible'
                    : 'hidden',
              }}>
              <Tooltip placement="bottomRight" title="Next Employee">
                <NextPreviousEmployeeImage
                  onClick={() => {
                    if (attendanceList && currentValue) {
                      urlChange(getNextId(attendanceList, currentValue));
                    }
                  }}
                  src={ChevronRightBlueSVG}
                />
              </Tooltip>
            </NextPreviousEmployeeDiv>
          </Div>
        </RightSection>
      </Overview>
      <HeaderSection>
        <LeftSection>
          <Header>
            <H type="h6">Time Attendance</H>
          </Header>
          <Div m="auto 0">
            <B type="b-large-semibold" display="flex">
              {!isLoadingUserAttendance ? (
                `${monthToString(userAttendance?.month)} / ${
                  userAttendance?.year
                }`
              ) : (
                <Spin />
              )}
            </B>
          </Div>
        </LeftSection>

        <RightSection>
          {/* TODO: This might be needed later */}
          {/* <WeekSelector
            onChange={(value) => {
              setFilter(value);
              setDataSource([]);
              fetchTimeAttendanceData(value);
            }}
          /> */}

          <OvertimeNoPaySelector onChange={(value) => handleFilter(value)} />

          <MobileHideButton>
            {isLoadingAttendance ? (
              <StyledSpin />
            ) : attendanceData?.status !== 'APPROVED' &&
              attendanceData?.status !== 'ARCHIVED' ? (
              <SecondaryButton
                onClick={() => setVisible(true)}
                ml="16px"
                size="small"
                icon={<img src={PlusBlueSVG} />}>
                Add Date
              </SecondaryButton>
            ) : null}
          </MobileHideButton>
        </RightSection>
      </HeaderSection>
      <DefaultBody>
        {
          //TODO: Investigate last column not appearing if  if (column.dataIndex === 'action'){} section is removed
        }
        <StyledTableDiv>
          <Form form={form} component={false}>
            <TableComp
              loading={isLoadingUserAttendance}
              dataSource={dataSource}
              columns={mergedColumns}
              withBorders
              pagination={false}
              rowClassName={(record) =>
                record.isExceeded ? 'exceeded-row' : ''
              }
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              scroll={{
                y: window.innerHeight - 470,
              }}
            />
          </Form>
        </StyledTableDiv>
      </DefaultBody>
      {visible && (
        <AddDateModal
          username={dataSource[0]?.fullName || ''}
          month={userAttendance?.month}
          dateList={dateList}
          year={userAttendance?.year}
          onClose={() => {
            getUserAttendance({ employeeId: params.employeeId, id: params.id });
            setVisible(false);
          }}
          maxOtHours={maxOtHours}
          onSubmit={() =>
            getUserAttendance({ employeeId: params.employeeId, id: params.id })
          }
          roundOff={organizationData?.timeAttendanceSettings.roundOffToNearest}
          payrunStartDate={organizationData?.payrunStartDate}
          visible={visible}
          organizationHoliday={organizationData.organizationHoliday}
        />
      )}
      <FooterComponent
        leftChildren={
          <SecondaryButton marginLeft={23} onClick={() => navigate(-1)}>
            Back
          </SecondaryButton>
        }
      />
    </>
  );
};

export default ViewAttendance;
