/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { Form, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useUpdateOrganization } from '../../../api/organizationHooks';
import { CustomCard, Div, PrimaryButton } from '../../../components';
import { FooterComponent } from '../../../components/FooterComponent';
import { DispatchType, RootState } from '../../../store/reducer';
import { getMyOrganization } from '../../../store/actions';
import {
  CustomLeaveTypesI,
  OrganizationDataI,
} from '../../../types/organization.types';
import {
  AutomatedAllowanceTypesI,
  OTTypesI,
  UpdateOrganizationI,
} from '../../../types/organization.types';
import { scrollToFirstErrorField } from '../../../util/utils';
import AvailableLeaveBalance from './AvailableLeaveBalance';
import CompanyDetails from './CompanyDetails';
import CompanyLogo from './CompanyLogo';
import ETFInformation from './ETFInformation';
import PayslipSetting from './PayslipSettings';
import PublicHoliday from './PublicHoliday';
import StampDutySetting from './StampDutySetting';
import BudgetaryAllowanceSetting from './BudgetaryAllowanceSetting';
import WorkingHours from './WorkingHours';
import LeaveInformationSetting from './LeaveInformationSetting';
import OverTime from './OverTime';
import NoPay from './NoPay';
import ForeignCurrencyPeg from './ForeignCurrencyPeg';
import PayCycle from './PayCycle';
import TaxOnTax from './TaxOnTax';
import FourDecimalPoint from './FourDecimalPoint';
import AttendanceAllowance from './AttendanceAllowance';
import ProRated from './ProRated';
import dayjs from 'dayjs';

const CustomForm = styled(Form)`
  padding: 40px 40px 0px 32px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CompanySettings: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const orgLoading = useSelector(
    (state: RootState) => state.organization.loading,
  );
  const [locations, setLocations] = useState<string[]>([]);
  const [isCheckNoPay, setisCheckNoPay] = useState(false);
  const [form] = Form.useForm();
  const [otList, setOtList] = useState<OTTypesI[]>([]);
  const [autoList, setAutoList] = useState<AutomatedAllowanceTypesI[]>([]);
  const [customLeaves, setCustomLeaves] = useState<CustomLeaveTypesI[]>([]);

  const [initialValues, setInitialValues] = useState<OrganizationDataI>(
    {} as OrganizationDataI,
  );
  const [data, setData] = useState<any>({
    name: '',
    email: '',
    address: '',
    companyName: '',
    industry: '',
    location: [],
    epf: '',
    etf: '',
    startTime: '',
    endTime: '',
    calenderType: '',
    logo: '',
    seal: '',
  });

  const [isBasicSalaryChecked, setIsBasicSalaryChecked] =
    useState<boolean>(false);
  const [isFixedAllowanceChecked, setIsFixedAllowanceChecked] =
    useState<boolean>(false);
  const [isVariableAllowanceChecked, setIsVariableAllowanceChecked] =
    useState<boolean>(false);

  const getOrganizationData = async () => {
    await dispatch(getMyOrganization());
  };

  useEffect(() => {
    getOrganizationData();
  }, []);

  useEffect(() => {
    if (organizationData) {
      setInitialValues({
        ...organizationData,
        ...organizationData?.paySlipCustomizations,
      });
      setisCheckNoPay(organizationData.leaveSettings?.noPay.status);
    }
    if (organizationData?.payItemTypes) {
      for (const [key, value] of Object.entries(
        organizationData.payItemTypes,
      )) {
        form.setFieldsValue({ [key]: value });
      }
    }
  }, [organizationData]);

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length) {
      for (const [key, value] of Object.entries(initialValues)) {
        if (key === 'startTime') {
          form.setFieldsValue({
            startTime: initialValues.startTime
              ? dayjs(initialValues.startTime, 'HH:mm').format('HH:mm')
              : '8:00',
          });
        } else if (key === 'endTime') {
          form.setFieldsValue({
            endTime: initialValues.endTime
              ? dayjs(initialValues.endTime, 'HH:mm').format('HH:mm')
              : '17:00',
          });
        } else {
          form.setFieldsValue({ [key]: value });
        }
      }
      if (initialValues.location) {
        setLocations(initialValues.location);
      }
    }
  }, [initialValues]);

  const {
    isLoading: loadingUpdateOrganization,
    mutate: updateOrganization,
    error: errorUpdateOrganization,
    data: dataUpdateOrganization,
  } = useUpdateOrganization();

  useEffect(() => {
    if (dataUpdateOrganization && !errorUpdateOrganization) {
      getOrganizationData();
    }
  }, [dataUpdateOrganization]);

  const leaveBalanceGroups = (values: any, type: string) => {
    return Object.fromEntries(
      Object.entries(values).filter(([key, value]) => key.includes(type)),
    );
  };
  const textSplit = (text: string) => {
    return text.split('-')[1];
  };

  const onFinish = (values: any) => {
    const FullTime = leaveBalanceGroups(values, 'FULL_TIME');
    const Probation = leaveBalanceGroups(values, 'PROBATION');
    values.logo = data.logo || values.logo;
    values.seal = data.seal || values.seal;
    values.location = locations;
    const value: UpdateOrganizationI = {
      ...values,
      leaveInfo: {
        'FULL TIME': Object.entries(FullTime).map(([key, v]) => ({
          id: Number(textSplit(key)),
          maxPerYear: Number(v),
        })),

        PROBATION: Object.entries(Probation).map(([key, v]) => ({
          id: Number(textSplit(key)),
          maxPerYear: v,
        })),
      },
      leaveSettings: {
        leaveReset: {
          status: values.annualLeaveReset || false,
          date: values.annualLeaveReset ? values.date : null,
        },
        noPay: {
          status: values.noPay || false,
          rate: values.noPay ? values.rate : 0,
          isEnableProRated: values.isEnableProRated || false,
          isApit: values.NOPAY_APIT || false,
          isEpf: values.NOPAY_EPF || false,
        },
      },
      customLeaveTypes: customLeaves,
      payItemTypeSelection: {
        BASIC: values.BASIC || false,
        FIXED_ALLOWANCE: values.FIXED_ALLOWANCE || false,
        VARIABLE_ALLOWANCE: values.VARIABLE_ALLOWANCE || false,
      },
      otSettings: {
        ot: {
          status: values?.ot || false,
          isApit: values?.OT_APIT || false,
          isEpf: values?.OT_EPF || false,
        },
      },
      automatedAllowanceSettings: {
        automatedAllowance: {
          status: values.automatedAllowance || false,
        },
      },
      otPayItemTypeSelection: {
        BASIC: values.OT_BASIC || false,
        FIXED_ALLOWANCE: values.OT_FIXED_ALLOWANCE || false,
        VARIABLE_ALLOWANCE: values.OT_VARIABLE_ALLOWANCE || false,
      },
      otTypes: otList,
      automatedAllowanceTypes: autoList,
      peggedSettings: {
        pegged: {
          status: values.pegged || false,
        },
        peggedInfo: {
          basedCurrency: values.basedCurrency,
          foreignCurrencyType: values.foreignCurrencyType,
          exchangeRate: values.exchangeRate,
          currency: values.currency,
          handledBy: values.handledBy,
          allowanceTitle: values.allowanceTitle,
          isEpfEtf: values.isEpfEtf,
          isApit: values.isApit,
        },
      },
      prorataSettings: {
        prorata: {
          status: values.prorated || false,
        },
      },
      prorataTypeSelection: {
        BASIC: isBasicSalaryChecked,
        FIXED_ALLOWANCE: isFixedAllowanceChecked,
        VARIABLE_ALLOWANCE: isVariableAllowanceChecked,
      },
      PeggedTypeSelection: {
        BASIC: values.PEGGED_BASIC || false,
        FIXED_ALLOWANCE: values.PEGGED_FIXED_ALLOWANCE || false,
        VARIABLE_ALLOWANCE: values.PEGGED_VARIABLE_ALLOWANCE || false,
      },
      payrunStartDate: values.payrunStartDate,
      taxOnTaxSettings: {
        taxOnTax: {
          status: values.taxOnTax || false,
        },
        taxOnTaxInfo: {
          borneBy: values.borneBy,
        },
      },
      stampDutySettings: {
        isStampDutyAllowed: values.isStampDutyAllowed || false,
        isDeductionsExclude: values.isDeductionsExclude || false,
      },
    };

    Object.keys(value).forEach((item) => {
      if (
        item.includes('FULL_TIME') ||
        item.includes('PROBATION') ||
        item.includes('isEnable') ||
        item.includes('rate') ||
        item.includes('noOfDays') ||
        item.includes('amount') ||
        item.includes('isApit') ||
        item.includes('isEpf') ||
        [
          'date',
          'annualLeaveReset',
          'noPay',
          'rate',
          'exchangeRate',
          'currency',
          'handledBy',
          'allowanceTitle',
          'isEpfEtf',
          'isApit',
          'noPayItem',
          'noPayStatutoryItem',
          'otStatuaryPayItem',
          'otPayItem',
          'peggedPayItem',
          'otList',
          'customLeaves',
          'taxOnTax',
          'borneBy',
          'basedCurrency',
          'foreignCurrencyType',
          'isEnableProRated',
          'isStampDutyAllowed',
          'deductionExclude',
        ].includes(item)
      ) {
        if (
          item !== 'isEpfEtfBudgetaryAllowance' &&
          item !== 'isApitBudgetaryAllowance'
        ) {
          delete value[item as keyof UpdateOrganizationI];
        }
      }
      if (
        item === 'BASIC' ||
        item === 'FIXED_ALLOWANCE' ||
        item === 'VARIABLE_ALLOWANCE'
      ) {
        delete value[item as keyof UpdateOrganizationI];
      }
      if (
        item === 'OT_BASIC' ||
        item === 'OT_FIXED_ALLOWANCE' ||
        item === 'OT_VARIABLE_ALLOWANCE' ||
        item === 'OT_APIT' ||
        item === 'OT_EPF' ||
        item === 'ot'
      ) {
        delete value[item as keyof UpdateOrganizationI];
      }

      if (item === 'NOPAY_EPF' || item === 'NOPAY_APIT') {
        delete value[item as keyof UpdateOrganizationI];
      }
      if (item === 'automatedAllowance' || item === 'allowanceName') {
        delete value[item as keyof UpdateOrganizationI];
      }

      if (
        item === 'PEGGED_BASIC' ||
        item === 'PEGGED_FIXED_ALLOWANCE' ||
        item === 'PEGGED_VARIABLE_ALLOWANCE' ||
        item === 'pegged'
      ) {
        delete value[item as keyof UpdateOrganizationI];
      }

      if (item === 'prorata') {
        delete value[item as keyof UpdateOrganizationI];
      }
    });

    updateOrganization(value);
  };

  const setProperties = (params: string) => {
    setData({
      logo: params,
    });
  };
  const setSeal = (param: string) => {
    setData({
      seal: param,
    });
  };
  const changeBasicSalaryCheck = (checked: boolean) => {
    setIsBasicSalaryChecked(checked);
  };
  const changeFixedAllowanceCheck = (checked: boolean) => {
    setIsFixedAllowanceChecked(checked);
  };
  const changeVariableAllowanceCheck = (checked: boolean) => {
    setIsVariableAllowanceChecked(checked);
  };

  return (
    <Spin spinning={orgLoading || loadingUpdateOrganization}>
      <CustomForm
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={scrollToFirstErrorField}>
        <CustomCard>
          <CompanyLogo onChangeImageUpload={(url) => setProperties(url)} />
          <CompanyDetails
            locations={locations}
            onChangeLocation={(locations) => setLocations(locations)}
          />
          <PayCycle />
          <ETFInformation />
          <TaxOnTax
            form={form}
            taxOnTaxSettings={organizationData?.taxOnTaxSettings || null}
          />
          <WorkingHours
            isMandatory={isCheckNoPay}
            organizationStartTime={organizationData?.startTime}
            organizationEndTime={organizationData?.endTime}
            monthlyWorkingDays={organizationData?.monthlyWorkingDays}
            dailyWorkingHours={organizationData?.dailyWorkingHours}
            monthlyWorkingDaysSettings={
              organizationData?.monthlyWorkingDaysSettings
            }
            formRef={form}
            workTimeUnitType={organizationData?.workTimeUnitType}
          />
          <AvailableLeaveBalance
            onChange={(v) => setisCheckNoPay(v)}
            form={form}
            leavePolicyGroups={organizationData?.leavePolicyGroup || []}
            leaveSettings={organizationData?.leaveSettings || null}
            payItemTypes={organizationData?.payItemTypes}
            workTimeUnitType={organizationData?.workTimeUnitType}
            customLeaveTypes={organizationData?.customLeaveTypes}
            customLeaves={customLeaves}
            setCustomLeaves={setCustomLeaves}
          />
          <LeaveInformationSetting
            paySlipConfig={organizationData?.paySlipConfig}
            isSystem={organizationData?.isUsingSystemLeaves}
            isLeaveInPayroll={organizationData?.isLeaveInPayroll}
          />
          <NoPay
            onChange={(v) => setisCheckNoPay(v)}
            form={form}
            leavePolicyGroups={organizationData?.leavePolicyGroup || []}
            leaveSettings={organizationData?.leaveSettings || null}
            payItemTypes={organizationData?.payItemTypes}
            workTimeUnitType={organizationData?.workTimeUnitType}
          />
          <OverTime
            otList={otList}
            setOtList={setOtList}
            form={form}
            otSettings={organizationData?.otSettings || null}
            otPayItemType={organizationData?.otPayItemTypeSelection}
            overTimeList={organizationData?.otTypes}
          />
          <AttendanceAllowance
            autoList={autoList}
            setAutoList={setAutoList}
            form={form}
            attendanceSetting={
              organizationData?.automatedAllowanceSettings || null
            }
            autoAllowanceList={organizationData?.automatedAllowanceTypes}
          />

          <FourDecimalPoint
            form={form}
            isFourDecimalPoint={organizationData?.isFourDecimalPoint}
          />
          <ForeignCurrencyPeg
            form={form}
            peggedSettings={organizationData?.peggedSettings || null}
            PeggedTypeSelection={organizationData?.PeggedTypeSelection}
          />
          {organizationData && (
            <ProRated
              form={form}
              prorataSettings={organizationData.prorataSettings}
              prorataTypeSelection={organizationData.prorataTypeSelection}
              isBasicSalaryChecked={isBasicSalaryChecked}
              setIsBasicSalaryChecked={changeBasicSalaryCheck}
              isFixedAllowanceChecked={isFixedAllowanceChecked}
              setIsFixedAllowanceChecked={changeFixedAllowanceCheck}
              isVariableAllowanceChecked={isVariableAllowanceChecked}
              setIsVariableAllowanceChecked={changeVariableAllowanceCheck}
            />
          )}
          <PayslipSetting setSealUrl={(url) => setSeal(url)} />
          <StampDutySetting
            form={form}
            isStampDutySettings={{
              isStampDutyAllowed: organizationData?.isStampDutyAllowed || false,
              isDeductionsExclude:
                organizationData?.isDeductionsExclude || false,
            }}
          />
          <BudgetaryAllowanceSetting
            budgetaryAllowanceSetting={{
              isAutoBudgetaryReliefAllowance:
                organizationData?.isAutoBudgetaryReliefAllowance || false,
              isEpfEtfBudgetaryAllowance:
                organizationData?.isEpfEtfBudgetaryAllowance || false,
              isApitBudgetaryAllowance:
                organizationData?.isApitBudgetaryAllowance || false,
            }}
          />
          <PublicHoliday />
        </CustomCard>

        <Div mt="80px">
          <FooterComponent
            rightChildren={
              <PrimaryButton ml={16} loading={orgLoading} htmlType="submit">
                Save
              </PrimaryButton>
            }
          />
        </Div>
      </CustomForm>
    </Spin>
  );
};

export default CompanySettings;
