import { message } from 'antd';
import axios from 'axios';
import * as FullStory from '@fullstory/browser';

import showApiError from '../../util/showApiError';
import {
  clearStorage,
  setAuthPayloads,
  setCurrentAuthIndex,
  getCurrentAuthPayload,
  getAuthPayloads,
  getCurrentAuthIndex,
  getCurrentAuthToken,
  isCurrentAuthSuperAdmin,
} from '../../util/storageUtil';
import { getLoggedInUser } from './people';
import { AuthPayload } from '../../types';
import { logoutRequest, setupAxiosToken } from '../../util/api';
import { RootState } from '../reducer';
import { getLoggedInSuperAdmin } from './superAdmin';

const LOGOUT_FROM_APP = 'LOGOUT_FROM_APP';
const LOGIN_LOADING = 'LOGIN_LOADING';
const RESET_DATA = 'RESET_DATA';

const SIGNUP_DATA = 'SIGNUP_DATA';
const ERROR_HANDLING = 'ERROR_HANDLING';
const CHANGED_PASSWORD = 'CHANGED_PASSWORD';
const INITIAL_LOADING = 'INITIAL_LOADING';
const SET_AUTH_PAYLOADS = 'SET_AUTH_PAYLOADS';

const LOAD_ALL_AUTH_PAYLOADS = 'LOAD_ALL_AUTH_PAYLOADS';
const SET_ALL_AUTH_PAYLOADS = 'SET_ALL_AUTH_PAYLOADS';
const SET_ALL_AUTH_PAYLOADS_ERROR = 'SET_ALL_AUTH_PAYLOADS_ERROR';

const RESET_LINK_EXPIRE = 'RESET_LINK_EXPIRE';

const CLEAR_ME = 'CLEAR_ME';
export interface SignupI {
  fullName: string | null;
  loginEmail: string | null;
}

export interface ChangePasswordI {
  oldPassword: string | null;
  newPassword: string | null;
}
export interface UserLoginI {
  email: string;
  password: string;
  keepLoggedIn: boolean;
}
interface userSignupI {
  fullName: string | null;
  password: string | null;
  token: string;
}

export interface ResetPasswordI {
  resetToken: string;
  password: string;
}
interface InitialStateI {
  loginLoading: boolean;
  resetData: number | null;
  signupData: SignupI | null;
  errorHandling: boolean;
  changePassword: ChangePasswordI | null;
  initialLoading: boolean;

  authTokenPayloads: AuthPayload[];
  currentAuthPayload: AuthPayload | null;
  currentAuthIndex: number;

  authAllPayloadsLoading: boolean;
  authAllPayloadError: string | null;

  resetLinkData: boolean;
}

const initialState: InitialStateI = {
  loginLoading: false,
  resetData: null,
  signupData: null,
  errorHandling: false,
  changePassword: null,
  initialLoading: true,

  authTokenPayloads: [],
  currentAuthPayload: null,
  currentAuthIndex: 0,

  authAllPayloadsLoading: false,
  authAllPayloadError: null,

  resetLinkData: false,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case INITIAL_LOADING:
      return {
        ...state,
        initialLoading: action.payload,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: action.payload,
      };
    case SET_AUTH_PAYLOADS:
      return {
        ...state,
        authTokenPayloads: action.payload.authTokenPayloads,
        currentAuthPayload: action.payload.currentAuthPayload,
        currentAuthIndex: action.payload.currentAuthIndex,
      };
    case RESET_DATA:
      return {
        ...state,
        resetData: action.payload,
      };
    case SIGNUP_DATA:
      return {
        ...state,
        signupData: action.payload,
      };
    case ERROR_HANDLING:
      return {
        ...state,
        errorHandling: action.payload,
      };
    case CHANGED_PASSWORD:
      return {
        ...state,
        changePassword: action.payload,
      };
    case LOAD_ALL_AUTH_PAYLOADS:
      return {
        ...state,
        authAllPayloadsLoading: action.payload,
      };
    case SET_ALL_AUTH_PAYLOADS_ERROR:
      return {
        ...state,
        authAllPayloadError: action.payload,
      };
    case SET_ALL_AUTH_PAYLOADS:
      return {
        ...state,
        authTokenPayloads: action.payload,
      };
    case RESET_LINK_EXPIRE:
      return {
        ...state,
        resetLinkData: action.payload,
      };
    default:
      return state;
  }
}

export function initializeApp() {
  return async (dispatch) => {
    try {
      // load data from the api

      //this should be removed after a week
      const isFirstTimeLoading = localStorage.getItem('isFirstTimeLoading');
      if (!isFirstTimeLoading) {
        clearStorage();
        setupAxiosToken(null);
        localStorage.setItem('isFirstTimeLoading', 'true');
      }
      /////
      const payloads = getAuthPayloads();
      const currentAuthPayload = getCurrentAuthPayload();
      const currentAuthIndex = getCurrentAuthIndex();

      dispatch({
        type: SET_AUTH_PAYLOADS,
        payload: {
          authTokenPayloads: payloads,
          currentAuthPayload: currentAuthPayload,
          currentAuthIndex: currentAuthIndex,
        },
      });

      if (currentAuthPayload) {
        setupAxiosToken(currentAuthPayload.jwtPayload);

        if (isCurrentAuthSuperAdmin()) {
          await dispatch(getLoggedInSuperAdmin());
        } else {
          await dispatch(getLoggedInUser());
        }
      }
    } catch (error: any) {
      showApiError(error);
      throw error(error);
    } finally {
      dispatch({ type: INITIAL_LOADING, payload: false });
    }
  };
}

export function loginByToken(token: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_ALL_AUTH_PAYLOADS, payload: true });
      setupAxiosToken(token);
      const res = await axios.get('/auth/sso');
      setAuthPayloads(res.data);

      dispatch({ type: INITIAL_LOADING, payload: true });
      await dispatch(switchAuthPayload(0));
    } catch (error: any) {
      dispatch({ type: LOGIN_LOADING, payload: false });
    } finally {
      dispatch({ type: LOGIN_LOADING, payload: false });
    }
  };
}

export function loginUser(data: UserLoginI, organizationId?: number) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOGIN_LOADING, payload: true });
      axios.defaults.withCredentials = true;
      const { data: responseBody } = await axios('/auth/login', {
        method: 'post',
        data: data,
        withCredentials: true,
      });
      setAuthPayloads(responseBody);
      dispatch({ type: INITIAL_LOADING, payload: true });

      let organizationIndex = 0;
      if (organizationId && responseBody.length) {
        responseBody.forEach((element: AuthPayload, index: number) => {
          if (organizationId == element.organizationId) {
            organizationIndex = index;
          }
        });
      }
      await dispatch(switchAuthPayload(organizationIndex));
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
    } finally {
      dispatch({ type: LOGIN_LOADING, payload: false });
    }
  };
}

export function switchAuthPayload(authIndex: number) {
  return async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    if (state.auth.currentAuthIndex !== authIndex) {
      dispatch({ type: INITIAL_LOADING, payload: true });
    }
    setCurrentAuthIndex(authIndex);
    await dispatch(initializeApp());
  };
}

export function logOutUser() {
  return async (dispatch: any) => {
    try {
      const payloads = await getAuthPayloads();
      await logoutRequest.post('/auth/logout', { ...payloads });
      await dispatch({ type: LOGOUT_FROM_APP });
      setupAxiosToken(null);
      clearStorage();
      await dispatch({ type: CLEAR_ME });
      await dispatch(initializeApp());
      // once your logout, Fullstory session should be shutdown
      // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users#anonymizing-users
      FullStory.shutdown();
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function signupUser(data: userSignupI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOGIN_LOADING, payload: true });
      await axios.post('/auth/signUp', { ...data });
      dispatch({ type: LOGIN_LOADING, payload: false });
      message.success('Successfully Signup');
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message);
      } else {
        showApiError(error);
      }
      dispatch({ type: LOGIN_LOADING, payload: false });
    }
  };
}

export function getSignupUser(token: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ERROR_HANDLING, payload: false });
      dispatch({ type: LOGIN_LOADING, payload: true });
      const res = await axios.get(`/auth/getSignUpUser/${token}`);
      dispatch({ type: SIGNUP_DATA, payload: res.data });
      dispatch({ type: LOGIN_LOADING, payload: false });
      dispatch({ type: ERROR_HANDLING, payload: false });
    } catch (error: any) {
      dispatch({ type: ERROR_HANDLING, payload: true });
      dispatch({ type: LOGIN_LOADING, payload: false });
    }
  };
}

export function changePassword(data: ChangePasswordI) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOGIN_LOADING, payload: true });
      dispatch({ type: ERROR_HANDLING, payload: false });
      const res = await axios.post('/auth/changePassword', { ...data });
      dispatch({ type: CHANGED_PASSWORD, payload: res.data });
      dispatch({ type: LOGIN_LOADING, payload: false });
      message.success('Successfully Changed!');
      return true;
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: LOGIN_LOADING, payload: false });
      dispatch({ type: ERROR_HANDLING, payload: true });
      return false;
    }
  };
}

export function getAllAuthPayloads() {
  return async (dispatch: any) => {
    try {
      if (getCurrentAuthToken()) {
        dispatch({ type: LOAD_ALL_AUTH_PAYLOADS, payload: true });
        const authPayload = getAuthPayloads();
        const res = await axios.post('/auth/allAvailableTokens', {
          payload: authPayload,
        });
        dispatch({ type: SET_ALL_AUTH_PAYLOADS, payload: res.data });
        setAuthPayloads(res.data);
      }
    } catch (error: any) {
      const errorMessage = showApiError(error);
      dispatch({ type: SET_ALL_AUTH_PAYLOADS_ERROR, payload: errorMessage });
      if (
        error.response &&
        (error.response.status == 401 || error.response.status == 404)
      ) {
        dispatch(logOutUser());
      }
    } finally {
      dispatch({ type: LOAD_ALL_AUTH_PAYLOADS, payload: false });
    }
  };
}

export function resetLinkExpire(token: string) {
  return async (dispatch: any) => {
    try {
      const res = await axios.post(`/auth/isResetLinkExpired/${token}`);
      dispatch({ type: RESET_LINK_EXPIRE, payload: res.data });
    } catch (error: any) {
      showApiError(error);
    }
  };
}
