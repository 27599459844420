export const TIME_LIST = [
  {
    label: '00:00',
    value: '00.00',
    disabled: false,
  },
  {
    label: '01:00',
    value: '1.00',
    disabled: false,
  },
  {
    label: '02:00',
    value: '2.00',
    disabled: false,
  },
  {
    label: '03:00',
    value: '03.00',
    disabled: false,
  },
  {
    label: '04:00',
    value: '4.00',
    disabled: false,
  },
  {
    label: '05:00',
    value: '5.00',
    disabled: false,
  },
  {
    label: '06:00',
    value: '6.00',
    disabled: false,
  },
  {
    label: '07:00',
    value: '7.00',
    disabled: false,
  },
  {
    label: '08:00',
    value: '8.00',
    disabled: false,
  },
  {
    label: '09:00',
    value: '9.00',
    disabled: false,
  },
  {
    label: '10:00',
    value: '10.00',
    disabled: false,
  },
  {
    label: '11:00',
    value: '11.00',
    disabled: false,
  },
  {
    label: '12:00',
    value: '12.00',
    disabled: false,
  },
  {
    label: '13:00',
    value: '13.00',
    disabled: false,
  },
  {
    label: '14:00',
    value: '14.00',
    disabled: false,
  },
  {
    label: '15:00',
    value: '15.00',
    disabled: false,
  },
  {
    label: '16:00',
    value: '16.00',
    disabled: false,
  },
  {
    label: '17:00',
    value: '17.00',
    disabled: false,
  },
  {
    label: '18:00',
    value: '18.00',
    disabled: false,
  },
  {
    label: '19:00',
    value: '19.00',
    disabled: false,
  },
  {
    label: '20:00',
    value: '20.00',
    disabled: false,
  },
  {
    label: '21:00',
    value: '21.00',
    disabled: false,
  },
  {
    label: '22:00',
    value: '22.00',
    disabled: false,
  },
  {
    label: '23:00',
    value: '23.00',
    disabled: false,
  },
];

export const WOKRING_DAYS_CONFIG = [
  {
    label: 'Calendar',
    value: 'Calendar',
  },
  {
    label: 'Fixed',
    value: 'Fixed',
  },
  {
    label: 'Variable',
    value: 'Variable',
  },
];
export const WORK_TIME_UNIT_TYPE = [
  {
    label: 'Days/hours',
    value: 'daysHours',
  },
  {
    label: 'Days/half days',
    value: 'daysHalfDays',
  },
  {
    label: 'Decimal Inputs',
    value: 'decimalInputs',
  },
];
