import React, { FC } from 'react';
import { Badge, Popover } from 'antd';
import moment from 'moment';
import styled from '@emotion/styled';
import { B, Div, NameTag } from '../../../../components';
import theme from '../../../../theme';
import { UserHistoryI } from '../../../../types/people.types';
import { useWindowSize } from '../../../../util/screenSize';
import {
  addDotsForLongText,
  capitalizeUnderscore,
  isHaveThreeDots,
} from '../../../../util/utils';
import { durationByYearAndMonth } from '../../../people/PeopleUtil';

const MainContainer = styled.div`
  margin-bottom: 24px;
  padding: 16px 24px 24px;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid ${theme.gray400};
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  experience: UserHistoryI;
}

export const ExperienceDetail: FC<Props> = ({ experience }) => {
  const { isDesktopLarge, isDesktop } = useWindowSize();
  const jotTitleMaxLenght = isDesktopLarge ? 70 : isDesktop ? 40 : 30;
  const designation = experience?.designation || '-';
  const startDateValidity = moment(experience?.startDate).isValid();
  const endDateValidity = moment(experience?.endDate).isValid();
  return (
    <>
      <MainContainer>
        <HeaderSection>
          <Div display="flex" m="auto 0">
            <B type="b-large-semibold">
              {isHaveThreeDots(designation, jotTitleMaxLenght) ? (
                <Popover
                  content={designation}
                  trigger="hover"
                  placement="topLeft">
                  {addDotsForLongText(designation, jotTitleMaxLenght)}
                </Popover>
              ) : (
                designation
              )}
            </B>
            {experience.isCurrentJob && (
              <Div m="auto" pl="15px">
                <NameTag textColor={theme.green300} color={theme.green50}>
                  Current
                </NameTag>
              </Div>
            )}
          </Div>
        </HeaderSection>
        <div>
          <Div display="flex" pb="8px" pt="4px">
            <B type="b-default" pr="8px">
              {experience?.branchName}
            </B>
            <Badge size="default" color={theme.black} />
            <B type="b-default" color={theme.gray600}>
              {experience?.employmentType &&
                capitalizeUnderscore(experience?.employmentType)}
            </B>
          </Div>
          <Div display="flex" pb="8px">
            <B type="b-default" color={theme.gray600}>
              {startDateValidity &&
                moment(experience?.startDate).format('DD.MM.YYYY')}
              &nbsp;-&nbsp;
              {endDateValidity
                ? moment(experience?.endDate).format('DD.MM.YYYY')
                : 'Present'}
            </B>
            <B type="b-default" pl="4px" color={theme.gray600}>
              (
              {durationByYearAndMonth(
                startDateValidity
                  ? moment(experience?.startDate).format('DD.MM.YYYY')
                  : undefined,
                endDateValidity
                  ? moment(experience?.endDate).format('DD.MM.YYYY')
                  : moment().format('DD.MM.YYYY'),
              )}
              )
            </B>
          </Div>
          <Div display="flex">
            <B type="b-default" color={theme.gray600}>
              Manager :
            </B>
            <B type="b-default" color={theme.gray600} pl="4px">
              {experience?.manager &&
              isHaveThreeDots(experience?.manager.fullName, 25) ? (
                <Popover
                  content={<>{experience?.manager}</>}
                  trigger="hover"
                  placement="topLeft">
                  {addDotsForLongText(experience?.manager.fullName, 25)}
                </Popover>
              ) : experience?.manager ? (
                experience?.manager.fullName
              ) : (
                '-'
              )}
            </B>
          </Div>
        </div>
      </MainContainer>
    </>
  );
};

export default ExperienceDetail;
