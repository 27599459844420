import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Menu, message, Select } from 'antd';
import { B, DefaultBody, RadioButton, RadioGroup } from '../../components';
import AllLeaveTable from './new-leave/AllLeaveTable';
import ManageLeaveBalanceModal from './new-leave/ManageLeaveBalanceModal';

import UserLeavesTable from './new-leave/UserLeavesTable';
import { isAllowed } from '../../util/permissionUtil';
import {
  OrganizationLeaveI,
  OrganizationLeaveRequestI,
  Permission,
} from '../../types';
import { DataRangeI } from '../../types/report.types';
import {
  leavesBalanceMe,
  getAllOrganizationLeaves,
  getMinimumUserInfo,
  getMyLeaves,
  getOrganizationLeaveRequests,
  getMyLeaveRequests,
} from '../../store/actions';
import { LeaveStatus } from '../home/homeUtil';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../store/reducer';
import LeaveOverview from './new-leave/LeaveOverview';
import styled from '@emotion/styled';
import { useEditReview, useRequestLeaveAsAdmin } from '../../api/leaveHooks';
import theme from '../../theme';
import RangePickerComponent from '../../components/selector/RangePickerComponent';
import { DropdownBlack, DropdownBlue, SettingsSVG } from '../../assets';
import { capitalizeFirstLetter } from '../../util/utils';
import { reviewAllowed } from '../../util/reviewAllowed';

const { Option } = Select;

const SelectComponent = styled(Select)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.black};
  width: 153px;
  height: 32px;

  .ant-select-selection-item {
    color: ${theme.black} !important;
    text-align: start !important;
  }

  .ant-select-selected-item {
    background-color: green !important;
  }

  @media only screen and (max-width: 420px) {
    width: 160px;
  }
`;

const CustomRadioGroup = styled(RadioGroup)`
  @media only screen and (max-width: 768px) {
  width: 50%;
    .ant-radio-button-wrapper {
      margin-bottom: 3px !important;
      width: 100%;
      text-align: center;
    }
  }
`;

const TabLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SettingsIcon = styled.img`
  padding-left: 10px;
  @media only screen and (max-width: 400px) {
    padding-left: 0px;
  }
  @media only screen and (max-width: 320px) {
    width: 20px;
    height: 20px;
  }
`;

const Container = styled.div`
  padding: 32px 0px 27px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding: 0px 0px 18px;
    flex-direction: column;
    overflow:hidden;
  }
`;

const ActionContainer = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media only screen and (max-width: 768px) {
    padding: 0px 0px 18px;
    flex-direction: row;
    margin-left: 0px;
    align-items: center;
    margin-top:5px;
  }

  @media only screen and (max-width: 470px) {
    padding: 0px 0px 18px;
    flex-direction: column;
    overflow:hidden;
    margin-left: 0px;
    align-items: start;
  }
`;

const LeaveHomePage: FC = () => {
  const [isAllLeaveTab, setIsAllLeaveTab] = useState(true);
  const me = useSelector((state: RootState) => state.people.me);
  // We have put this because manager, can only view user leave balances
  // Admins can edit them.
  const isAllowedLeaveVisibility =
    Boolean(me?.isManager) || isAllowed(Permission.MANAGE_ASSIGNED_USERS);
  const isAllowedLeaveEdit = isAllowed(Permission.MANAGE_ASSIGNED_USERS);
  const [visible, setVisible] = useState(false);
  const [leavesFilter, setLeavesFilter] = useState<string>('ALL');
  const [status, setStatus] = useState<LeaveStatus>();
  const [dateRange, setDateRange] = useState<DataRangeI | null>(null);
  const dispatch: DispatchType = useDispatch();
  const [filter, setFilter] = useState<LeaveStatus | ''>('');

  const {
    error: errorEditReview,
    data: editReview,
    mutate: putEditReview,
  } = useEditReview();

  useEffect(() => { 
    dispatch(getOrganizationLeaveRequests(filter));
    dispatch(leavesBalanceMe());
  }, [editReview]);
  useEffect(() => {
    if (!errorEditReview && editReview && status) {
      if (status === LeaveStatus.ACCEPTED) {
        message.success('Leave request approved!');
      }
      if (status === LeaveStatus.REJECTED) {
        message.success('Leave request rejected!');
      }
    }
  }, [editReview, status]);

  const [dataSource, setDataSource] = useState<OrganizationLeaveRequestI[]>([]);

  useEffect(() => {
    if (isAllowed(Permission.MANAGE_ASSIGNED_USERS) || !!me?.isManager) {
      dispatch(getMinimumUserInfo());
    }
    if (!me?.isExternal) {
      dispatch(leavesBalanceMe());
    }
    dispatch(getOrganizationLeaveRequests(''));
    dispatch(getMyLeaveRequests());
  }, []);

  function onSelect(selectedData: string) {
    if (selectedData === LeaveStatus.PENDING) {
      setFilter(LeaveStatus.PENDING);
    } else if (selectedData === LeaveStatus.ACCEPTED) {
      setFilter(LeaveStatus.ACCEPTED);
    } else if (selectedData === LeaveStatus.REJECTED) {
      setFilter(LeaveStatus.REJECTED);
    } else {
      setFilter('');
    }
  }
  useEffect(() => {
    dispatch(getOrganizationLeaveRequests(filter));
  }, [filter]);

  const myLeaves = useSelector((state: RootState) => state.leave.myLeaveRequests);

  useEffect(() => {
    const list: OrganizationLeaveRequestI[] = [];
    myLeaves.forEach((leaves) => {
      const myLeavesTable: OrganizationLeaveRequestI = {
        ...leaves,
      };
      if (
        leavesFilter === LeaveStatus.PENDING &&
        myLeavesTable.reqBy[0].status === LeaveStatus.PENDING
      ) {
        list.push(myLeavesTable);
      } else if (
        leavesFilter === LeaveStatus.ACCEPTED &&
        myLeavesTable.reqBy[0].status === LeaveStatus.ACCEPTED
      ) {
        list.push(myLeavesTable);
      } else if (
        leavesFilter === LeaveStatus.REJECTED &&
        myLeavesTable.reqBy[0].status === LeaveStatus.REJECTED
      ) {
        list.push(myLeavesTable);
      } else if (leavesFilter === 'ALL') {
        list.push(myLeavesTable);
      }
    });
    setDataSource(list);
  }, [myLeaves]);

  const organizationLeaveRequests = useSelector(
    (state: RootState) => state.leave.organizationLeaveRequests,
  );

  const filteredOrganizationData = organizationLeaveRequests?.filter((data) =>(
    reviewAllowed({
      reviewerId: me?.id,
      userId: Number(data.reqBy[0].userId),
    })
  ))



  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const leavesBalancesMe = useSelector(
    (state: RootState) => state.leave.leavesBalanceMe,
  );

  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  useEffect(() => {
    if (scrollTarget) {
      const targetElement = document.getElementById(`${scrollTarget}`);

      targetElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollTarget]);

  return (
    <>
      <DefaultBody>
        <LeaveOverview
          leaveData={leavesBalancesMe}
          allPeoples={allPeoples}
          userId={!me?.isExternal ? me?.id : undefined}
        />
        <Container>
          {isAllowedLeaveVisibility ? (
            <CustomRadioGroup defaultValue="allLeave">
              <RadioButton
                value="allLeave"
                onClick={() => setIsAllLeaveTab(true)}>
                <TabLabel>
                  {capitalizeFirstLetter(leavesFilter)} Leaves
                </TabLabel>
              </RadioButton>
              <RadioButton
                value="myLeave"
                onClick={() => setIsAllLeaveTab(false)}>
                <TabLabel>My Leave</TabLabel>
              </RadioButton>
            </CustomRadioGroup>
          ) : (
            <B type="b-large-semibold">Requested Leave</B>
          )}
          <ActionContainer>
            <RangePickerComponent
              onChange={(value) => setDateRange(value)}
              defaultStartDeductMonths={null}
            />
            {isAllLeaveTab && isAllowedLeaveVisibility && (
              <SelectComponent
                notFoundContent="Not Found"
                bordered={true}
                onSelect={(e) => {
                  onSelect(String(e));
                  setLeavesFilter(String(e));
                }}
                defaultValue="ALL"
                suffixIcon={<img src={DropdownBlack} />}>
                <Option value="ALL">All Leaves</Option>
                <Option value="PENDING">Pending Approval</Option>
                <Option value="ACCEPTED">Approved</Option>
                <Option value="REJECTED">Rejected</Option>
              </SelectComponent>
            )}
          </ActionContainer>
        </Container>
        {isAllLeaveTab ? (
          <>
            {isAllowedLeaveVisibility ? (
              <AllLeaveTable
                dateRange={dateRange}
                organizationLeaveRequests={filteredOrganizationData}
                onDataChange={(value) => {
                  setStatus(value.status);
                  putEditReview(value);
                }}
                filter={filter}
              />
            ) : (
              <UserLeavesTable
                myLeaveRequests={dataSource}
                dateRange={dateRange}
              />
            )}
          </>
        ) : (
          <UserLeavesTable myLeaveRequests={dataSource} dateRange={dateRange} />
        )}
      </DefaultBody>
      {visible && (
        <ManageLeaveBalanceModal
          allPeoples={allPeoples}
          onClose={() => setVisible(false)}
        />
      )}
    </>
  );
};
export default LeaveHomePage;
