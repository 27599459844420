import React, { FC, useEffect } from 'react';
import { css, Global } from '@emotion/react';
import Routes from './Routes';
import theme from './theme';
import { getAllAuthPayloads, initializeApp } from '../src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from './store/reducer';
import { Spin } from 'antd';
import { globalAlertStyles } from './common/globalAlertStyles';
import { InternalErrorComponent } from './components/InternalErrorMessage';
import { AttendanceProvider } from './context/AttendanceContext';
import { useHoursPerDay } from './api/organizationHooks';
import { initializeHoursPerDay } from './types/leave.types';
const App: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const initialLoading = useSelector(
    (state: RootState) => state.auth.initialLoading,
  );
  
  const { HOURS_PER_DAY } = useHoursPerDay();
    initializeHoursPerDay(HOURS_PER_DAY );
    
  useEffect(() => {
    const init = async () => {
      await dispatch(initializeApp());
      await dispatch(getAllAuthPayloads());
    };
    init();
  }, []);
  return (
    <div className="App">
      <Global
        styles={css`
          ${globalAlertStyles}
          * {
            font-family: 'Inter', sans-serif;
          }
          ::-webkit-scrollbar {
            width: 6px !important;
            height: 8px !important;
            background-color: ${theme.gray50} !important;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${theme.gray400} !important;
          }
        `}
      />
      <InternalErrorComponent />
      {initialLoading ? (
        <Spin
          css={css`
            position: fixed;
            z-index: 999;
            height: 2em;
            width: 2em;
            overflow: show;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          `}></Spin>
      ) : (
        <AttendanceProvider>
          <Routes />
        </AttendanceProvider>
      )}
    </div>
  );
};

export default App;
