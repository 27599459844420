//import 'antd/dist/antd.css'; // TODO: do we really need this
import React, { FC, useEffect, useState } from 'react';
import { Image, Select } from 'antd';
import { Link } from 'react-router-dom';
import { B } from '../../../components';
import { useWindowSize } from '../../../util/screenSize';
import { ProfileMenuItemType, PROFILE_MENU_ITEMS } from '../../../configs';
import styled from '@emotion/styled';
import { DropdownIcon } from '../../../assets';

const { Option } = Select;

const MenuDropdown = styled(Select)`
  min-width: 190px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    margin: 15px 0px 0px 0px !important;
    padding-left: 0px !important;
  }
  .ant-select-selection-item {
    line-height: 39px !important;
    padding-right: 25px !important;
  }
`;
interface Props {
  defaultIndex: string;
}

const UserPeopleSelect: FC<Props> = ({ defaultIndex }) => {
  const { isMobile, isTablet } = useWindowSize();
  const [userMenuItems, setUserMenuItems] =
    useState<ProfileMenuItemType[]>(PROFILE_MENU_ITEMS);

  useEffect(() => {
    setUserMenuItems(PROFILE_MENU_ITEMS);
  }, []);

  return (
    <>
      {isMobile || isTablet ? (
        <div>
          <MenuDropdown
            suffixIcon={
              <Image
                width="18px"
                height="18px"
                preview={false}
                src={DropdownIcon}
              />
            }
            defaultValue={defaultIndex}>
            {userMenuItems.map((menuItem, i) => {
              return (
                <Option key={i.toString()} value={(i + 1).toString()}>
                  <Link
                    to={{
                      pathname: `${menuItem.path}`,
                    }}>
                    <B type="b-large-semibold">{menuItem.displayName}</B>
                  </Link>
                </Option>
              );
            })}
          </MenuDropdown>
        </div>
      ) : null}
    </>
  );
};

export default UserPeopleSelect;
