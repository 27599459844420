import React, { FC, Fragment } from 'react';
import { Radio, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  B,
  DeleteModal,
  EmptyTextContainer,
  TableComp,
  Image,
  Div,
} from '../../../components';
import { deleteLeave, deleteLeaveRequest } from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { OrganizationLeaveI, OrganizationLeaveRequestI } from '../../../types';
import { LeaveStatus } from '../../home/homeUtil';
import LeavePeriodDisplay from '../../home/shared/leaves/LeavePeriodDisplay';
import LeaveTypeDisplay from '../../home/shared/leaves/LeaveTypeDisplay';
import { convertHoursToHoursAndMin } from '../utils';
import UserLeave from './UserLeave';
import { ExpenseStatusTag } from '../../expenses/shared';
import { DataRangeI } from '../../../types/report.types';
import moment from 'moment';
import { tableSorter } from '../../../util/tableSorter';
import { LeaveStatusTags } from '../../expenses/shared/Leavestatus';
import { addDotsForLongText } from '../../../util/utils';
import ExpandableTableComp from '../../../components/ExpandableTableComp';
import { ChevronUp } from '../../../assets';
import { ChevronDown } from '../../../assets';
import { useWindowSize } from '../../../util/screenSize';
import { ExpandButton, StyledRadioGroup } from './AllLeaveTable';

interface Props {
  tableScrollY?: number;
  myLeaveRequests: OrganizationLeaveRequestI[];
  dateRange: DataRangeI | null;
}

const UserLeaveItem = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const StatusColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;
const TableCard = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const UserLeavesTable: FC<Props> = ({
  tableScrollY,
  myLeaveRequests,
  dateRange,
}) => {
  const { isDesktopLarge } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const myLeaveRequestsLoading = useSelector(
    (state: RootState) => state.leave.myLeaveRequestsLoading,
  );
  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  const columns: ColumnProps<OrganizationLeaveRequestI>[] = [
    {
      title: 'Date Range/ Hours',
      dataIndex: ['startDate', 'endDate'],
      width: isDesktopLarge ? 300 : 200,
      render: (_hours: string, data) => {
        const hours = !isExpandable(data) ? data?.reqBy[0]?.hours : 0;
        const range =
        data.reqBy.length > 1
            ? data.startDate + ' - ' + data.endDate
            : data.startDate + ' / ' + hours;
        return (
          <Div>
            <LeavePeriodDisplay data={range} />
          </Div>
        );
      },

      sorter: (a, b) => tableSorter.defaultSort(a.startDate, b.startDate),
      sortDirections: ['descend', 'ascend'],
      key: 'DateRange',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: isDesktopLarge ? 200 : 150,
      render: (_value, data) => {
        const { pending, accepted, rejected } = data.reqBy.reduce(
          (acc, req) => {
            req.status === LeaveStatus.PENDING
              ? (acc.pending += 1)
              : req.status === LeaveStatus.ACCEPTED
              ? (acc.accepted += 1)
              : req.status === LeaveStatus.REJECTED
              ? (acc.rejected += 1)
              : null;
            return acc;
          },
          { pending: 0, accepted: 0, rejected: 0 },
        );

        const leaveStatusList = [
          { status: LeaveStatus.ACCEPTED, count: accepted },
          { status: LeaveStatus.PENDING, count: pending },
          { status: LeaveStatus.REJECTED, count: rejected },
        ];

        const hasStatus = leaveStatusList.filter(({ count }) => count > 0);

        return data.reqBy[0].reviewBy && hasStatus.length === 1 ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {data.reqBy[0].reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {data.reqBy[0].reviewBy.fullName}
                  </B>
                )}
                {data.reqBy[0].reviewBy &&
                  data.reqBy[0].status === LeaveStatus.REJECTED && (
                    <B color="white" type="b-small">
                      Reject Note : {data.reqBy[0].rejectionNote || '-'}
                    </B>
                  )}
              </Fragment>
            }>
            <StatusColumn>
              <LeaveStatusTags status={data.reqBy[0].status} />
            </StatusColumn>
          </Tooltip>
        ) : (
          <StatusColumn>
            {hasStatus.length === 1 ? (
              <LeaveStatusTags status={data.reqBy[0].status} />
            ) : (
              leaveStatusList.map(
                ({ status, count }) =>
                  count > 0 && (
                    <LeaveStatusTags
                      key={status}
                      status={status}
                      count={count}
                    />
                  ),
              )
            )}
          </StatusColumn>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: isDesktopLarge ? 200 : 150,
      render: (_value, data) => <LeaveTypeDisplay data={data} />,
    },

    {
      title: 'Comment',
      dataIndex: 'comment',
      width: isDesktopLarge ? 200 : 150,
      ellipsis: true,
      render: (_value, data) => (
        <>
          <B type="b-small">
            <Tooltip placement="topLeft" title={data.reqBy[0].reason}>
              {data.reqBy[0].reason || '-'}
            </Tooltip>
          </B>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'cancel All',
      width: 500,
      render: (_value, data) => (
        <>
          {data.reqBy.every((leaves) => leaves.status === LeaveStatus.PENDING) && (
            <StyledRadioGroup>
              <Radio.Button>
                <DeleteModal
                  title="Confirm Deletion"
                  buttonLabel="yes, Delete"
                  content="Are you sure you want to delete this leave?"
                  onClickCancel={() => {
                    dispatch(deleteLeaveRequest(String(data.id)));
                  }}
                  openModal={
                    <B cursor="pointer" type="b-small" color="#0052ea">
                      {isExpandable(data) ? 'Delete All' : 'Delete'}
                    </B>
                  }
                />
              </Radio.Button>
            </StyledRadioGroup>
          )}
        </>
      ),
    },
  ];

  const expandedColumns: ColumnProps<OrganizationLeaveI>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 120,
      render: (_hours: string, data) => <LeavePeriodDisplay data={data.date} />,
      sorter: (a, b) => tableSorter.defaultSort(a.date, b.date),
      sortDirections: ['descend', 'ascend'],
      key: 'date',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width: 120,
      render: (_hours: string, data) => (
        <B type="b-default">{convertHoursToHoursAndMin(data.hours)}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.hours, b.hours),
      sortDirections: ['descend', 'ascend'],
      key: 'hours',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      render: (_value, data) =>
        data.reviewBy ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {data.reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {data.reviewBy.fullName}
                  </B>
                )}
                {data.reviewBy && data.status === LeaveStatus.REJECTED && (
                  <B color="white" type="b-small">
                    Reject Note : {data.rejectionNote || '-'}
                  </B>
                )}
              </Fragment>
            }>
            <StatusColumn>
              <LeaveStatusTags status={data.status} />
            </StatusColumn>
          </Tooltip>
        ) : (
          <StatusColumn>
            <LeaveStatusTags status={data.status} />
          </StatusColumn>
        ),
    },
    {
      title: '',
      dataIndex: 'cancel',
      width: 100,
      render: (_value, data) => (
        <>
          {data.status === LeaveStatus.PENDING && (
            <StyledRadioGroup>
              <Radio.Button>
                <DeleteModal
                  title="Confirm Deletion"
                  buttonLabel="Yes, Delete"
                  content="Are you sure you want to delete this leave?"
                  onClickCancel={() => {
                    dispatch(deleteLeave(String(data.id)));
                  }}
                  openModal={
                    <B cursor="pointer" type="b-default" color="#0052ea">
                      Delete
                    </B>
                  }
                />
              </Radio.Button>
            </StyledRadioGroup>
          )}
        </>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You dont have any leave requested yet" />
    ),
  };

  const startDate = moment(dateRange?.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange?.endDate).format('YYYY-MM-DD');
  let filteredDateRange = null;
  let filteredLeaves = null;

  if (dateRange?.startDate === null || dateRange?.endDate === null) {
    filteredDateRange = myLeaveRequests;
  } else {
    filteredDateRange = myLeaveRequests.filter(
      (item) =>
        (item.startDate <= endDate && item.startDate >= startDate) ||
        (item.endDate <= endDate && item.endDate >= startDate),
    );
  }

  const expandDataSource = (reqId: string) => {
    const filteredRequests = filteredDateRange?.filter(
      (leaveReq: OrganizationLeaveRequestI) => {
        return leaveReq.id === reqId;
      },
    );

    filteredRequests.map((leave) => (filteredLeaves = leave.reqBy));

    return filteredLeaves;
  };

  const expandedRowRender = (reqId: string) => (
    <ExpandableTableComp<OrganizationLeaveI>
      key={reqId}
      rowKey={(item) => {
        return String(item.id);
      }}
      columns={expandedColumns}
      dataSource={expandDataSource(reqId)}
      pagination={false}
    />
  );

  const isExpandable = (record: OrganizationLeaveRequestI) => {
    const count = record.reqBy.reduce(
      (acc, id) => {
        !!id ? (acc.count += 1) : null;
        return acc;
      },
      { count: 0 },
    );
    return count.count > 1;
  };

  return (
    <>
      <TableCard>
        <TableComp
          rowKey={(item) => {
            return String(item.id);
          }}
          headerFontSize={14}
          rowClassName={(row) =>
            row.id === scrollTarget ? 'yellow-highlight' : ''
          }
          loading={myLeaveRequestsLoading}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record?.id),
            rowExpandable: (record) => isExpandable(record),
            expandRowByClick: true, 
            expandIcon: ({ expanded, onExpand, record }) =>
              isExpandable(record) &&
              (expanded ? (
                <ExpandButton onClick={(e) => onExpand(record, e)}>
                  <Image src={ChevronUp} space="100px" mr="6px" />
                </ExpandButton>
              ) : (
                <ExpandButton onClick={(e) => onExpand(record, e)}>
                  <Image src={ChevronDown} space="100px" mr="6px" />
                </ExpandButton>
              )),
          }}
          dataSource={filteredDateRange}
          locale={locale}
          pagination={false}
          withBorders={true}
          scroll={{
            x: 700,
            y: tableScrollY || window.innerHeight - 320,
          }}
        />
      </TableCard>
      <UserLeaveItem>
        {!!myLeaveRequests.length ? (
          <>
            {myLeaveRequests.map((item) =>
              item.reqBy.map((leaves) => <UserLeave leavesDetails={leaves} />),
            )}
          </>
        ) : (
          <EmptyTextContainer description="You don't have any leave yet" />
        )}
      </UserLeaveItem>
    </>
  );
};
export default UserLeavesTable;
