import React, { FC, ReactNode, useState } from 'react';
import { Empty } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import styled from '@emotion/styled';
import { LogTrailItemI } from '../store/actions';
import { B, Div, FooterComponent } from './';
import TableComp from './TableComp';
import { replaceJSX } from '../util/replaceJSX';
import theme from '../theme';

const DateColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const Span = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
`;

const LOG_LIMIT = 20;

const StyledEmpty = styled(Empty)`
  .ant-empty-image {
    display: none;
  }
`;

const LogTable = styled(TableComp)`
  min-width: 100%;
`;

const antTableLocale = {
  emptyText: (
    <StyledEmpty description={<Span>There is no Log</Span>}></StyledEmpty>
  ),
};

interface Props {
  logs: LogTrailItemI[];
  loading: boolean;
  leftChildren?: ReactNode;
}

export const LogTrailTable: FC<Props> = ({ logs, loading, leftChildren }) => {
  const [showingCount, setShowingCount] = useState(LOG_LIMIT);
  const columns: ColumnProps<LogTrailItemI>[] = [
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: 200,
      align: 'left',
      render: (value: string) => (
        <DateColumn>{moment(value).format('YYYY-MM-DD hh:mm a')}</DateColumn>
      ),
    },
    {
      title: 'Activity',
      dataIndex: 'message',
      align: 'left',
      render: (_value, row) => renderLog(row),
    },
  ];

  const renderLog = (logItem: LogTrailItemI) => {
    const mapperKeys = Object.keys(logItem.mapper);
    let logMessage: Array<string | ReactNode> = [logItem.message];

    mapperKeys.forEach((mapperKey, i) => {
      const { display, id, type, link } = logItem.mapper[mapperKey];

      const findKey = `{@:${mapperKey}}`;
      let url: string | null = null;
      if (type === 'USER') {
        url = `/contact/personal-details/${id}`;
      } else if (logItem.payrollId) {
        if (type === 'PAYROLL') {
          url = `/Payroll-view/${logItem.payrollId}`;
          if (link) {
            url = link;
          }
        }
      } else if (logItem.userId) {
        if (type === 'USER_HISTORY') {
          url = `/contact/experience-details/${logItem.userId}`;
        } else if (type === 'USER_PAY_ITEM') {
          url = `/contact/pay-details/${logItem.userId}`;
        } else if (type === 'USER_DOC') {
          url = `/contact/document/${logItem.userId}`;
        }
      }

      if (url) {
        let replaceComponent = (
          <a key={i} rel="_blank" href={url}>
            {display}
          </a>
        );

        if (link) {
          replaceComponent = (
            <a key={i} rel="_blank" href={link}>
              click here
            </a>
          );
        }

        logMessage = replaceJSX(logMessage, findKey, replaceComponent);
      }

      if (!url) {
        const replaceNextComponent = (
          <a
            key={i}
            rel="_blank"
            href={`/personal-salary-information/${logItem.payrollId}/employee/${logItem.actorId}/ALL_EMPLOYEE`}>
            {display}
          </a>
        );
        logMessage = replaceJSX(logMessage, findKey, replaceNextComponent);
      }
    });

    return logMessage;
  };

  return (
    <>
      <LogTable
        size="middle"
        loading={loading}
        scroll={{
          x: 800,
        }}
        rowKey={(record: LogTrailItemI) => `log-${record?.id}`}
        columns={columns}
        dataSource={logs.slice(0, showingCount)}
        pagination={false}
        locale={antTableLocale}
        withBorders={true}
      />
      {showingCount < LOG_LIMIT || showingCount >= logs.length ? null : (
        <FooterComponent
          leftChildren={leftChildren}
          rightChildren={
            <Div display="flex" marginTop="8px">
              <B type="b-small" pr="15px">
                Showing {showingCount} out of {logs.length}
              </B>

              <B
                type="b-default"
                onClick={() => setShowingCount(showingCount + LOG_LIMIT)}
                color={theme.blue50}>
                Show More
              </B>
            </Div>
          }
        />
      )}
    </>
  );
};
