import styled from '@emotion/styled';
import { Collapse } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { B, Div, LinkButton } from '../../../components';
import theme from '../../../theme';
import { AddLoanNoteI, LoanDocumentI } from '../../../types/loan.types';
import { isValidMoment } from '../../../util/isValidMoment';
import { DocumentUploadModal } from './DocumentUploadModal';
import LoanNoteForm from './LoanNoteForm';
import NoteItem from './NoteItem';
import { BlueCancel, DownloadBlue, PlusBlueSVG } from '../../../assets';

const { Panel } = Collapse;

const CollapseSection = styled(Collapse)`
  border: none;
  background-color: ${theme.white};
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${theme.black};
    background: ${theme.gray50} !important;
    border-radius: 4px !important;

    border: 0.5px solid ${theme.gray300};
    padding: 16px 32px !important;
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0px !important;
      display: flex;
      flex-direction: column;
    }
    border-top: none;
    margin-top: 0px;
  }
  .content-one {
    border: none;
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    color: ${theme.gray600};
    padding: 10px 0;
  }
  .content-two {
    border: 1px solid ${theme.gray300};
  }
  .ant-collapse-item {
    padding: 0px !important;
    border-radius: 4px !important;
    margin-bottom: 24px;
    border-bottom: none;
  }
  .ant-collapse-item-active {
    padding: 0px !important;
  }
  .ant-collapse-header .ant-collapse-arrow {
    top: 17px !important;
    left: 34px !important;
    font-weight: 900 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: inline-block !important;
    margin-right: 12px !important;
    font-size: 12px !important;
  }

  .ant-collapse-arrow svg {
    margin-top: 6px;
  }

  .ant-collapse-extra {
    margin-top: 2px;
  }

  .ant-collapse-item-active > .ant-collapse-header {
    border-radius: 4px 4px 0px 0px !important;
  }
`;

const DocumentList = styled.div`
  padding: 24px 0px;
  div:first-child {
    padding-top: 0px;
  }
  div:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
  border: 1px solid ${theme.gray300};
  border-top: none;
`;

const DocumentRemoveIcon = styled.img`
  cursor: pointer;
  margin-bottom: 4px;
`;

const PlusIcon = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`;

interface Props {
  note?: AddLoanNoteI[];
  userId?: number;
  onStatusChange?: () => void;
  onEditNote: (id: string | number | undefined, data: AddLoanNoteI) => void;
  onAddNewNote: (data: AddLoanNoteI) => void;
  onRemoveNote: (id: string | number) => void;
  documents?: LoanDocumentI[];
  onSubmitDocument: (document: LoanDocumentI) => void;
  onRemoveDocument: (id: string | number) => void;
  isViewLoan?: boolean;
  isUserView?: boolean;
}

const NotesAndDocuments: FC<Props> = ({
  note,
  userId,
  onStatusChange,
  onEditNote,
  onAddNewNote,
  onRemoveNote,
  documents,
  onSubmitDocument,
  onRemoveDocument,
  isViewLoan,
  isUserView,
}) => {
  const [showAddNewNote, setShowAddNewNote] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const onSubmitNote = (values: AddLoanNoteI) => {
    const value = {
      ...(values.id && { id: values.id }),
      ...(values.loanId && { loanId: values.loanId }),
      ...(values.updatedAt && { updateAt: values.updatedAt }),
      note: values.note,
      reminderDate: values.reminderDate,
      userId: userId,
    };

    if (editIndex > -1) {
      onEditNote(values.id, value);
    } else {
      onAddNewNote(value);
    }
    setShowAddNewNote(false);
    setEditIndex(-1);
  };

  const onCancel = () => {
    setShowAddNewNote(false);
    setEditIndex(-1);
  };

  useEffect(() => {
    onCancel();
  }, [note]);

  return (
    <div>
      <CollapseSection>
        <Panel
          header={
            <B type="b-large" pl="32px">
              Notes
            </B>
          }
          extra={
            !isUserView && (
              <PlusIcon>
                <img
                  src={PlusBlueSVG}
                  style={{ cursor: 'pointer', marginTop: '0px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAddNewNote(true);
                  }}
                />
              </PlusIcon>
            )
          }
          key="1">
          <Div className="content-one">
            <B type="b-default">
              These notes are only visible to Owners, Admins, and Guest Admins.
            </B>
          </Div>
          <Div className="content-two">
            {showAddNewNote && (
              <LoanNoteForm
                isLoanView={isViewLoan}
                onSubmit={(v) => onSubmitNote(v)}
                onCancel={() => setShowAddNewNote(false)}
              />
            )}
            {note && note.length > 0
              ? note.map((item, index) => {
                  if (index === editIndex) {
                    return (
                      <Div>
                        <LoanNoteForm
                          editItem={item}
                          onSubmit={(v) => onSubmitNote(v)}
                          onCancel={() => onCancel()}
                        />
                      </Div>
                    );
                  }
                  return (
                    <NoteItem
                      isUserView={isUserView}
                      item={item}
                      isLastItem={index === note.length - 1}
                      onClickEdit={() => {
                        setEditIndex(index);
                      }}
                      onRemove={() => {
                        if (item.id) {
                          onRemoveNote(item.id);
                        }
                      }}
                      onStatusChange={onStatusChange}
                    />
                  );
                })
              : !showAddNewNote && (
                  <Div
                    height={124}
                    background={theme.gray50}
                    display="flex"
                    borderRadius={4}>
                    <Div m="auto">
                      <B type="b-default" color={theme.gray600}>
                        You don't have any notes yet.
                      </B>
                      {!isUserView && (
                        <Div display="flex" justifyContent="center">
                          <LinkButton
                            icon={<img src={PlusBlueSVG} />}
                            onClick={() => setShowAddNewNote(true)}>
                            Add Notes
                          </LinkButton>
                        </Div>
                      )}
                    </Div>
                  </Div>
                )}
          </Div>
        </Panel>

        <Panel
          header={
            <B type="b-large" pl="32px">
              Documents
            </B>
          }
          extra={
            !isUserView && (
              <PlusIcon>
                <img
                  src={PlusBlueSVG}
                  style={{ cursor: 'pointer', marginTop: '0px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDocumentModal(true);
                  }}
                />
              </PlusIcon>
            )
          }
          key="2">
          <DocumentList>
            {documents && documents.length > 0 ? (
              documents.map((item) => {
                if (item) {
                  return (
                    <Div
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom={`1px solid ${theme.gray300}`}
                      p="16px 0px">
                      <div>
                        <B type="b-default" marginLeft="45px">
                          {item.name}
                        </B>
                      </div>
                      <Div
                        display="flex"
                        alignItems="center"
                        marginRight="38px">
                        <B type="b-default" color={theme.gray600} pr="22px">
                          {isValidMoment(
                            item.updatedAt,
                            undefined,
                            'DD MMM YYYY',
                          ).toString()}
                        </B>
                        {isViewLoan && (
                          <Div pr="24px">
                            <a href={item.fileUrl} download>
                              <img src={DownloadBlue} />
                            </a>
                          </Div>
                        )}
                        {!isUserView && (
                          <DocumentRemoveIcon
                            src={BlueCancel}
                            onClick={() => {
                              if (item.id) {
                                onRemoveDocument(item.id);
                              }
                            }}
                          />
                        )}
                      </Div>
                    </Div>
                  );
                }
              })
            ) : (
              <Div
                height={124}
                background={theme.gray50}
                display="flex"
                borderRadius={4}>
                <Div m="auto">
                  <B type="b-default" color={theme.gray600}>
                    You don't have any documents yet.
                  </B>
                  {!isUserView && (
                    <Div display="flex" justifyContent="center">
                      <LinkButton
                        icon={<img src={PlusBlueSVG} />}
                        onClick={() => setShowDocumentModal(true)}>
                        Add Documents
                      </LinkButton>
                    </Div>
                  )}
                </Div>
              </Div>
            )}
          </DocumentList>
        </Panel>
        {showDocumentModal && (
          <DocumentUploadModal
            isViewLoan={isViewLoan}
            visible={showDocumentModal}
            handleCancel={() => setShowDocumentModal(false)}
            onSubmit={(document) => {
              onSubmitDocument(document);
              setShowDocumentModal(false);
            }}
          />
        )}
      </CollapseSection>
    </div>
  );
};

export default NotesAndDocuments;
