//import 'antd/dist/antd.css'; // TODO: do we really need this

import React, { FC, useEffect, useState } from 'react';

import { Image, Select } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { B } from '../../../components';
import { useWindowSize } from '../../../util/screenSize';
import {
  PeopleMenuItemType,
  PeopleStatusEnum,
  PEOPLE_MENU_ITEMS,
} from '../../../configs';
import styled from '@emotion/styled';
import { DropdownIcon } from '../../../assets';
import theme from '../../../theme';

const { Option } = Select;

const MenuDropdown = styled(Select)`
  min-width: 190px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    padding-left: 0px !important;
  }
  .ant-select-selection-item {
    line-height: 39px !important;
    padding-right: 25px !important;
  }
`;
interface Props {
  defaultIndex: string;
}

type UseParamI = {
  employeeKey: string;
};

const PeopleSelect: FC<Props> = ({ defaultIndex }) => {
  const { isMobile, isTablet } = useWindowSize();
  const params = useParams<UseParamI>();
  const [userMenuItems, setUserMenuItems] =
    useState<PeopleMenuItemType[]>(PEOPLE_MENU_ITEMS);

  useEffect(() => {
    setUserMenuItems(PEOPLE_MENU_ITEMS);
  }, []);

  return (
    <>
      {isMobile || isTablet ? (
        <div>
          <MenuDropdown
            suffixIcon={
              <Image
                width="18px"
                height="18px"
                preview={false}
                src={DropdownIcon}
              />
            }
            defaultValue={defaultIndex}>
            {userMenuItems.map((menuItem, i) => {
              return (
                <Option key={i.toString()} value={(i + 1).toString()}>
                  <Link
                    to={{
                      pathname: `${menuItem.path}/${params.employeeKey}`,
                    }}>
                    <B type="b-large-semibold" color={theme.black}>
                      {menuItem.displayName}
                    </B>
                  </Link>
                </Option>
              );
            })}

            <Option value="settings">
              <Link
                to={{
                  pathname: `/contact/settings/${params.employeeKey}`,
                }}>
                <B type="b-large-semibold" color={theme.black}>
                  Settings
                </B>
              </Link>
            </Option>
            <Option value="log-view">
              <Link
                to={{
                  pathname: `/contact/log-view/${params.employeeKey}`,
                }}>
                <B type="b-large-semibold" color={theme.black}>
                  Log Trail Details
                </B>
              </Link>
            </Option>
          </MenuDropdown>
        </div>
      ) : null}
    </>
  );
};

export default PeopleSelect;
