import React, { FC } from 'react';
import { Avatar } from 'antd';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import styled from '@emotion/styled';

import theme from '../theme';
import { getLabelFromTitle } from '../util/getLabelFromTitle';

export const AvatarComp = styled(Avatar)<
  SpaceProps & TypographyProps & BorderProps & ColorProps
>`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${theme.gray300};
  line-height: 1;
  ${space}
  ${typography}
  ${border}
  ${color}
`;

type Props = SpaceProps &
  TypographyProps &
  BorderProps &
  ColorProps & {
    size?: number;
    shape?: 'circle' | 'square';
    title?: string;
    src?: React.ReactNode;
    backgroundColor?: string;
    textColor?: string;
  };
export const AvatarComponent: FC<Props> = ({
  size,
  shape,
  title,
  src,
  backgroundColor=theme.white,
  textColor=theme.black,
  ...otherProps
}) => {
  const label=getLabelFromTitle(title)
  return (
    <>
      <AvatarComp
        src={src}
        size={size}
        shape={shape}
        backgroundColor={backgroundColor}
        textColor={textColor}
        {...otherProps}>
        {label}
      </AvatarComp>
    </>
  );
};
