import styled from '@emotion/styled';
import {
  Button,
  ButtonProps,
  Dropdown,
  Menu,
  message,
  Tag,
  Tooltip,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { debounce, includes, uniqueId } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  useArchiveLoan,
  useGetLoanList,
  useUpdateLoan,
} from '../../../api/loanHooks';
import {
  AvatarComponent,
  B,
  DeleteModal,
  Div,
  InputComp,
  PrimaryButton,
  RadioButton,
  RadioGroup,
  SecondaryButton,
  TableComp,
} from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import {
  FilterTypes,
  LoanI,
  LoanStatus,
  LoanTypeEnum,
  LoanUpdateI,
  UpdateUserLoanI,
} from '../../../types/loan.types';
import { useWindowSize } from '../../../util/screenSize';
import { tableSorter } from '../../../util/tableSorter';
import {
  isHaveThreeDots,
  addDotsForLongText,
  numberThousandSeparator,
} from '../../../util/utils';
import GenerateBankFileModal from '../generatedBankFile/GenerateBankFileModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { DropdownBlack, SearchSVG, LogTrailSVG } from '../../../assets';

import { sortEmpIds } from '../../payroll/utils/sortingUtils';
import LoanGeneratedBankFilesTable from '../generatedBankFile/LoanGeneratedBankFilesTable';
import {
  installmentAmountCal,
  loanInterestAmountCal,
} from '../../../util/loanCalculations';
import { useGetPeople } from '../../../api/peopleHooks';
import dayjs from 'dayjs';
import { ColorObject } from '../../../types/colorObject.types';
import { getRandomColor } from '../../../util/getRandomColor';

const Container = styled.div`
  border: 1px solid ${theme.gray300};
  @media (max-width: 450px) {
    border: none;
  }
  border-radius: 4px;
`;

const Header = styled.div`
  padding: 24px 40px 24px 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

const RightSection = styled.div`
  display: block;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const AllEmpDropdown = styled(Dropdown)`
  margin-right: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;

const DropdownButton = styled(Button)`
  width:50px
  height: 32px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 4px 12px 6px 16px !important;
  border-color: ${theme.gray300} !important;
  
`;

const CustomRadioGroup = styled(RadioGroup)`
  @media only screen and (max-width: 768px) {
    .ant-radio-button-wrapper {
      margin-bottom: 3px !important;
      width: 100%;
      text-align: center;
    }
  }
`;
const TabLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface StyledButtonProps extends ButtonProps {
  textColor?: string;
  position?: 'first' | 'middle' | 'last';
}

const StyledButton = styled(Button)<StyledButtonProps>`
  border: 1px solid ${theme.blue100};
  color: ${(props) => props.textColor || 'inherit'};!important
  height: 30px;
  padding: 0 10px;

  border-radius: ${(props) =>
    props.position === 'first'
      ? '4px 0px 0px 4px'
      : props.position === 'last'
      ? '0px 4px 4px 0px'
      : '0px'};

  :where(.css-dev-only-do-not-override-1drr2mu).ant-btn-default:disabled,
  :where(.css-dev-only-do-not-override-1drr2mu).ant-btn-default.ant-btn-disabled {
    color: ${theme.gray600}!important;
    background:${theme.white}!important;
    border-color: ${theme.blue100} !important;
`;

export const StyledImage = styled.img`
  margin-left: 16px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const menuItems = [
  {
    key: FilterTypes.ALL,
    name: 'All Types',
  },
  {
    key: FilterTypes.ACTIVE,
    name: 'Active',
  },
  {
    key: FilterTypes.DRAFT,
    name: 'Draft',
  },
  {
    key: FilterTypes.SETTLED,
    name: 'Settled',
  },
  {
    key: FilterTypes.ARCHIVED,
    name: 'Archived',
  },
];
const ButtonGroup = Button.Group;

const LoanTable: FC = () => {
  const navigate = useNavigate();
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();
  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.ALL);
  const [dataSource, setDataSource] = useState<LoanI[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<LoanI[]>([]);
  const [checkLoan, setCheckLoan] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedCheckList, setSelectedCheckList] = useState<number[]>([]);
  const [isAllLoansTab, setIsAllLoansTab] = useState(true);

  let prevColor: ColorObject = { bgColor: ' ', textColor: ' ' };

  const {
    isLoading: loadingLoanList,
    data: loanList = [],
    refetch: getloanList,
  } = useGetLoanList();

  const { isSuccess: successArchiveLoan, mutate: archiveLoan } =
    useArchiveLoan();

  const {
    isLoading: loadingUpdateLoan,
    isSuccess: successUpdateLoan,
    mutate: updateLoan,
  } = useUpdateLoan();

  const {
    isLoading: loadingPeopledata,
    data: peopleData,
    mutate: fetchPeople,
  } = useGetPeople();

  useEffect(() => {
    if (successArchiveLoan || successUpdateLoan) {
      getloanList();
    }
  }, [successArchiveLoan, successUpdateLoan]);

  useEffect(() => {
    const list: LoanI[] = [];
    if (!!loanList.length) {
      loanList?.forEach((loan) => {
        const loanTable: LoanI = {
          ...loan,
        };
        if (
          filter === FilterTypes.ACTIVE &&
          loanTable.loanStatus === LoanStatus.ACTIVE
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.DRAFT &&
          loanTable.loanStatus === LoanStatus.DRAFT
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.ARCHIVED &&
          loanTable.loanStatus === LoanStatus.ARCHIVED
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.SETTLED &&
          loanTable.loanStatus === LoanStatus.SETTLED
        ) {
          list.push(loanTable);
        } else if (
          filter === FilterTypes.ALL &&
          loanTable.loanStatus != LoanStatus.ARCHIVED
        ) {
          list.push(loanTable);
        }
      });
      setDataSource(list);
      setFilteredDataSource(list);
    }
  }, [filter, loanList]);

  const searchDataTableCallback = useCallback(
    debounce((searchText) => {
      const text = searchText.trim();
      if (text) {
        const filteredData = dataSource.filter((item) => {
          const fullText =
            item.user.fullName?.toLowerCase() +
            item.user.employeeNumber +
            item.loanId;
          return includes(fullText, text.toLowerCase());
        });
        setFilteredDataSource(filteredData);
      } else {
        setFilteredDataSource(dataSource);
      }
    }, 400),
    [dataSource],
  );

  const clearSelected = () => {
    setSelectedCheckList([]);
    getloanList();
  };

  const ReleaseLoan = async (values: LoanI) => {
    fetchPeople(String(values.userId), {
      onSuccess: async (peopleData) => {
        const loanInterestAmount = loanInterestAmountCal(
          values.loanAmount,
          values.loanInterest,
        );

        const installmentAmount = installmentAmountCal(
          values.loanAmount,
          values.loanPeriod,
          loanInterestAmount,
        );

        const monthlySalary = peopleData?.calculation?.netSalary;

        const isNotValidLoan =
          values.loanType === LoanTypeEnum.DEFAULT
            ? monthlySalary < installmentAmount
            : monthlySalary < values.loanAmount;

        if (isNotValidLoan) {
          message.error(
            'Loan instalment size is larger than the last drawn net salary',
          );
        } else {
          const value: LoanUpdateI = {
            releaseDate: dayjs().format('YYYY-MM-DD'),
            loanAmount: values.loanAmount,
            loanPeriod: values.loanPeriod,
            loanInterest: values.loanInterest ? values.loanInterest : 0,
            maturityDate: values.maturityDate,
            installmentAmount: values.installmentAmount,
            noOfInstallments: values.noOfInstallments,
            loanReleasedToEmployee: true,
            ...(values.guarantorId && { guarantorId: values.guarantorId }),
            ...(values.guarantorName && {
              guarantorName: values.guarantorName,
            }),
            ...(values.guarantorEmail && {
              guarantorEmail: values.guarantorEmail,
            }),
            ...(values.guarantorContactNumber && {
              guarantorContactNumber: values.guarantorContactNumber,
            }),
            ...(values.loanType === LoanTypeEnum.DEFAULT && {
              isInternalGuarantor: values.isInternalGuarantor,
            }),
          };

          if (values.loanId) {
            const dataSet: UpdateUserLoanI = {
              id: values.id,
              data: value,
            };

            await updateLoan(dataSet);
          }
        }
      },
      onError: (error) => {
        message.error('Failed to fetch employee data');
      },
    });
  };

  const columns: ColumnProps<LoanI>[] = [
    {
      title: '',
      width: 30,
      dataIndex: 'check',
      align: 'center',
      render: (_value: string, data) => (
        <CheckBoxComp
          disabled={data.loanStatus === LoanStatus.DRAFT ? false : true}
          onChange={(v) => {
            setCheckLoan(v.target.checked);
            if (v.target.checked) {
              setSelectedCheckList([...selectedCheckList, data.id]);
            } else {
              const checkedList = selectedCheckList;
              const setId = checkedList.findIndex((i) => i === data.id);
              checkedList.splice(setId, 1);
              setSelectedCheckList([...checkedList]);
            }
          }}
        />
      ),
    },
    {
      title: 'Name',
      width: 130,
      dataIndex: 'fullName',
      align: 'left',
      render: (_value: string, data) => {
        const currentColor = getRandomColor(prevColor);
        prevColor = currentColor;
        return (
          <Div display="flex" flexDirection="row">
            <AvatarComponent
              fontSize={12}
              fontWeight={600}
              shape="circle"
              title={`${data.user.fullName}`}
              backgroundColor={currentColor.bgColor}
              textColor={currentColor.textColor}
            />
            <Div display="flex">
              {isHaveThreeDots(data.user.fullName, 25) ? (
                <B type="b-small-semibold" pt="6px" pl="12px">
                  {data.user.fullName ? (
                    <Tooltip placement="topLeft" title={data.user.fullName}>
                      {isDesktopLarge && data.user.fullName
                        ? addDotsForLongText(data.user.fullName, 20)
                        : data.user.fullName
                        ? addDotsForLongText(data.user.fullName, 15)
                        : '-'}
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </B>
              ) : (
                <B type="b-small-semibold" pt="6px" pl="12px" pr="8px">
                  {data.user.fullName ? data.user.fullName : '-'}
                </B>
              )}
            </Div>
          </Div>
        );
      },
      sorter: (a, b) =>
        tableSorter.defaultSort(a.user.fullName, b.user.fullName),
      sortDirections: ['descend', 'ascend'],
      key: 'fullName',
    },
    {
      title: 'Loan Type',
      width: 60,
      dataIndex: 'loanType',
      align: 'left',
      render: (_value: string, data) => {
        const isAdvancePayment = data.loanType === LoanTypeEnum.ADVANCE_PAYMENT;
        const tagColor = isAdvancePayment ? theme.lightBlue50 : theme.green50;
        const textColor = isAdvancePayment ? theme.blue500 : theme.green300;

        return (
          <Tag color={tagColor} bordered={false}>
            <B type="b-small" color={textColor}>
              {data.loanType === LoanTypeEnum.DEFAULT
                ? 'Standard'
                : data.loanType === LoanTypeEnum.ADVANCE_PAYMENT
                ? 'Advanced'
                : data.loanType}
            </B>
          </Tag>
        );
      },
    },
    {
      title: 'Emp ID',
      width: 50,
      dataIndex: 'employeeNumber',

      render: (_value: string, data) => (
        <B type="b-small" style={{ paddingLeft: '8px' }}>
          {data.user.employeeNumber ? data.user.employeeNumber : '-'}
        </B>
      ),
      sorter: (a, b) =>
        sortEmpIds(a.user.employeeNumber, b.user.employeeNumber),
      sortDirections: ['descend', 'ascend'],
      key: 'employeeNumber',
    },
    {
      title: 'Loan ID',
      width: 50,
      dataIndex: 'loanId',

      render: (_value: string, data) => (
        <B type="b-small" style={{ paddingLeft: '8px' }}>{data.loanId ? data.loanId : '-'}</B>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.loanId, b.loanId),
      sortDirections: ['descend', 'ascend'],
      key: 'loanId',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Loan Amount',
      width: 60,
      dataIndex: 'loanAmount',
      align: 'left',
      render: (_value: string, data) => (
        <B type="b-small" style={{ paddingLeft: '7px' }}>
          {data.loanAmount ? numberThousandSeparator(data.loanAmount) : 'empty'}
        </B>
      ),
    },
    {
      title: 'Settled Amount',
      width: 60,
      dataIndex: 'settledAmount',
      align: 'left',
      render: (_value: string, data) => (
        <B type="b-small" style={{ paddingLeft: '8px' }}>
          {data.paidAmount ? numberThousandSeparator(data.paidAmount) : 'empty'}
        </B>
      ),
    },
    {
      title: 'Outstanding Balance',
      width: 60,
      dataIndex: 'outstandingBalance',
      align: 'left',
      render: (_value: string, data) => (
        <B type="b-small" style={{ paddingLeft: '8px' }}>
          {data.balanceAmount
            ? numberThousandSeparator(data.balanceAmount)
            : 'empty'}
        </B>
      ),
    },
    {
      title: 'Status',
      width: 50,
      dataIndex: 'loanStatus',
     
      render: (_value: string, data) => {
        let tagColor;
        let textColor;

        if (data.loanStatus === LoanStatus.ACTIVE) {
          tagColor = theme.green50;
          textColor = theme.green300;
        } else if (data.loanStatus === LoanStatus.SETTLED) {
          tagColor = theme.lightBlue50;
          textColor = theme.blue500;
        } else if (data.loanStatus === LoanStatus.DRAFT) {
          tagColor = theme.warn;
          textColor = theme.orange300;
        } else if (data.loanStatus === LoanStatus.ARCHIVED) {
          tagColor = theme.gray100;
          textColor = theme.gray600;
        }
        return (
          <Tag color={tagColor} bordered={false} >
            <B type="b-small" color={textColor}>
              {data.loanStatus
                ? data.loanStatus.charAt(0).toUpperCase() +
                  data.loanStatus.substr(1).toLowerCase()
                : '-'}
            </B>
          </Tag>
        );
      },
    },
    {
      title: '',
      align: 'center',
      dataIndex: 'viewMore',
      key: uniqueId(),
      width: 100,
      render: (_value: string, data) => (
        <ButtonGroup>
          <Link
            to={{
              pathname: `/loan/${data.id}`,
            }}
            state={{ employee: data }}>
            <StyledButton textColor={theme.blue500} position="first">
              View
            </StyledButton>
          </Link>

          <StyledButton
            position="middle"
            disabled={data.loanStatus === LoanStatus.DRAFT ? false : true}
            onClick={() => ReleaseLoan(data)}>
            Release
          </StyledButton>

          <DeleteModal
            title="Archive Loan"
            buttonLabel="Yes, Archive!"
            content="Are you sure you want to archive this loan?"
            onClickCancel={() => {
              archiveLoan({
                id: data.id,
              });
            }}
            openModal={
              <StyledButton
                position="last"
                disabled={
                  data.loanStatus === LoanStatus.ARCHIVED ? true : false
                }>
                Archive
              </StyledButton>
            }></DeleteModal>
        </ButtonGroup>
      ),
    },
  ];

  const filterMenu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item onClick={() => setFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  return (
    <>
      <Header>
        <LeftSection>
          <CustomRadioGroup defaultValue="allLeave">
            <RadioButton
              value="allLoans"
              onClick={() => setIsAllLoansTab(true)}>
              <TabLabel>All Loans</TabLabel>
            </RadioButton>
            <RadioButton
              value="generatedBankFiles"
              onClick={() => setIsAllLoansTab(false)}>
              <TabLabel>Generated Bank Files</TabLabel>
            </RadioButton>
          </CustomRadioGroup>
        </LeftSection>
        <RightSection>
          {isAllLoansTab && (
            <AllEmpDropdown overlay={filterMenu} trigger={['click']}>
              <DropdownButton>
                <B display="flex">
                  {menuItems.find((item) => item.key === filter)?.name}(
                  {dataSource.length})
                  <Div pl="10px" margin-left="8px">
                    <img src={DropdownBlack} />
                  </Div>
                </B>
              </DropdownButton>
            </AllEmpDropdown>
          )}
          <InputComp
            size="small"
            onChange={(e) => {
              searchDataTableCallback(e.target.value);
            }}
            placeholder="Search"
            prefix={<img src={SearchSVG} />}
          />
          <SecondaryButton
            size="small"
            ml={16}
            mr={16}
            onClick={() => setVisible(true)}
            disabled={checkLoan ? false : true}>
            Generate Bulk File
          </SecondaryButton>
          <PrimaryButton size="small" onClick={() => navigate('/loan/create')}>
            Add New Loan
          </PrimaryButton>
          <Tooltip title="View Log Trail" placement="bottomRight">
            <StyledImage
              className="ant-dropdown-link"
              onClick={() => navigate('/loan/log-trail')}
              src={LogTrailSVG}
            />
          </Tooltip>
        </RightSection>
      </Header>
      <Container>
        {isAllLoansTab ? (
          <TableComp
            rowKey={(item) => {
              return String(item.id);
            }}
            rowClassName={(row) => {
              return row.id === scrollTarget ? 'yellow-highlight' : '';
            }}
            pagination={false}
            loading={loadingLoanList}
            columns={columns}
            size="large"
            dataSource={filteredDataSource}
            scroll={{
              x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
              y: window.innerHeight - 300,
            }}
          />
        ) : (
          <LoanGeneratedBankFilesTable />
        )}
      </Container>
      {visible && (
        <GenerateBankFileModal
          visible={visible}
          handleCancel={() => setVisible(false)}
          clearSelected={() => clearSelected()}
          selected={selectedCheckList}
          dataSet={loanList}
        />
      )}
    </>
  );
};

export default LoanTable;
