import React, { FC, useEffect, useState } from 'react';
import { Form, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useRequestLeaveAsAdmin } from '../../../api/leaveHooks';
import { AlertMessage, B, InputComp, SelectComp } from '../../../components';
import { Div } from '../../../components/Div';
import { ModalComponent } from '../../../components/ModalComponent';
import { LEAVE_LIST, LEAVE_NAMES } from '../../../configs';
import {
  getAllOrganizationLeaves,
  getLeaveBalanceByUser,
  getMyLeaveRequests,
  getMyLeaves,
  getOrganizationLeaveRequests,
  leavesBalanceMe,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  LeaveNameEnum,
  LeaveTypesEnum,
  Permission,
  RequestLeaveAdminI,
} from '../../../types';
import { PeopleI } from '../../../types/people.types';
import { getApiError } from '../../../util/getApiError';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import LeaveDetails from '../shared/LeaveDetails';

interface Props {
  allPeoples: PeopleI[];
  onClose: () => void;
  id?: number;
}

const LeaveBalanceForm = styled.div`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

const ManageLeaveBalanceModal: FC<Props> = ({ allPeoples, onClose, id }) => {
  const dispatch: DispatchType = useDispatch();
  const [visible, setVisible] = useState(true);
  const [isLeaveName, setIsLeaveName] = useState(false);
  const [form] = Form.useForm();

  const [userId, setUserId] = useState<null | number>(id ? id : null);

  const leavesBalanceUserLoading = useSelector(
    (state: RootState) => state.leave.leavesBalanceUserLoading,
  );
  const me = useSelector((state: RootState) => state.people.me);
  const leaveBalanceUser = useSelector(
    (state: RootState) => state.leave.leavesBalanceUser,
  );

  const {
    isLoading: requestLoadingAsAdmin,
    error: requestErrorAsAdmin,
    data: requestDataAsAdmin,
    mutate: executeAdminPost,
  } = useRequestLeaveAsAdmin();

  useEffect(() => {
    if (requestDataAsAdmin && !requestErrorAsAdmin) {
      dispatch(leavesBalanceMe());
      dispatch(getAllOrganizationLeaves());
      dispatch(getMyLeaves());
      dispatch(getOrganizationLeaveRequests());
      dispatch(getMyLeaveRequests());
    }
  }, [requestDataAsAdmin]);

  useEffect(() => {
    if (userId) {
      dispatch(getLeaveBalanceByUser(userId.toString()));
    }
  }, [userId]);

  useEffect(() => {
    if (!visible) {
      onClose();
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onSubmit = (data: RequestLeaveAdminI) => {
    const value: RequestLeaveAdminI = {
      ...data,
      userId: String(data.userId),
      type: 'ADD',
      hours: Number(data.hours),
      leaves: [],
    };
    executeAdminPost(value, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const allPeoplesOptions = allPeoples
    .filter(
      (item) =>
        (isManager() && item.id !== me?.id) ||
        isAllowed(Permission.CREATE_EDIT_LEAVES_USER),
    )
    .map((item) => {
      return {
        value: item.id,
        label: item.fullName,
      };
    });

  // to add labels and values of the custom leave types as an object
  const customLeaveTypeList =
    leaveBalanceUser?.filter(
      (items) =>
        items.leaveType === LeaveTypesEnum.CUSTOM && items.totalLeave > 0,
    ) || [];
  const CUSTOM_LEAVES_LIST = customLeaveTypeList?.map((customLeaves) => ({
    label: customLeaves.leaveName,
    value: customLeaves.leaveName,
  }));

  const mergedOptions = [
    ...LEAVE_LIST.filter((item) => item.value !== LeaveNameEnum.NO_PAY),
    ...CUSTOM_LEAVES_LIST,
  ];

  return (
    <ModalComponent
      form={form}
      onCancel={handleCancel}
      submitText="Add Leave"
      loading={requestLoadingAsAdmin}
      centered
      width={585}
      visible={visible}
      title={
        <B type="b-large-semibold" color={theme.black}>
          Manage Leave Balance
        </B>
      }>
      <LeaveBalanceForm>
        <Form
          form={form}
          initialValues={{
            userId: userId,
          }}
          layout="vertical"
          name="requestLeave"
          onFinish={onSubmit}>
          {requestErrorAsAdmin && (
            <Div mb="12px">
              <AlertMessage
                title={getApiError(requestErrorAsAdmin)}
                type="error"
              />
            </Div>
          )}
          <Form.Item
            name="userId"
            rules={[
              {
                required: true,
                message: 'Please select your Employee Name!',
              },
            ]}>
            <SelectComp
              size="middle"
              label="Employee Name"
              value={userId as SelectValue | undefined}
              showSearch
              placeholder="Select a employee name"
              optionFilterProp="label"
              onChange={(v) => setUserId(v as number)}
              options={allPeoplesOptions}
            />
          </Form.Item>
          <Spin spinning={leavesBalanceUserLoading}>
            <LeaveDetails leaveSummery={leaveBalanceUser} />
          </Spin>
          <Form.Item
            name="leaveType"
            rules={[
              {
                required: true,
                message: 'Please select your leave type',
              },
            ]}>
            <SelectComp
              size="middle"
              label="Leave Type"
              placeholder="Please Select leave type"
              options={mergedOptions}
              onSelect={(value) => {
                if (value == LeaveTypesEnum.OTHER) {
                  setIsLeaveName(true);
                } else {
                  setIsLeaveName(false);
                }
              }}
            />
          </Form.Item>

          {isLeaveName && (
            <Form.Item
              name="leaveName"
              rules={[
                {
                  required: true,
                  message: 'Please select your leave Name',
                },
              ]}>
              <SelectComp
                size="middle"
                label="Leave Name"
                placeholder="Please Select leave name"
                options={LEAVE_NAMES}
              />
            </Form.Item>
          )}
          <Form.Item
            name="hours"
            rules={[
              {
                required: true,
                message: 'Number of hours are required',
              },
            ]}>
            <InputComp size="small" type="number" label="Hours" />
          </Form.Item>

          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: 'Please enter a reason!',
              },
            ]}>
            <InputComp
              size="small"
              label="Reason"
              placeholder="Reason for leave"
            />
          </Form.Item>
        </Form>
      </LeaveBalanceForm>
    </ModalComponent>
  );
};

export default ManageLeaveBalanceModal;
