import React, { FC, useEffect, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useParams } from 'react-router';

import styled from '@emotion/styled';

import { useGetPayslips } from '../../../../api/peopleHooks';
import { B, TableComp } from '../../../../components';
import { DownloadBlack } from '../../../../assets';
import { UserHistoryI } from '../../../../types/people.types';

const MainContainer = styled.div`
  padding: 24px 32px 8px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const LastColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export interface PayslipTableI {
  name: string | null | undefined;
  dateRange: string | null | undefined;
  payslipUrl: string | null | undefined;
}

type ParamsI = {
  employeeKey: string;
};
interface PayslipsProps {
  latestJob: UserHistoryI;
}

const Payslips: FC<PayslipsProps> = ({ latestJob }) => {
  const [item, setItem] = useState<PayslipTableI[]>([]);
  const params = useParams<ParamsI>();
  const { data: getPayslipList, mutate: getPayslips } = useGetPayslips();

  useEffect(() => {
    getPayslips(Number(params.employeeKey));
  }, [params.employeeKey]);

  useEffect(() => {
    if (getPayslipList) {
      const final: PayslipTableI[] = getPayslipList.empPayroll.map((item) => {
        const startDate = moment(item.payroll?.payrollStartDate).format(
          'DD.MM.YYYY',
        );
        const endDate = moment(item.payroll?.payrollEndDate).format(
          'DD.MM.YYYY',
        );
        return {
          name: item.payroll?.name,
          dateRange: `${startDate} - ${endDate}`,
          payslipUrl: item.payslipUrl,
        };
      });
      setItem(final);
    }
  }, [getPayslipList]);

  const columns: ColumnProps<PayslipTableI>[] = [
    {
      title: 'Month',
      dataIndex: 'name',
      width: 150,
      render: (value) => <B type="b-small">{value ? value : '-'}</B>,
    },
    {
      title: 'Date Range',
      dataIndex: 'dateRange',
      render: (value) => <B type="b-small">{value}</B>,
    },
    {
      title: '',
      fixed: 'right',
      width: 70,
      dataIndex: 'payslipUrl',
      render: (value: string) => (
        <>
          <LastColumn>
            <a href={value} download>
              <img src={DownloadBlack} />
            </a>
          </LastColumn>
        </>
      ),
    },
  ];

  return (
    <MainContainer>
      <B type="b-default-semibold" Pb="16px">
        {latestJob.employmentType === 'CONTRACT'
          ? 'Download Invoices'
          : 'Download Payslips'}
      </B>
      <TableComp
        size="middle"
        withBorders={true}
        columns={columns}
        dataSource={item}
        pagination={false}
      />
    </MainContainer>
  );
};

export default Payslips;
