import { Button, Dropdown, Checkbox, Menu } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getMinimumUserInfo } from '../../store/actions';
import { DispatchType, RootState } from '../../store/reducer';
import { B } from '../B';
import styled from '@emotion/styled';
import { CheckBoxComp } from '../v2/CheckBoxComp';
import { PeopleI } from '../../types/people.types';
import { Div } from '../Div';
import theme from '../../theme';
import { addDotsForLongText } from '../../util/utils';

export const MenuComp = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  padding: 0px !important;
`;
export const MenuStyle = styled.div`
  overflow-y: scroll;
  max-height: 350px;
`;
export const MenuItem = styled.div`
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 14px;
  margin-bottom: 0px !important;
`;
export const MenuItemClear = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;
export const DropdownComp = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 14px;
  padding: 0px 8px;
`;
const DropdownButton = styled(Button)<{ width?: number; employees: number[] }>`
  width: ${(Props) =>
    Props.width
      ? `${Props.width}px`
      : Props.employees.length === 0
      ? '179px'
      : '188px'};
  height: 32px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  :hover,
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.gray300} !important;
  }
`;
const DownOutlinedStyle = styled(DownOutlined)<{ width?: number }>`
  margin-left: ${(Props) => (Props.width ? '150px' : '17px')} !important;
  .anticon .anticon-down .css-d8vyo0-emotion--ReportSelector--ReportSelector {
    margin-left: 37px !important;
  }
`;
interface Props {
  onChange: (value: number[]) => void;
  width?: number;
  isActive: boolean;
  employees?: number[];
  className?: string;
}

const EmployeesSelector: FC<Props> = ({
  onChange,
  width,
  isActive = false,
  employees,
}) => {
  const dispatch: DispatchType = useDispatch();
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const [allEmployee, setAllEmployee] = useState<PeopleI[]>([]);
  const [uniqueEmps, setUniqueEmps] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getMinimumUserInfo());
  }, []);

  useEffect(() => {
    if (employees) {
      const uniqueValues: Set<number> = new Set();
      employees.forEach((emp) => uniqueValues.add(emp));
      setUniqueEmps(Array.from(uniqueValues));
      onChange(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (isActive) {
      onCheckAllEmployees(
        allPeoples.filter((activeUser) => activeUser.isArchived === false),
      );
      setAllEmployee(
        allPeoples
          .filter((activeUser) => activeUser.isArchived === false)
          .sort((a, b) => a.fullName?.localeCompare(b.fullName)),
      );
    } else {
      onCheckAllEmployees(allPeoples);
      setAllEmployee(
        allPeoples.sort((a, b) => a.fullName?.localeCompare(b.fullName)),
      );
    }
  }, [allPeoples, isActive]);

  const onCheckAllEmployees = (allPeople: PeopleI[]) => {
    const allEmployee: number[] = [];
    allPeople
      .filter((activeUser) => activeUser.isArchived === false)
      .map((item) => {
        return allEmployee.push(item.id);
      });
    onChange(allEmployee);
  };

  const allEmployeesMenu = (
    <Checkbox.Group value={employees} onChange={(e) => onChange(e as number[])}>
      <MenuComp>
        <MenuStyle>
          {allEmployee.map((item) => {
            return (
              <MenuItem onClick={(e) => e.stopPropagation()} key={item.id}>
                <CheckBoxComp key={item.id} value={item.id}>
                  {addDotsForLongText(item.fullName, 15)}(
                  {item.employeeNumber ? item.employeeNumber : '-'})
                  {item.isArchived && '(Archived)'}
                </CheckBoxComp>
              </MenuItem>
            );
          })}
        </MenuStyle>
        <MenuItemClear key={1} onClick={(e) => e.stopPropagation()}>
          <B
            type="b-default"
            color={theme.gray600}
            onClick={() => {
              if (isActive) {
                onCheckAllEmployees(
                  allPeoples.filter(
                    (activeUser) => activeUser.isArchived === false,
                  ),
                );
              } else {
                onCheckAllEmployees(allPeoples);
              }
            }}>
            Select All
          </B>
          <Div ml="auto">
            <B
              type="b-default"
              color={theme.gray600}
              onClick={() => onChange([])}>
              Clear
            </B>
          </Div>
        </MenuItemClear>
      </MenuComp>
    </Checkbox.Group>
  );

  return (
    <>
      <Dropdown overlay={allEmployeesMenu}>
        <DropdownButton width={width} employees={employees ? employees : []}>
          All Employees
          <DropdownComp>
            <B type="b-small">{uniqueEmps?.length}</B>
          </DropdownComp>
          <DownOutlinedStyle width={width} />
        </DropdownButton>
      </Dropdown>
    </>
  );
};

export default EmployeesSelector;
