import React, { FC, ReactNode } from 'react';
import { Tag } from 'antd';
import theme from '../../../theme';
import { IExpenseStatus } from '../../../types/expense.types';
import { LeaveStatus } from '../../home/homeUtil';
import { AttendanceFilterTypes } from '../../../types/attendance.types';
import styled from '@emotion/styled';

interface Props {
  children?: ReactNode;
  status: string;
  count?: number;
}

type tagProps = {
  backgroundColor: string;
  textColor: string;
};

const Tags = styled(Tag)<tagProps>`
  color: ${(tagProps) => tagProps.textColor} !important;
  background-color: ${(tagProps) => tagProps.backgroundColor} !important;
  border: none !important;
  filter: contrast(100%) !important;
  margin: 4px;
  font-family: Inter;
`;

export const LeaveStatusTags: FC<Props> = ({ status, count }) => {
  {
    switch (status) {
      case IExpenseStatus.PENDING:
      case LeaveStatus.PENDING:
        return (
          <Tags backgroundColor="#EBF1FD" textColor="#0052EA">
            {count ? count : 'Pending'}
          </Tags>
        );
      case AttendanceFilterTypes.APPROVED:
      case IExpenseStatus.APPROVED:
      case LeaveStatus.ACCEPTED:
        return (
          <Tags backgroundColor={theme.green50} textColor={theme.green300}>
            {count ? count : 'Approved'}
          </Tags>
        );
      case IExpenseStatus.REJECTED:
      case LeaveStatus.REJECTED:
        return (
          <Tags backgroundColor={theme.red50} textColor={theme.red300}>
            {count ? count : 'Rejected'}
          </Tags>
        );
      default:
        return null;
    }
  }
};
