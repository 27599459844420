import React, { FC, ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Col, Form, Popover, Row, Select } from 'antd';
import { useNavigate } from 'react-router';
import {
  B,
  FooterComponent,
  InputComp,
  InputNumberComp,
  PrimaryButton,
  RouteLeavingGuard,
  CustomDivider,
  InputV2,
  SelectComp,
  LinkButton,
  SwitchComponent,
  Div,
  Image,
} from '../../../../components';
import styled from '@emotion/styled';
import { useGetBankList } from '../../../../api/bankHooks';
import { getBranches } from '../../../../util/utils';
import theme from '../../../../theme';
import {
  BankDetailI,
  BankDetailIForm,
  UserHistoryI,
} from '../../../../types/people.types';
import { Validation } from '../../../../common/validation';
import { InfoSVG } from '../../../../assets';
import { useParams } from 'react-router-dom';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import { getLatestJob } from '../../PeopleUtil';

const { Option } = Select;
type ParamsI = {
  employeeKey: string;
};

const CustomForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-form-item-label {
    padding: 0 0 4px;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
  width: 344px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const SecondaryAccount = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    display: grid;
  }
`;
interface Props {
  bankDetail: BankDetailI;
  onSubmit: (updatedBankDetails: BankDetailIForm) => void;
  footer?: ReactNode;
  loading?: boolean;
}

export const MultiBankDetailForm: FC<Props> = ({
  bankDetail,
  onSubmit,
  footer,
  loading,
}) => {
  const [form] = Form.useForm();
  const params = useParams<ParamsI>();
  const [hasSecondaryBank, setHasSecondaryBank] = useState(false);
  const [latestJob, setLatestJob] = useState<UserHistoryI>({
    positionLevel: null,
    employmentType: null,
    branchName: null,
    manager: null,
    managerId: null,
    designation: null,
    startDate: null,
    endDate: null,
    isCurrentJob: false,
  });
  const navigate = useNavigate();
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const [primaryBankCode, setPrimaryBankCode] = useState('');
  const [secondaryBankCode, setSecondaryBankCode] = useState('');
  const { data: banks = [] } = useGetBankList();

  const bankOptions = banks.map((item) => ({
    value: item.bankCode,
    label: item.bankName,
  }));
  const {
    state: { people, isLoading, error },
    fetchPeopleById,
  } = useIndividualPeopleState();

  const fetchPeople = () => {
    fetchPeopleById(params.employeeKey);
  };

  const [isEmployeePaidInCash, setIsEmployeePaidInCash] = useState(
    bankDetail.isEmployeePaidInCash,
  );

  const handleSwitchChange = (checked: boolean) => {
    setIsEmployeePaidInCash(checked);
  };

  useEffect(() => {
    fetchPeople();
  }, []);

  useEffect(() => {
    if (people && people.userHistory) {
      setLatestJob(getLatestJob(people.userHistory));
    }
  }, [people]);

  useEffect(() => {
    if (bankDetail && banks.length) {
      form.setFieldsValue({
        bankAccountName: bankDetail.bankAccountName,
        bankAccountNumber: bankDetail.bankAccountNumber,
        bankBankCode: bankDetail.bankBankCode,
        bankBranchId: bankDetail.bankBranchId,
        isEmployeePaidInCash: bankDetail.isEmployeePaidInCash,
        primaryAccountAmount: bankDetail.primaryAccountAmount,
        secondaryBankAccountName: bankDetail.secondaryBankAccountName,
        secondaryBankAccountNumber: bankDetail.secondaryBankAccountNumber,
        secondaryBankBankCode: bankDetail.secondaryBankBankCode,
        secondaryBankBranchId: getBranchId(
          bankDetail.secondaryBankBankCode,
          bankDetail.secondaryBankBranchCode,
        ),
        paySlipMail: bankDetail.paySlipMail,
      });
      setPrimaryBankCode(bankDetail.bankBankCode || '');
      setSecondaryBankCode(bankDetail.secondaryBankBankCode || '');

      if (bankDetail.primaryAccountAmount) {
        setHasSecondaryBank(true);
      }
      setIsEmployeePaidInCash(bankDetail.isEmployeePaidInCash);
    }
  }, [bankDetail, banks]);

  const getBranchId = (
    bankCode: string | null,
    branchCode: string | null,
  ): string => {
    if (bankCode && branchCode) {
      const branches = getBranches(bankCode, banks);
      const found = branches.find((item) => item.code == branchCode);
      if (found) {
        return found.value;
      }
    }

    return '';
  };

  const onFinish = (FormValues: BankDetailIForm) => {
    let values: BankDetailIForm = {
      ...FormValues,
      bankBranchId: String(FormValues.bankBranchId),
      secondaryBankBranchId: String(FormValues.secondaryBankBranchId),
      primaryAccountAmount: String(FormValues.primaryAccountAmount),
    };
    if (!hasSecondaryBank) {
      values = {
        ...values,
        primaryAccountAmount: null,
        secondaryBankAccountName: null,
        secondaryBankAccountNumber: null,
        secondaryBankBankCode: null,
        secondaryBankBranchId: null,
      };
    }

    setIsFieldDataChanged(false);
    onSubmit(values);
    if (people?.isPending) {
      navigate(`/contact/loan-details/${params.employeeKey}`);
    }
  };

  const onValuesChange = () => {
    setIsFieldDataChanged(true);
  };
  function shouldBlockNavigation() {
    return isFieldDataChanged;
  }

  return (
    <>
      <RouteLeavingGuard
        when={isFieldDataChanged}
        navigate={(path) => navigate(path)}
        shouldBlockNavigation={shouldBlockNavigation}
      />
      <CustomForm
        layout="vertical"
        form={form}
        name="account_details"
        onValuesChange={onValuesChange}
        onFinish={(v) => onFinish(v as BankDetailIForm)}
        validateTrigger="onBlur">
        <Row>
          <Form.Item name="isEmployeePaidInCash" valuePropName="checked">
            <SwitchComponent
              defaultChecked={isEmployeePaidInCash ? true : false}
              onChange={handleSwitchChange}
            />
          </Form.Item>
          <B type="b-default" color={theme.gray600} pl="16px" pt="6px">
            Employee Paid In Cash
          </B>
        </Row>
        {!isEmployeePaidInCash && (
          <>
            <Row>
              <Col flex="auto">
                <B type="b-default-semibold" pb="4px">
                  {' '}
                  Primary Account
                </B>
                <B type="b-default" pb="15px" color="#757575">
                  The bank account below will be considered the primary account
                  to credit employee&apos;s salary.
                </B>
              </Col>
            </Row>

            <Div
              border=" 1px solid #e0e0e0"
              padding="24px 24px 6px 24px"
              borderRadius="0px 4px 4px 0px">
              <Row gutter={[32, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="bankAccountName"
                    label=" Account Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your bank account name!',
                      },
                      {
                        max: 24,
                        message: 'max characters 24.',
                      },
                    ]}>
                    <InputComp placeholder="John Doe" size="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="bankAccountNumber"
                    label=" Account Number"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your bank account number!',
                      },
                      {
                        pattern: Validation.AccountNumberPattern,
                        message: 'The input is not valid bank account number!',
                      },
                    ]}>
                    <InputComp placeholder="823475215723" size="small" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[32, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="bankBankCode"
                    label="Bank"
                    rules={[
                      {
                        required: true,
                        message: 'Please select your bank name!',
                      },
                    ]}>
                    <SelectComp
                      showSearch
                      size="middle"
                      placeholder="Select a bank name"
                      optionFilterProp="children"
                      onChange={(e) => {
                        setPrimaryBankCode(String(e));
                        form.setFieldsValue({ bankBranchId: undefined });
                      }}>
                      {bankOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.value} - {option.label}
                        </Option>
                      ))}
                    </SelectComp>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="bankBranchId"
                    label=" Branch"
                    rules={[
                      {
                        required: true,
                        message: 'Please select your branch name!',
                      },
                    ]}>
                    <SelectComp
                      showSearch
                      size="middle"
                      placeholder="Select a branch name"
                      optionFilterProp="children">
                      {getBranches(primaryBankCode, banks).map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.code} - {option.label}
                        </Option>
                      ))}
                    </SelectComp>
                  </Form.Item>
                </Col>
              </Row>
            </Div>
          </>
        )}
        {!isEmployeePaidInCash && (
          <>
            {!hasSecondaryBank ? (
              <Div marginTop="24px">
                <LinkButton onClick={() => setHasSecondaryBank(true)}>
                  Add a Secondary Account
                </LinkButton>
              </Div>
            ) : (
              <>
                <SecondaryAccount>
                  <div>
                    <B type="b-default-semibold" pb="4px">
                      {' '}
                      Secondary Account
                    </B>
                    <B
                      type="b-default"
                      color="#757575"
                      pb="15px"
                      css={css`
                        @media (max-width: 768px) {
                          padding-bottom: 0px;
                        }
                      `}>
                      The bank account below will be considered the secondary
                      account to credit employee&apos;s salary, allowing you to
                      split the salary.
                    </B>
                  </div>
                  {hasSecondaryBank ? (
                    <Div
                      css={css`
                        margin-left: auto;
                        margin-top: 25px;
                      `}>
                      <LinkButton
                        onClick={() => setHasSecondaryBank(false)}
                        pb="12px">
                        Remove
                      </LinkButton>
                    </Div>
                  ) : null}
                </SecondaryAccount>

                <Div
                  border="1px solid #e0e0e0"
                  padding="24px 24px 6px 24px"
                  borderRadius="4px">
                  <Row gutter={[32, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="secondaryBankAccountName"
                        label="Account Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your bank account name!',
                          },
                          {
                            max: 24,
                            message: 'max characters 24.',
                          },
                        ]}>
                        <InputComp placeholder="John Doe" size="small" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="secondaryBankAccountNumber"
                        label="Account Number"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your bank account number!',
                          },
                          {
                            pattern: Validation.AccountNumberPattern,
                            message:
                              'The input is not valid bank account number!',
                          },
                        ]}>
                        <InputComp placeholder="823475215723" size="small" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[32, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        label="Bank"
                        name="secondaryBankBankCode"
                        rules={[
                          {
                            required: true,
                            message: 'Please select your bank name!',
                          },
                        ]}>
                        <SelectComp
                          showSearch
                          size="middle"
                          placeholder="Select a bank name"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setSecondaryBankCode(String(e));
                            form.setFieldsValue({
                              secondaryBankBranchId: undefined,
                            });
                          }}>
                          {bankOptions.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label} - {option.value}
                            </Option>
                          ))}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="secondaryBankBranchId"
                        label="Branch"
                        rules={[
                          {
                            required: true,
                            message: 'Please select your branch name!',
                          },
                        ]}>
                        <SelectComp
                          showSearch
                          size="middle"
                          placeholder="Select a branch name"
                          optionFilterProp="children">
                          {getBranches(secondaryBankCode, banks).map(
                            (option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label} - {option.code}
                              </Option>
                            ),
                          )}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                  </Row>
                </Div>
                <Row>
                  <Col flex="auto">
                    <B type="b-default-semibold" pt="24px" pb="4px">
                      Split Salary
                    </B>
                    <B type="b-default" pb="15px" color="#757575">
                      You can enter the salary amount you would like to split
                      below.
                    </B>
                  </Col>
                </Row>

                <Div
                  border=" 1px solid #e0e0e0"
                  padding="24px 24px 6px 24px"
                  borderRadius="4px"
                  marginBottom="24px">
                  <Row gutter={[32, 0]}>
                    <Col xs={21} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="primaryAccountAmount"
                        label="Primary Account Amount"
                        rules={[
                          {
                            required: true,
                            message: 'Enter amount',
                          },
                        ]}>
                        <InputNumberComp
                          placeholder="Ex: 40,000"
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      {' '}
                      <Popover
                        content="The balance will be deposited to the Secondary Account "
                        trigger="hover"
                        css={css`
                          .ant-popover-inner {
                            background: ${theme.blue900};
                            border: 1px solid ${theme.black} !important;
                            box-sizing: border-box !important;
                            border-radius: 8px !important;
                            padding: 0px !important;
                          }
                        `}>
                        <Image
                          src={InfoSVG}
                          marginLeft="-20px"
                          width="20px"
                          height="20px"
                          marginTop=" 34px"
                        />
                      </Popover>{' '}
                    </Col>
                  </Row>{' '}
                  <B type="b-default" pb="15px" color={theme.gray600}>
                    The balance will be deposited to the Secondary Account
                  </B>
                </Div>
              </>
            )}
            <CustomDivider
              mt="26px"
              mb="32px"
              border="0px solid"
              borderBottom={`1px solid ${theme.gray300}`}
            />
          </>
        )}
        <div>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {/* <LabeledInputV2
                flexDirection="column"> */}
              <FormItem
                label={
                  latestJob.employmentType === 'CONTRACT'
                    ? 'Email address for invoice send off'
                    : 'Email address for payslip send off'
                }
                name="paySlipMail"
                rules={[
                  {
                    type: 'email',
                    message: 'Enter a valid email',
                  },
                ]}>
                <InputV2
                  placeholder="Enter Email address for payslip "
                  size="middle"
                />
              </FormItem>
              {/* </LabeledInputV2> */}
            </Col>
          </Row>
        </div>
        {footer ? (
          footer
        ) : (
          <FooterComponent
            rightChildren={
              <PrimaryButton
                ml={16}
                loading={loading}
                disabled={loading}
                htmlType="submit">
                {people?.isPending ? 'Next' : 'Save'}
              </PrimaryButton>
            }
          />
        )}
      </CustomForm>
    </>
  );
};
