import { LeaveStatus } from '../containers/home/homeUtil';
import { RoleI } from './';

export interface LeaveViewI {
  date: string;
  description: string | null;
  alreadyTaken:string | null;
  hours: number;
  isHoliday: boolean;
  fromTime: string;
  toTime: string;
}
export interface LeaveHoursI {
  annualLeaves: number;
  casualLeaves: number;
  sickLeaves: number;
}

export interface LeaveBalance {
  balance: number;
  leaveName: string;
  leaveType: string;
  totalLeave: number;
  extraLeaves: number;
}

export interface LeaveNameOption {
  value: string;
  label: string;
  disabled?: boolean;
}
export interface LeaveNameI {
  annualLeaves: number;
  sickLeaves: number;
  casualLeaves: number;
  noPay: number;
}

export interface WorkingHourI {
  inString: string | null;
  inNumber: number;
}

export let HOURS_PER_DAY = 8;

export function initializeHoursPerDay(value: number) {
  HOURS_PER_DAY = value;
}

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'HH:mm';

export enum LeaveTypesEnum {
  OTHER = 'OTHER',
  FIXED = 'FIXED',
  ACCRUAL = 'ACCRUAL',
  EARNED_LEAVES = 'EARNED_LEAVES',
  CUSTOM = 'CUSTOM',
}
export enum LeaveTypeEnum {
  DEDUCT = 'DEDUCT',
  ADD = 'ADD',
}

export interface LeaveBalancesI {
  fullName: string;
  id: number;
  employeeNumber: string;
  employeeType?: string;
  leaveBalances: EmployeeLeaveBalancesI[];
}

export enum LeaveNameEnum {
  ANNUAL_LEAVE = 'ANNUAL_LEAVE',
  SICK_LEAVE = 'SICK_LEAVE',
  CASUAL_LEAVE = 'CASUAL_LEAVE',
  NO_PAY = 'NO_PAY',
  STUDY_LEAVE = 'STUDY_LEAVE',
  TRAINING_LEAVE = 'TRAINING_LEAVE',
  WORK_ON_HOLIDAY = 'WORK_ON_HOLIDAY',
  MATERNITY_LEAVE = 'MATERNITY_LEAVE',
  LIEU_LEAVE = 'LIEU_LEAVE',
}

export enum CustomLeaveNameEnum {
  NO_PAY = 'NO_PAY',
  STUDY_LEAVE = 'STUDY_LEAVE',
  TRAINING_LEAVE = 'TRAINING_LEAVE',
  WORK_ON_HOLIDAY = 'WORK_ON_HOLIDAY',
  MATERNITY_LEAVE = 'MATERNITY_LEAVE',
  LIEU_LEAVE = 'LIEU_LEAVE',
}

export interface EmployeeLeaveBalancesI {
  balance: number;
  extraLeaves: number;
  leaveName: LeaveNameEnum;
  leaveType: LeaveTypesEnum;
  taken: number;
  totalLeave: number;
}
export interface RequestLeaveI {
  userId?: string;
  type?: string | null;
  hours?: number;
  leaveType: 'ANNUAL_LEAVE' | 'CASUAL_LEAVES' | 'SICK LEAVE';
  leaveName?: CustomLeaveNameEnum;
  reason: string | null;
  leaves?: LeavesI[];
}

interface LeavesI {
  date: string;
  to?: string;
  from?: string;
  hours: number;
}

export interface RequestLeaveAdminI {
  userId: string;
  type: string | null;
  hours: number;
  leaveType: 'ANNUAL_LEAVE' | 'CASUAL_LEAVES' | 'SICK LEAVE';
  leaveName: CustomLeaveNameEnum;
  reason: string | null;
  leaves?: LeavesI[];
}

export interface CreatedUserI {
  fullName: string | null;
  userRole: string;
  role?: RoleI;
  leaveBalance?: LeaveBalance[];
  roleId: number;
  primaryManager: number;
  secondaryManager: number;
}

export interface ReviewI {
  status: LeaveStatus;
  leaveId: string;
  reasonForRejection?: string;
}

export interface ReviewLeaveRequestI {
  status: LeaveStatus;
  reqId: string
  reasonForRejection?: string;
}

export interface UserLeaveI {
  leaveId: string;
  date: string | null;
  to: string | null;
  from: string | null;
  hours: number;
  leaveType: string | null;
  reason: string | null;
}
export interface OrganizationLeaveI {
  id?: string;
  reason: string | null;
  date: string;
  status: string;
  leaveType: string;
  reqId: number;
  hours: number;
  from: string | null;
  to: string | null;
  rejectionNote: string | null;
  createdBy: CreatedUserI;
  reviewBy: CreatedUserI;
  user: CreatedUserI;
  userId: string;
  createdById: string;
}

export interface OrganizationLeaveRequestI {
  id?: string;
  startDate: string;
  endDate: string;
  reqBy: OrganizationLeaveI[];
}

export interface GetLeaveLogsParams {
  userId: string;
  page: string;
}

export enum FrequencyTypesEnum {
  ANNUALLY = 'ANNUALLY',
}

export enum LeavePolicyGroupEnum {
  FULL_TIME = 'FULL TIME',
  PROBATION = 'PROBATION',
}
export enum MonthlyWorkingDayTypeEnum {
  FIXED = 'Fixed',
  CALENDAR = 'Calendar',
  VARIABLE = 'Variable',
}

export interface LeavePolicyGroupI {
  createdAt: string;
  id: number;
  isDefault: boolean;
  leaveTypes: LeaveTypesI[];
  name: LeavePolicyGroupEnum;
  organizationId: number;
  updatedAt: string;
}

export interface LeaveTypesI {
  accrualLeavesPerMonth: string | null;
  createdAt: string;
  id: number;
  leavePolicyGroupId: number;
  leaveType: LeaveTypesEnum;
  maxPerYear: number;
  name: 'ANNUAL_LEAVE' | 'CASUAL_LEAVES' | 'SICK LEAVE';
  updatedAt: string;
}
export interface LeaveSettingsI {
  leaveReset: LeaveResetI;
  noPay: NoPayI;
}

export interface LeaveResetI {
  date: string | null;
  status: boolean;
}
export interface NoPayI {
  rate: number;
  status: boolean;
  isEnableProRated: boolean;
  isApit?: boolean;
  isEpf?: boolean;
}

export interface LeaveI {
  leaveTime: string;
  leaveHours: string;
  leaveType: string;
}
export interface DayI {
  dayType: string;
  leaveDay: LeaveI[];
}
export interface StyledHeaderOuterGrayProps {
  dayName?: string;
}
export interface StyledInnerHeaderYellowProps {
  currentDateNumber: number;
}
