import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LogTrailTable,
  SecondaryButton,
} from '../../../components';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { useGetAllLeaveLogs } from '../../../api/leaveHooks';

const LeaveLogTrail: FC = () => {
  const navigate = useNavigate();

  const {
    isLoading: loadingLoanLogs,
    data: dataLoanLogs = [],
    refetch: getLeaveLogs,
  } = useGetAllLeaveLogs();

  useEffect(() => {
    getLeaveLogs();
  }, []);

  return (
    <>
      <LogTrailTable
        logs={dataLoanLogs}
        loading={loadingLoanLogs}
        leftChildren={
          <SecondaryButton onClick={() => navigate(getPathWithOrgId('/leave'))}>
            Back
          </SecondaryButton>
        }
      />
    </>
  );
};
export default LeaveLogTrail;
