import styled from '@emotion/styled';
import { FC } from 'react';
import { B } from '../../../components';
import { Row } from 'antd';
import theme from '../../../theme';
import {
  LeaveBalance,
  LeaveNameEnum,
  LeaveNameI,
  LeaveTypesEnum,
} from '../../../types';
import LeavesBalance from './LeavesBalance';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import {
  disableSickleavesOrg,
  LEAVE_LIST,
  LEAVE_NAMES,
} from '../../../configs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';

interface Props {
  leaveBalanceUser: LeaveBalance[];
  leaveStatus: LeaveNameI;
}

const LeaveOverviewDiv = styled.div`
  padding: 0px 0px 16px 0px;
  // background-color: lightgray !important;
  max-width: 100%;

  // display: inline-block;

  margin-top: 10px;
  //   @media only screen and (max-width: 767px) {
  //     display: none;
  //   }
`;

const LeaveBalanceComponent: FC<Props> = ({
  leaveBalanceUser,
  leaveStatus,
}) => {
  const LeavesBalanceDiv = styled.div`
    padding-right: '0px';
    margin-top: 10px !important;
    margin: 0px !important;
  `;

  const StyledRow = styled(Row)`
    display: flex !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
    border: 1px solid ${theme.gray300};

    // flex-shrink: 'unset' !important;

    overflow-y: hidden !important;
    overflow-x: auto !important;
    scroll-padding-right: 20px !important;
    white-space: nowrap !important;

    scroll-padding-inline-end: auto !important;

    & > *:not(:last-child) {
      border-right: 1px solid ${theme.gray300};
      padding-right: 10px;
    }
  `;


  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const customLeaveTypes = organizationData?.customLeaveTypes;

  // to add labels and values of the custom leave types as an object
  const customLeaves =
    leaveBalanceUser?.filter(
      (item) =>
        item.leaveType === LeaveTypesEnum.CUSTOM &&
        customLeaveTypes?.some(
          (balance) =>
            balance.isEnable && balance.customLeaveName === item.leaveName,
        ),
    ) || [];

  return (
    <>
      <LeaveOverviewDiv>
        <StyledRow align="middle" style={{ borderRadius: '4px' }}>
          <LeavesBalanceDiv>
            <LeavesBalance
              leaveTitle="Annual Leave"
              leavesBalance={leaveStatus.annualLeaves}
              isShort={false}
            />
          </LeavesBalanceDiv>
          {/* FIXME */}
          {getCurrentAuthPayload()?.organizationId !== disableSickleavesOrg ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="Sick Leave"
                leavesBalance={leaveStatus.sickLeaves}
                isShort={false}
              />
            </LeavesBalanceDiv>
          ) : null}
          <LeavesBalanceDiv>
            <LeavesBalance
              leaveTitle="Casual Leave"
              leavesBalance={leaveStatus.casualLeaves}
              isShort={false}
            />
          </LeavesBalanceDiv>
          {customLeaves?.map((item) => {
            return (
              <LeavesBalanceDiv>
                <LeavesBalance
                  leaveTitle={item.leaveName}
                  leavesBalance={leaveStatus[item.leaveName]}
                  isShort={false}
                />
              </LeavesBalanceDiv>
            );
          })}
          {leaveStatus.noPay ? (
            <LeavesBalanceDiv>
              <LeavesBalance
                leaveTitle="No Pay"
                leavesBalance={leaveStatus.noPay}
                isShort={false}
              />
            </LeavesBalanceDiv>
          ) : null}
        </StyledRow>
      </LeaveOverviewDiv>
    </>
  );
};
export default LeaveBalanceComponent;
