import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { LogTrailItemI } from '../store/actions';
import {
  AddLoanNoteI,
  ArchiveLoanStatusI,
  LoanDocumentI,
  LoanGenerateBulkI,
  LoanGeneratedBankFilesI,
  LoanI,
  SettleLoanDataI,
  UpdateUserLoanI,
} from '../types/loan.types';
import { getApiError } from '../util/getApiError';

export const useCreateLoan = () => {
  return useMutation<string, AxiosError, FormData>(async (data: FormData) => {
    const response: AxiosResponse<string> = await axios.post('/loan', data);
    return response.data;
  });
};

export const useGetLoanId = () => {
  return useQuery<string, AxiosError>(
    'loan-id',
    async () => {
      const response: AxiosResponse<string> = await axios.get(
        '/loan/loanid',
      );
    return response.data;
    },
  );
};

export const useGetLoanList = () => {
  return useQuery<LoanI[], AxiosError>('loan-list', async () => {
    const response: AxiosResponse<LoanI[]> = await axios.get(
      '/loan/list',
    );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      }
    });
};

export const useGetUserLoanList = (userId:number) => {
  return useQuery<LoanI[], AxiosError>(['user-loan-list',userId], async () => {
      const response: AxiosResponse<LoanI[]> = await axios.get(
        `/loan/list/${userId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      }
    });
};

export const useGetLoan = (id:number) => {
  return useQuery<LoanI, AxiosError>(['loan', id],async () => {
      const response: AxiosResponse<LoanI> = await axios.get(`/loan/${id}`);
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      }
    });
};

export const useUpdateLoan = () => {
  return useMutation<string, AxiosError, UpdateUserLoanI>(
    async ({ id, data }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/loan/${id}`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: (_data, { data }) => {
        if (data.loanReleasedToEmployee) {
          message.success('Loan Released Successfully');
        } else {
          message.success('Updated Successfully');
        }
      },
    },
  );
};

export const useArchiveLoan = () => {
  return useMutation<string, AxiosError, ArchiveLoanStatusI>(
    async ({ id }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/loan/archive/${id}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Loan Archived Successfully');
      },
    },
  );
};

export const useAddNote = () =>
  useMutation<AxiosResponse, AxiosError, AddLoanNoteI>(
    async (data: AddLoanNoteI) => {
      const response: AxiosResponse = await axios.post(
        '/loan/addNote',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Note added successfully');
      },
    },
  );

export const useEditNote = () => {
  return useMutation<string, AxiosError, AddLoanNoteI>(
    async ({ id, ...data }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/loan/editNote/${id}`,
        { ...data },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Note updated successfully');
      },
    },
  );
};

export const useRemoveNote = () => {
  return useMutation<string, AxiosError, number>(
    async (id) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/loan/removeNote/${id}`,
      );

      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Note removed successfully');
      },
    },
  );
};

export const useAddDocument = () =>
  useMutation<AxiosResponse, AxiosError, LoanDocumentI>(
    async (data: LoanDocumentI) => {
      const formData = new FormData();
      formData.append('file', data.document);
      formData.append('name', data.name);
      formData.append('loanId', String(data.loanId));

      const response: AxiosResponse = await axios.post(
        '/loan/addDocument',
        formData,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Document added successfully');
      },
    },
  );

export const useRemoveDocument = () => {
  return useMutation<string, AxiosError, number>(
    async (id) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/loan/removeDocument/${id}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Document removed successfully');
      },
    },
  );
};

export const useSettleLoan = () => {
  return useMutation<string, AxiosError, SettleLoanDataI>(
    async ({ id, data }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/loan/settleLoan/${id}`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Loan settle successfully');
      },
    },
  );
};

export const useGetLoanLogs = () =>
  useMutation<LogTrailItemI[], AxiosError>(
    'loan-logs',
    async () => {
      const response: AxiosResponse<LogTrailItemI[]> = await axios.get(
        '/loan/loan-logs',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useGetLogsByLoanId = () =>
  useMutation<LogTrailItemI[], AxiosError, number>(
    'individual-loan-logs',
    async (id) => {
      const response: AxiosResponse<LogTrailItemI[]> = await axios.get(
        `/loan/loan-logs/${id}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useGenerateBulkLoan = () => {
  return useMutation<string, AxiosError, LoanGenerateBulkI>(async (data) => {
    const response: AxiosResponse<string> = await axios.post(
      `/loan/generate-bulk-file`,
      data,
    );
    return response.data;
  });
};

export const useGetGenerateBankList = () => {
  return useQuery<LoanGeneratedBankFilesI[], AxiosError>('transfer-records', async () => {
    const response: AxiosResponse<LoanGeneratedBankFilesI[]> = await axios.get(
      '/loan/transfer-records',
    );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      }
    });
};