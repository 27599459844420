import { css } from '@emotion/react';
import { Form, Row, Input, Spin, Divider } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

import {
  DatePickerComp,
  H,
  SelectComp,
  AlertMessage,
  DefaultBody,
  Div,
} from '../../../components';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import {
  getLeaveBalanceByUser,
  leavesBalanceMe,
  getMinimumUserInfo,
  getAllOrganizationLeaves,
  getMyLeaves,
  getOrganizationLeaveRequests,
  getMyLeaveRequests,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import { isAllowed } from '../../../util/permissionUtil';
import {
  LeaveViewI,
  LeaveBalance,
  LeaveTypesEnum,
  Permission,
  DATE_FORMAT,
  RequestLeaveI,
  LeaveTypeEnum,
} from '../../../types';
import {
  getDatesBetween,
  getDisabledTimeSlots,
  onTimeChange,
  getLeaveTypes,
  getOtherLeaveTypes,
  onDateRangeChanged,
  onLeaveDayClose,
  isDisabledLeaveEndDate,
  isDisabledLeaveStartDate,
} from '../utils';
import LeaveSelector from './LeaveSelector';
import { capitalizeUnderscore } from '../../../util/utils';
import LeaveDetails from '../shared/LeaveDetails';
import {
  useRequestLeaveAsAdmin,
  useRequestMyLeave,
} from '../../../api/leaveHooks';
import { getApiError } from '../../../util/getApiError';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

const textAreaCss = css`
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    border: 1px solid #0052ea;
    box-shadow: 0 0 0 2px rgba(90, 90, 90, 0.1);
  }

  &:hover {
    border: 1px solid #0052ea;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 82, 234, 0.1));
    border-radius: 4px;
  }
`;
const LeaveCard = styled.div<{ leaveDates: number }>`
  border: ${(props) => props.leaveDates != 0 && '0.5px solid #bdbdbd'};
  border-bottom: none;
  padding: 0px;
  margin-bottom: ${(props) => props.leaveDates != 0 && '16px'};
  @media (max-width: 768px) {
    border: none;
  }
`;
const ApplyLeave: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const [selectedLeaveType, setSelectedLeaveType] = useState<string | null>();
  const [selectedLeaveNameType, setSelectedLeaveNameType] = useState<
    string | null
  >();
  const [startValue, setStartValue] = useState<string>();
  const [endValue, setEndValue] = useState<string>();
  const [isLeaveName, setIsLeaveName] = useState(false);
  const [leaveDates, setLeaveDates] = useState<LeaveViewI[]>([]);
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const [noPayAllowed, setNoPayAllowed] = useState(
    isAllowed(Permission.CREATE_EDIT_LEAVES_USER),
  );

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const userLeaveData = useSelector(
    (state: RootState) => state.leave.organizationLeaves,
  );


  const currentYear = moment().year();
  const leaveResetDate = moment(
    organizationData?.leaveSettings.leaveReset.date,
    'DD-MM',
  )
    .year(currentYear)
    .format('YYYY-MM-DD');

  const leaveBalanceUser = useSelector(
    (state: RootState) => state.leave.leavesBalanceUser,
  );

  const leaveBalanceMe = useSelector(
    (state: RootState) => state.leave.leavesBalanceMe,
  );

  const {
    isLoading: requestLoadingAsAdmin,
    error: requestErrorAsAdmin,
    data: requestDataAsAdmin,
    mutate: postLeaveAsAdmin,
  } = useRequestLeaveAsAdmin();

  const {
    isLoading: requestLoadingAsUser,
    error: requestErrorAsUser,
    data: requestDataAsUser,
    mutate: postLeaveAsUser,
  } = useRequestMyLeave();

  const leavesBalanceMeLoading = useSelector(
    (state: RootState) => state.leave.leavesBalanceMeLoading,
  );
  const leavesBalanceUserLoading = useSelector(
    (state: RootState) => state.leave.leavesBalanceUserLoading,
  );

  const me = useSelector((state: RootState) => state.people.me);
  const [otherLeaves, setOtherLeaves] = useState<LeaveBalance[]>();

  useEffect(() => {
    setOtherLeaves(
      leaveBalanceUser?.filter(
        (item) => item.leaveType == LeaveTypesEnum.OTHER,
      ),
    );
  }, [leaveBalanceUser]);

  useEffect(() => {
    setOtherLeaves(
      leaveBalanceMe?.filter((item) => item.leaveType == LeaveTypesEnum.OTHER),
    );
  }, [leaveBalanceMe]);

  const leaveNameOptions = otherLeaves?.map((item) => {
    return {
      value: item.leaveName,
      label: capitalizeUnderscore(item.leaveName),
    };
  });

  useEffect(() => {
    if (isAllowed(Permission.MANAGE_ASSIGNED_USERS) || me?.isManager) {
      dispatch(getMinimumUserInfo());
      if (me && me.id) {
        dispatch(getLeaveBalanceByUser(String(me.id)));
      }
    } else {
      dispatch(leavesBalanceMe());
    }
  }, []);

  useEffect(() => {
    if (requestDataAsAdmin && !requestErrorAsAdmin) {
      dispatch(leavesBalanceMe());
      dispatch(getAllOrganizationLeaves());
      dispatch(getOrganizationLeaveRequests());
      dispatch(getMyLeaves());
      dispatch(getMyLeaveRequests());
      // navigate to leave home page
      navigate('/leave');
    }
  }, [requestDataAsAdmin]);

  useEffect(() => {
    if (requestDataAsUser && !requestErrorAsUser) {
      dispatch(leavesBalanceMe());
      dispatch(getAllOrganizationLeaves());
      dispatch(getMyLeaves());
      dispatch(getOrganizationLeaveRequests());
      dispatch(getMyLeaveRequests());
      // navigate to leave home page
      navigate('/leave');
    }
  }, [requestDataAsUser]);

  useEffect(() => {
    if (startValue && endValue) {
      const dateRanges = getDatesBetween({
        toDate: endValue,
        fromDate: startValue,
      });
      const newLeaveDates = onDateRangeChanged(
        dateRanges,
        leaveDates,
        userLeaveData,
        organizationData,
      );
      setLeaveDates(newLeaveDates);
    }
  }, [endValue, startValue]);

  useEffect(() => {
    if (endValue) {
      form.setFieldsValue({
        to: dayjs(endValue),
      });
    }
  }, [endValue]);

  useEffect(() => {
    if (startValue) {
      form.setFieldsValue({
        from: dayjs(startValue),
      });
    }
  }, [startValue]);

  function onSubmitLeaveAsAdmin(data: RequestLeaveI) {
    const value: RequestLeaveI = {
      userId: String(me?.id),
      type: LeaveTypeEnum.DEDUCT,
      hours: data.hours ? Number(data.hours) : 0,
      leaveType: data.leaveType,
      leaveName: data.leaveName && data.leaveName,
      reason: data.reason,
      leaves: leaveDates
        .filter((item) => item.isHoliday === false)
        .map((item) => ({
          date: item.date,
          to: item.toTime,
          from: item.fromTime,
          hours: item.hours,
        })),
    };

    postLeaveAsAdmin(value);
  }

  function onSubmitLeaveAsUser(data: RequestLeaveI) {
    const value: RequestLeaveI = {
      leaveType: data.leaveType,
      reason: data.reason,
      leaveName: data.leaveName && data.leaveName,
      leaves: leaveDates
        .filter((item) => item.isHoliday === false)
        .map((item) => ({
          date: item.date,
          to: item.toTime,
          from: item.fromTime,
          hours: item.hours,
        })),
    };
    postLeaveAsUser(value);
  }

  const leaveSummery = isAllowed(Permission.CREATE_EDIT_LEAVES_USER)
    ? leaveBalanceUser
    : leaveBalanceMe;

  const allPeoplesOptions =
    allPeoples &&
    allPeoples
      .sort((a, b) => a.fullName?.localeCompare(b.fullName))
      .map((item, index) => {
        return {
          value: item.id ? item.id : index,
          label: item.fullName,
        };
      });
  return (
    <DefaultBody>
      <Row
        justify="start"
        align="middle"
        css={css`
          padding: 16px 0px 0px 0px;
        `}>
        <ArrowLeftOutlined
          onClick={() => navigate(-1)}
          css={css`
            padding-right: 10px;
            font-size: 20px;
          `}
        />
        <H type="h6">Request New Leave</H>
      </Row>
      <Divider
        css={css`
          margin-top: 14px;
        `}
      />
      <Form
        initialValues={{
          userId: me?.id,
        }}
        form={form}
        layout="vertical"
        wrapperCol={{ span: 50 }}
        name="requestLeave"
        onFinish={
          isAllowed(Permission.CREATE_EDIT_LEAVES_USER)
            ? onSubmitLeaveAsAdmin
            : onSubmitLeaveAsUser
        }
        css={css`
          .ant-form-item-label
            > label.ant-form-item-required:not(
              .ant-form-item-required-mark-optional
            )::before {
            content: none;
          }
          .ant-form-item-label > label {
            font-size: 14px;
            font-weight: 600;
            color: #00318c;
          }
          .ant-input[disabled] {
            color: #000000;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            margin-bottom: 10px;
          }
        `}>
        {requestErrorAsAdmin && (
          <Div marginBottom="12px">
            <AlertMessage
              title={getApiError(requestErrorAsAdmin)}
              type="error"
            />
          </Div>
        )}
        {requestErrorAsUser && (
          <Div marginBottom="12px">
            <AlertMessage
              title={getApiError(requestErrorAsUser)}
              type="error"
            />
          </Div>
        )}
        {!!allPeoplesOptions.length ? (
          <Form.Item
            name="userId"
            rules={[
              {
                required: true,
                message: 'Please select your Employee Name!',
              },
            ]}>
            <SelectComp
              showSearch
              placeholder="Select a employee name"
              optionFilterProp="label"
              options={allPeoplesOptions}
              onSelect={(value) => {
                setNoPayAllowed(
                  me?.id !== value ||
                    isAllowed(Permission.CREATE_EDIT_LEAVES_USER),
                );
                dispatch(getLeaveBalanceByUser(value.toString()));
              }}></SelectComp>
          </Form.Item>
        ) : null}
        <Form.Item
          name="leaveType"
          css={css`
            margin-bottom: ${selectedLeaveType ? '6px' : '24px'};
          `}
          rules={[
            {
              required: true,
              message: 'Please select your leave type',
            },
          ]}>
          <SelectComp
            placeholder="Please Select leave type"
            options={getLeaveTypes(
              leaveSummery,
              otherLeaves ? otherLeaves?.length : 0,
              noPayAllowed,
              organizationData
            )}
            onSelect={(value) => {
              if (value == LeaveTypesEnum.OTHER) {
                setIsLeaveName(true);
                setSelectedLeaveType(null);
              } else {
                setIsLeaveName(false);
                setSelectedLeaveType(value.toString());
              }
            }}
          />
        </Form.Item>
        {selectedLeaveType && (
          <Spin spinning={leavesBalanceUserLoading || leavesBalanceMeLoading}>
            <LeaveDetails
              leaveSummery={leaveSummery}
              type={selectedLeaveType}
            />
          </Spin>
        )}
        {isLeaveName && (
          <>
            <Form.Item
              name="leaveName"
              css={css`
                margin-bottom: ${selectedLeaveNameType ? '6px' : '24px'};
              `}
              rules={[
                {
                  required: true,
                  message: 'Please select your leave Name',
                },
              ]}>
              <SelectComp
                placeholder="Please Select leave name"
                options={getOtherLeaveTypes(leaveNameOptions, leaveSummery)}
                onSelect={(value) => {
                  setSelectedLeaveNameType(value.toString());
                }}
              />
            </Form.Item>
            {selectedLeaveNameType && (
              <Spin
                spinning={leavesBalanceUserLoading || leavesBalanceMeLoading}>
                <LeaveDetails
                  leaveSummery={leaveSummery}
                  type={selectedLeaveNameType}
                />
              </Spin>
            )}
          </>
        )}

        <Form.Item
          name="from"
          rules={[
            {
              required: true,
              message: 'required',
            },
          ]}>
          <DatePickerComp
            format={DATE_FORMAT}
            placeholder="Select From Date"
            size="middle"
            inputReadOnly
            disabledDate={(current) =>
              isDisabledLeaveStartDate(
                moment(current.toDate()),
                !!organizationData?.leaveSettings.leaveReset.date,
                moment(leaveResetDate),
              )
            }
            onChange={(value) => {
              if (value) {
                setStartValue(value.format('YYYY-MM-DD'));
                if (!endValue || value.valueOf() > moment(endValue).valueOf()) {
                  setEndValue(value.format('YYYY-MM-DD'));
                }
              }
            }}
          />
        </Form.Item>
        {startValue && (
          <Form.Item name="to">
            <DatePickerComp
              size="middle"
              defaultValue={dayjs(endValue)}
              disabled={startValue === undefined ? true : false}
              format={DATE_FORMAT}
              placeholder="Select To Date"
              inputReadOnly
              disabledDate={(current) =>
                isDisabledLeaveEndDate(
                  moment(current.toDate()),
                  moment(startValue),
                  !!organizationData?.leaveSettings.leaveReset.date
                    ? moment(leaveResetDate).subtract(1, 'day').valueOf()
                    : moment().endOf('year').valueOf(),
                )
              }
              onChange={(value) => {
                if (value) {
                  setEndValue(value.format('YYYY-MM-DD'));
                } else {
                  setEndValue('');
                }
              }}
            />
          </Form.Item>
        )}

        <LeaveCard leaveDates={leaveDates.length}>
          {leaveDates.map((item, i) => (
            <LeaveSelector
              leaveClose={(value) =>
                onLeaveDayClose(
                  value,
                  leaveDates,
                  setStartValue,
                  setEndValue,
                  setLeaveDates,
                )
              }
              key={i}
              leave={item}
              onChangeFromTime={(value) => {
                setLeaveDates(onTimeChange(value, leaveDates, item, i, 'from'));
              }}
              disabledHours={() =>
                getDisabledTimeSlots(leaveDates[i]?.fromTime)
              }
              onChangeToTime={(value) => {
                setLeaveDates(onTimeChange(value, leaveDates, item, i, 'to'));
              }}
            />
          ))}
        </LeaveCard>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: 'Please enter a reason!',
            },
          ]}>
          <Input.TextArea css={textAreaCss} placeholder="Reason" />
        </Form.Item>
        <Form.Item
          css={css`
            margin-bottom: 0px;
          `}>
          <Row justify="end">
            <PrimaryButton
              block
              loading={
                isAllowed(Permission.CREATE_EDIT_LEAVES_USER)
                  ? requestLoadingAsAdmin
                  : requestLoadingAsUser
              }
              htmlType="submit">
              Submit
            </PrimaryButton>
          </Row>
        </Form.Item>
      </Form>
    </DefaultBody>
  );
};

export default ApplyLeave;
