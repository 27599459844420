import React, { FC, useEffect, useState } from 'react';

import { Col, Dropdown, Menu, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import styled from '@emotion/styled';

import { B } from '../../../../components';
import { useIndividualPeopleState } from '../../../../providers/IndividualPeopleState';
import {
  addNewNote,
  deleteNote,
  getPeopleNotes,
  updateNote,
} from '../../../../store/actions';
import { DispatchType, RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import {
  NotificationItemI,
  NotificationStatusEnum,
} from '../../../../types/notification.types';
import NoteContainer from '../../shared/notes/NoteContainer';
import PeopleSelect from '../../shared/PeopleSelect';
import { DropdownBlue } from '../../../../assets';

const NotePage = styled.div`
  padding: 32px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
const NoteHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0px 0px;
    margin-bottom: 0px;
  }
`;
const CustomDropdown = styled(Dropdown)`
  margin-left: auto;
  margin-top: 15px;
  color: '${theme.blue500}';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  top: 215px !important;
  :hover {
    color: '${theme.blue500}' !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 215px !important;
  }
  @media (max-width: 1024px) {
    padding-top: 2px;
  }
`;
export const CustomRow = styled(Row)`
  @media (max-width: 1024px) and (min-width: 500px) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 24px;
  }
`;
export const CustomCol = styled(Col)`
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const Filter = styled.a`
  color: ${theme.blue500};
  &:hover {
    color: ${theme.blue500};
  }
`;
enum FilterTypes {
  done = 'done',
  allNotes = 'allNotes',
  pending = 'pending',
}

const menuItems = [
  {
    key: FilterTypes.allNotes,
    name: 'All Notes',
  },
  {
    key: FilterTypes.done,
    name: 'Done',
  },
  {
    key: FilterTypes.pending,
    name: 'Pending',
  },
];
type ParamsI = {
  employeeKey: string;
};

export const AddNotes: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();
  const [notifications, setNotifications] = useState<NotificationItemI[]>([]);
  const [note, setNote] = useState<NotificationItemI[]>([]);
  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.allNotes);
  const {
    state: { isLoading },
    fetchPeopleById,
  } = useIndividualPeopleState();
  const notes = useSelector((state: RootState) => state.notification.notes);

  useEffect(() => {
    const filterNotes = async () => {
      await setNote(notes.filter((note) => !note.isLoanNote));
    };

    filterNotes();
  }, [notes]);

  const menu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item onClick={() => setFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    dispatch(getPeopleNotes(params.employeeKey));
  }, [params]);
  useEffect(() => {
    const list: NotificationItemI[] = [];

    note.forEach((note) => {
      const notesList: NotificationItemI = {
        ...note,
      };
      if (filter === FilterTypes.allNotes) {
        list.push(notesList);
      } else if (
        filter === FilterTypes.done &&
        note.status === NotificationStatusEnum.DONE
      ) {
        list.push(notesList);
      } else if (
        filter === FilterTypes.pending &&
        note.status === NotificationStatusEnum.PENDING
      ) {
        list.push(notesList);
      }
    });

    setNotifications(list);
  }, [note, filter]);
  return (
    <Spin spinning={isLoading}>
      <NotePage>
        <NoteHeader>
          <Row justify="end">
            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
              <B type="b-large-semibold">
                Notes
                <B type="b-small" color={theme.gray600}>
                  These notes are only visible to Owners, Admins, and Guest
                  Admins.
                </B>
              </B>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
              <PeopleSelect defaultIndex="7" />
            </Col>
          </Row>

          <CustomDropdown overlay={menu} trigger={['click']}>
            <Filter onClick={(e) => e.preventDefault()}>
              {menuItems.find((item) => item.key === filter)?.name}{' '}
              <img src={DropdownBlue} />
            </Filter>
          </CustomDropdown>
        </NoteHeader>

        <CustomRow>
          <CustomCol xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
            <B type="b-small" color={theme.gray600} pb={15}>
              These notes are only visible to Admins and Accountants.
            </B>
          </CustomCol>
        </CustomRow>

        <NoteContainer
          addAndEditOnly={false}
          notifications={notifications}
          onAddNewNote={async (note) => {
            const value = {
              ...note,
              userId: Number(params.employeeKey),
            };
            await dispatch(addNewNote(value));
            fetchPeopleById(params.employeeKey);
          }}
          onEditNote={async (_index, note) => {
            await dispatch(updateNote(note, params.employeeKey));
            fetchPeopleById(params.employeeKey);
          }}
          onRemove={async (index) => {
            await dispatch(deleteNote(String(index), params.employeeKey));
            fetchPeopleById(params.employeeKey);
          }}
        />
      </NotePage>
    </Spin>
  );
};
export default AddNotes;
