import React, { FC, useEffect } from 'react';

import _ from 'lodash';

import { Col, Dropdown, Menu, message, Row, Tag } from 'antd';
import { AddLoanNoteI } from '../../../types/loan.types';
import { DeleteModal, B, Image } from '../../../components';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { isValidMoment } from '../../../util/isValidMoment';
import { MoreSVG, NotificationSVG } from '../../../assets';
import moment from 'moment';
import { getDiffDays } from '../../leave/utils/dateAndTime.util';
import { NotificationStatusEnum } from '../../../types/notification.types';
import { useChangeNotificationStatus } from '../../../api/reminderHooks';
import { getApiError } from '../../../util/getApiError';

interface Props {
  item: AddLoanNoteI;
  onClickEdit: () => void;
  onRemove: () => void;
  isUserView?: boolean;
  isLastItem?: boolean;
  onStatusChange?: () => void;
}

const DropdownMenu = styled(Menu)`
  width: 163px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 12px 0px 12px 16px;
    border-bottom: 1px solid ${theme.gray300};
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
  }
  .ant-dropdown-menu-item:last-child {
    border-bottom: none;
  }
`;

const CustomRow = styled(Row)<{ isLastItem?: boolean }>`
  border-bottom: ${({ isLastItem }) =>
    isLastItem ? 'none' : `1px solid ${theme.gray300}`};
  box-sizing: border-box;
  padding: 18px 20px 8px 0px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
`;

const DropdownIconContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ReminderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CustomCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NoteItem: FC<Props> = ({
  onClickEdit,
  onRemove,
  item,
  isUserView,
  isLastItem,
  onStatusChange,
}) => {
  const {
    error: changeNotificationStatusError,
    data: changeNotificationStatus,
    mutate: putChangeNotificationStatus,
  } = useChangeNotificationStatus();

  useEffect(() => {
    if (changeNotificationStatus && !changeNotificationStatusError) {
      message.success('Submitted successfully!');
      onStatusChange();
    }
  }, [changeNotificationStatus]);

  useEffect(() => {
    changeNotificationStatusError &&
      message.error(getApiError(changeNotificationStatusError));
  }, [changeNotificationStatusError]);

  const menu = (
    <DropdownMenu>
      {item?.notification && item?.notification?.status != 'DONE' ? (
        <Menu.Item
          key="done"
          onClick={() => {
            if (item?.notification) {
              const value = {
                id: item?.notification.id,
                status: NotificationStatusEnum.DONE,
              };
              putChangeNotificationStatus(value);
            }
          }}>
          Mark as done
        </Menu.Item>
      ) : null}
      {item?.notification?.status === 'DONE' ? (
        <Menu.Item
          key="status"
          onClick={() => {
            if (item?.notification) {
              const value = {
                id: item?.notification.id,
                status: NotificationStatusEnum.PENDING,
              };
              putChangeNotificationStatus(value);
            }
          }}>
          Unmark as done
        </Menu.Item>
      ) : (
        <Menu.Item key="edit" onClick={() => onClickEdit()}>
          <B type="b-default">Edit note</B>
        </Menu.Item>
      )}
      <Menu.Item key="remove">
        <DeleteModal
          title="Remove note"
          buttonLabel="Yes, Remove!"
          content="Are you sure you want to remove this note?"
          onClickCancel={() => onRemove()}
          openModal={<B type="b-default">Remove note</B>}></DeleteModal>
      </Menu.Item>
    </DropdownMenu>
  );

  return (
    <CustomRow isLastItem={isLastItem}>
      <Col span={19}>
        <B
          type="b-default"
          color={item?.notification?.status === 'DONE' ? theme.gray600 : theme.black}
          marginLeft="45px">
          {item.note}
          <B type="b-small" color={theme.gray600}>
            {isValidMoment(item.updatedAt, undefined, 'DD.MM.YYYY').toString()}
          </B>
        </B>
      </Col>

      <CustomCol span={5}>
        {item?.notification?.status === 'DONE' ? (
          <Tag color={theme.green50} bordered={false}>
            <B type="b-small" color={theme.green300}>
              Done
            </B>
          </Tag>
        ) : (
          <>
            {item.reminderDate && (
              <ReminderContainer>
                <Image
                  width={20}
                  height={20}
                  marginLeft="auto"
                  src={NotificationSVG}
                />

                <B type="b-small" color={theme.gray600} pl="10px">
                  {getDiffDays(moment(item.reminderDate).format('M/D/YYYY'))}
                </B>
              </ReminderContainer>
            )}
          </>
        )}
        {!isUserView && (
          <DropdownIconContainer>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight">
              <Image
                width={20}
                height={20}
                cursor="pointer"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                src={MoreSVG}
              />
            </Dropdown>
          </DropdownIconContainer>
        )}
      </CustomCol>
    </CustomRow>
  );
};

export default NoteItem;
