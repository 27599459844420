import React, { FC, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';
import styled from '@emotion/styled';
import { B, Div, LinkButton } from '../../../components';
import theme from '../../../theme';

import { CustomLeaveTypesI } from '../../../types/organization.types';

import { v4 as uuidv4 } from 'uuid';
import { PlusBlueSVG } from '../../../assets';
import CustomLeaveTypeList from './CustomLeaveTypesList';
import AddCustomLeave from './AddCustomLeave';
import { useDeleteCustomLeaveBalance } from '../../../api/organizationHooks';

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;

const CustomBalanceList = styled.div<SpaceProps & BorderProps>`
  width: 580px;
  border-radius: 4px;
  div:last-child {
    border-bottom: none;
  }
  ${space}
  ${border}
`;
interface Props {
  form: FormInstance<any>;
  customLeaveList?: CustomLeaveTypesI[];
  customLeaves: CustomLeaveTypesI[];
  setCustomLeaves: (v: CustomLeaveTypesI[]) => void;
}

const CustomLeave: FC<Props> = ({
  form,
  customLeaveList,
  customLeaves,
  setCustomLeaves,
}) => {
  const [addCustomLeave, setAddCustomLeave] = useState<boolean>();
  const [customLeaveName, setCustomLeaveName] = useState<string>();
  const [noOfDays, setNoOfDays] = useState<number>();
  const [customLeaveIsEnable, setCustomLeaveIsEnable] =
    useState<boolean>(false);

  const {
    isLoading: loadingDeleteCustomLeave,
    isSuccess: isCustomLeaveDeleteSuccess,
    data: deleteCustomData,
    mutate: deleteCustomLeave,
  } = useDeleteCustomLeaveBalance();

  useEffect(() => {
    if (customLeaveList) {
      setCustomLeaves([...customLeaveList]);
    }
  }, [customLeaveList]);

  useEffect(() => {
    if (deleteCustomData) {
      const setId = customLeaves.findIndex(
        (list) => list.id === deleteCustomData?.deletedId,
      );
      customLeaves.splice(setId, 1);
      setCustomLeaves([...customLeaves]);
    }
  }, [isCustomLeaveDeleteSuccess]);

  return (
    <>
      <Div>
        <B type="b-default" pb="16px">
          Please select which leave categories should be included.
        </B>
        <CustomBalanceList
          border={
            customLeaves.length || addCustomLeave
              ? `1px solid ${theme.gray300}`
              : `none`
          }
          mb={customLeaves.length || addCustomLeave ? '10px' : '0px'}>
          {customLeaves && customLeaves.length ? (
            <CustomLeaveTypeList
              customLeaves={customLeaves}
              onRemove={(id) => {
                deleteCustomLeave({ id });
              }}
              onChange={(e) => {
                setCustomLeaveName(e.target.value);
              }}
              onEdit={(id) => {
                if (customLeaveName) {
                  const setId = customLeaves.findIndex(
                    (list) => list.id === id,
                  );
                  customLeaves[setId].customLeaveName = customLeaveName;
                }
              }}
              onChangeNoOfDays={(value, id) => {
                const setId = customLeaves.findIndex((list) => list.id === id);
                customLeaves[setId].noOfDays = value;
              }}
              onChangeCheckBox={(e, id) => {
                const setId = customLeaves.findIndex((list) => list.id === id);
                customLeaves[setId].isEnable = e.target.checked;
                setCustomLeaves([...customLeaves]);
              }}
            />
          ) : null}

          {addCustomLeave && (
            <AddCustomLeave
              onChange={(e) => setCustomLeaveName(e.target.value)}
              onBlur={() => {
                setAddCustomLeave(false);
                if (customLeaveName) {
                  const value: CustomLeaveTypesI = {
                    id: uuidv4(),
                    customLeaveName: customLeaveName,
                    noOfDays: noOfDays,
                    isEnable: customLeaveIsEnable,
                  };
                  if (
                    customLeaves
                      .map((i) => i.customLeaveName.toLocaleLowerCase())
                      .includes(customLeaveName.toLocaleLowerCase())
                  ) {
                    message.error('This Custom Leave Type already exists!');
                  } else {
                    customLeaves.push(value);
                    setCustomLeaves([...customLeaves]);

                    setCustomLeaveIsEnable(false);
                  }
                }
              }}
              onChangeNoOfDays={(v) => setNoOfDays(v)}
              onRemove={() => setAddCustomLeave(false)}
              onChangeCheckBox={(e) => setCustomLeaveIsEnable(e.target.checked)}
            />
          )}
        </CustomBalanceList>
        <FormItem name="customLeaveList">
          <LinkButton
            onClick={() => setAddCustomLeave(true)}
            padding={0}
            icon={<img src={PlusBlueSVG} />}
            disabled={customLeaves.length > 4 && true}>
            Add Custom Leave
          </LinkButton>
        </FormItem>
      </Div>
    </>
  );
};

export default CustomLeave;
