import styled from '@emotion/styled';
import { Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC } from 'react';
import { Div, InputComp, SelectComp } from '../../../components';
import { CheckBoxComp } from '../../../components/v2/CheckBoxComp';
import theme from '../../../theme';
import { GrayCancel } from '../../../assets';

const MainDiv = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.gray300};
  padding: 11px 22px 16px 19px;
  height: 56px;
  justify-content: space-between;
`;

const CustomFormItem = styled(FormItem)`
  width: 187px;
`;

export const InputTag = styled.div`
  background: ${theme.gray100};
  padding: 5px 7px;
  border-left: 1px solid ${theme.gray400};
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  margin-right: -12px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CustomInputComp = styled(InputComp)`
  height: 32px;
  width: 160px;
  margin-left: 20px;
`;

interface Props {
  onRemove: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChangeNoOfDays: (value: number) => void;
  onChangeCheckBox: (e: CheckboxChangeEvent) => void;
}

const AddCustomLeave: FC<Props> = ({
  onRemove,
  onChange,
  onBlur,
  onChangeNoOfDays,
  onChangeCheckBox,
}) => {
  const [form] = Form.useForm();

  return (
    <Form form={form}>
      <MainDiv>
        <Div display="flex">
          <FormItem valuePropName="checked">
            <CheckBoxComp onChange={(e) => onChangeCheckBox(e)} />
          </FormItem>
          <FormItem
            name="ot"
            rules={[
              {
                required: true,
                message: 'Please enter custom leave name',
              },
            ]}>
            <CustomInputComp
              placeholder="Enter Name"
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={(e) => {
                onBlur(e);
              }}
            />
          </FormItem>
        </Div>
        <Div display="flex">
          <CustomFormItem>
            <InputComp
              onChange={(v) => {
                onChangeNoOfDays(Number(v));
              }}
              size="small"
              suffix={<InputTag>days</InputTag>}
            />
          </CustomFormItem>
          <Div ml="38px" mt="4px">
            <CloseIcon onClick={() => onRemove()} src={GrayCancel} />
          </Div>
        </Div>
      </MainDiv>
    </Form>
  );
};

export default AddCustomLeave;
